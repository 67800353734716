import { getSurveyScheduleRate } from 'components/forms/Assets/lib';
import { trimAndReplace } from 'components/forms/utils';
import { getDateTime, SURVEY_FORM } from 'lib/layoutUtils';
import { isEmpty, isNil } from 'lodash';
import { useEffect } from 'react';

const useSetNonQuestionSetFields = ({
  layoutType,
  createPage,
  surveyName,
  surveyHandle,
  surveyId,
  schedule,
  startDate,
  endDate,
  rate,
  values,
  touched,
  setFieldValue,
}) => {
  // eslint-disable-next-line complexity
  useEffect(() => {
    if (layoutType === SURVEY_FORM) {
      if (createPage && !values?.surveyId) {
        const uuid = touched.surveyId
          ? values?.surveyId
          : window?.crypto?.randomUUID();

        if (!isNil(uuid)) setFieldValue('surveyId', uuid);
      } else if (
        !createPage &&
        !touched?.surveyId &&
        (!('surveyId' in values) || !values?.surveyId)
      ) {
        setFieldValue('surveyId', surveyId || window?.crypto?.randomUUID());
      }
    }
  }, [surveyId, values, touched]);

  useEffect(() => {
    if (layoutType === SURVEY_FORM) {
      if (createPage) {
        const handle = touched.surveyHandle
          ? values?.surveyHandle
          : trimAndReplace(values?.surveyName);

        if (!isNil(handle)) setFieldValue('surveyHandle', handle);
      } else if (!createPage && isEmpty(touched)) {
        setFieldValue('surveyHandle', surveyHandle);
        setFieldValue('surveyName', surveyName);
      }
    }
  }, [surveyName, surveyHandle, values?.surveyName, values?.surveyHandle]);

  useEffect(() => {
    if (layoutType === SURVEY_FORM && schedule) {
      const [surveyStartDate, surveyTime] = getDateTime(startDate);
      const [surveyEndDate, _] = getDateTime(endDate);
      setFieldValue('scheduleRate', getSurveyScheduleRate(rate));
      setFieldValue('surveyStartDate', surveyStartDate);
      setFieldValue('surveyEndDate', surveyEndDate);
      setFieldValue('surveyTime', surveyTime);
    }
  }, [layoutType, schedule, startDate, endDate, rate]);
};

export default useSetNonQuestionSetFields;
