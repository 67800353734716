/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextField, Text } from '@nike/eds';
import { TooltipContainer } from 'components/fields/SelectWithCriteria/styles';
import TooltipWrapper from 'components/TooltipWrapper';
import { surveyFieldsHelperText } from 'components/forms/utils';

const toolTipMessage = (
  <Text
    font="subtitle-2"
    className="eds-spacing--mb-4"
    style={{ wordBreak: 'break-word' }}
  >
    ⚠️ Once survey is published, surveyId cannot be edited again.
  </Text>
);

const SurveyIdField = ({
  id = 'surveyId',
  name = 'surveyId',
  value,
  initialValue,
  label = 'Survey Id',
  isRequiredField = false,
  errorText,
  isTextFieldTouched,
  handleFieldChange,
  disabled,
  createPage,
}) => {
  const hasErrors = !!(isTextFieldTouched && errorText);
  const isFieldDisabled = disabled || (!createPage && !!initialValue);

  const getLabel = () => (
    <label>
      { label }
      { isRequiredField && !hasErrors && <span className="asterisk">*</span> }
    </label>
  );

  const surveyIdMessage = createPage
    ? surveyFieldsHelperText
    : !initialValue
      ? 'This value will be added to the survey. You can make an initial edit to this value but further changes won\'t be permitted.'
      : surveyFieldsHelperText;

  const getValue = () => {
    if (value) {
      return value;
    }
    if (!createPage) {
      return initialValue;
    }
    return isTextFieldTouched ? value : initialValue;
  };

  return (
    <Grid item={ true } xs={ 12 }>
      <TooltipContainer>
        <TooltipWrapper active={ isFieldDisabled } message={ toolTipMessage }>
          <TextField
            id={ id }
            name={ name }
            label={ getLabel() }
            message={ surveyIdMessage }
            onChange={ handleFieldChange }
            hasErrors={ hasErrors }
            errorMessage={ hasErrors && errorText }
            disabled={ isFieldDisabled }
            value={ getValue() ?? '' }
          />
        </TooltipWrapper>
      </TooltipContainer>
    </Grid>
  );
};

SurveyIdField.propTypes = {
  createPage: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  handleFieldChange: PropTypes.func,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  isRequiredField: PropTypes.bool,
  isTextFieldTouched: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

SurveyIdField.defaultProps = {
  disabled: false,
  errorText: '',
  initialValue: '',
  isRequiredField: false,
  isTextFieldTouched: false,
  value: '',
  label: 'Survey Id',
  id: 'surveyId',
  name: 'surveyId',
};

export default SurveyIdField;
