// modules
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@nike/eds';
import { applyTo, pipe, isEmpty } from 'ramda';
import { withFormik } from 'formik';

import { Grid } from '@material-ui/core';
import { TagsInput } from 'components/fields';
import Button from 'components/Button';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import { INITIAL_VALUES, validationSchema } from './lib';
import styles from './index.module.scss';
import { ViewMode } from 'lib/enums';

export default applyTo(
  ({
    disabled,
    onCancel: handleCancel,
    handleSubmit,
    errors,
    dirty,
    viewMode,
    submitText,
    values,
    setFieldValue,
    onSubmit,
  }) => {
    const { upmids } = values;

    return (
      <form onSubmit={ handleSubmit }>
        <>
          <Grid container={ true } className={ styles.gridRow } spacing={ 2 }>
            <Grid item={ true } className={ styles.gridRow }>
              <Text font="title-4" className={ styles.title }>
                Invite Candidates
              </Text>
            </Grid>
            <Grid item={ true } className={ styles.gridRow }>
              <TagsInput
                fullWidth={ true }
                variant="outlined"
                id="upmids"
                name="upmids"
                tags={ upmids }
                disabled={ disabled || viewMode === ViewMode.READ }
                title="User Ids"
                setFieldValue={ setFieldValue }
                helperText="Type or paste upmids separated by a space, comma or line break"
              />
            </Grid>
          </Grid>
          <div className={ styles.btns }>
            { handleCancel && (
              <Button variant="secondary" onClick={ handleCancel } type="button">
                { viewMode === ViewMode.READ ? 'Back' : 'Cancel' }
              </Button>
            ) }
            <Button
              variant="primary"
              onClick={ onSubmit }
              disabled={
                viewMode === ViewMode.READ ? false : !isEmpty(errors) || !dirty
              }
              type="submit"
            >
              { submitText || 'Submit' }
            </Button>
          </div>
        </>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        if (initialValues) {
          //TODO uncomment
          //const { ...rest } = initialValues;
        }
        return INITIAL_VALUES;
      },
      validationSchema,
      // do the right handle submit, formik provides one
      handleSubmit: (
        values,
        { setSubmitting, props: { onSubmit, submitText } }
      ) => {
        const { ...rest } = values;
        const request = { ...rest };
        if (submitText === 'Edit') {
          onSubmit();
        }
        onSubmit(request);
        setSubmitting(false);
      },
      displayName: 'CandidatesForm',
    }),
    propTypes({
      handleCancel: PropTypes.func,
      handleSubmit: PropTypes.func,
      hasUsers: PropTypes.bool,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      disabled: PropTypes.bool,
      viewMode: PropTypes.string,
      submitText: PropTypes.string,
    }),
    defaultProps({
      handleCancel: () => {},
      initialValues: {},
      onSubmit: () => {},
      disabled: false,
      submitText: 'Submit',
    }),
    memo
  )
);
