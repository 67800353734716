import React, { useCallback, useMemo } from 'react';
import { IconButton } from '@nike/eds';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

export const DeleteButton = ({
  id,
  disabled,
  fieldName,
  onChange,
  setAnswerOptions,
}) => {
  const debounceDelete = useMemo(
    () =>
      debounce((updatedAnswerOptions) => {
        onChange({ target: { name: fieldName } }, updatedAnswerOptions);
      }, 100),
    [fieldName, onChange]
  );

  const handleDeleteOption = useCallback(() => {
    let updatedAnswerOptions = [];

    setAnswerOptions((prevOptions) => {
      updatedAnswerOptions = prevOptions?.filter((item) => {
        return item.id !== id;
      });
      return updatedAnswerOptions;
    });

    setTimeout(() => {
      debounceDelete(updatedAnswerOptions);
    }, 0);
  }, [id, debounceDelete, setAnswerOptions]);

  return (
    <IconButton
      id={ 'delete-' + id }
      icon={ 'Delete' }
      variant={ 'ghost' }
      size={ 'medium' }
      disabled={ disabled }
      onClick={ handleDeleteOption }
    />
  );
};

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  onChange: PropTypes.func,
  setAnswerOptions: PropTypes.func,
};
