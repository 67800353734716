/* eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RenderField from './utils';
import styles from './index.module.scss';
import { formTypes, string } from 'lib/propTypes';
import { IconButton } from '@nike/eds';
import { getRepeatingTextFields } from './lib';
import { length } from 'ramda';
import { useFormikContext } from 'formik';
import { getFieldType } from './RepeatableFieldsetUtils';

const RepeatableFields = ({
  fields,
  setFieldValue,
  setFormState,
  formState,
  errors,
  disabled,
  layoutType,
}) => {
  const [repeatableFields, setRepeatableFields] = useState([]);
  const isRepeatableType = ['consentDocument'].includes(layoutType);
  const isRepeatableField = (type) => ['repeatingText'].includes(type);
  const fieldKey = 'sections';
  const { values } = useFormikContext();

  useEffect(() => {
    if (fields.length > 0 && !repeatableFields[0]?.value) {
      const initialFields = isRepeatableType
        ? isRepeatableField(fields?.[0]?.type)
          ? fields?.[0]?.value?.sort(
            (item1, item2) => item1.sortOrder - item2.sortOrder
          )
          : []
        : [];

      setRepeatableFields(initialFields);
    }
  }, [fields]);

  const handleRepeatableChange = useCallback(
    ({ target }) => {
      const key = target?.id;
      const value = target?.value;
      const updatedFields = repeatableFields;
      const objIndex = updatedFields.findIndex((obj) => obj.field === key);
      updatedFields[objIndex].value = value;

      setRepeatableFields(updatedFields);
      setFieldValue(fieldKey, updatedFields);
      setFormState({
        ...formState,
        [fieldKey]: {
          updatedFields,
        },
      });
    },
    [formState, repeatableFields]
  );

  const handleRemoveField = useCallback(
    (handle) => () => {
      const updatedFields = repeatableFields.filter(
        (field) => field.field !== handle
      );
      Object.keys(updatedFields).forEach(
        (item, index) => (updatedFields[item].sortOrder = index + 1)
      );

      //Call to re-index sections for error handling
      setFieldValue(fieldKey, updatedFields);
      setRepeatableFields(updatedFields);
      setFormState({
        ...formState,
        [fieldKey]: {
          updatedFields,
        },
      });
    },
    [formState, repeatableFields]
  );

  const handleAddField = useCallback(
    (type) => {
      const addedFieldOrder = {
        sortOrder: repeatableFields.length + 1,
        type: fields?.[0]?.type,
      };
      const addedField = getRepeatingTextFields(addedFieldOrder);

      const updatedFields = [...repeatableFields, addedField];
      setRepeatableFields(updatedFields);
      setFormState({
        ...formState,
        [fieldKey]: {
          updatedFields,
        },
      });
    },
    [formState, repeatableFields]
  );

  const showRemoveLink = !disabled && length(repeatableFields) > 1;

  const showAddLink =
    !disabled && length(repeatableFields) >= 0 && isRepeatableType;

  return (
    <>
      { repeatableFields.length
        ? repeatableFields
          ?.filter((field) => isRepeatableField(getFieldType(field)))
          .map((field) => {
            const fieldName = field.field;
            return (
              <div key={ fieldName }>
                <div>
                  { showRemoveLink && (
                    <a
                      className={ styles.consentRemoveLink }
                      onClick={ handleRemoveField(fieldName) }
                    >
                      Remove
                    </a>
                  ) }
                  <RenderField
                    field={{ ...field, layoutType, values }}
                    onChange={ handleRepeatableChange }
                    errors={ errors }
                    disabled={ disabled }
                  />
                </div>
              </div>
            );
          })
        : null }
      { showAddLink && (
        <IconButton icon="Plus" onClick={ handleAddField } label="add field" />
      ) }
    </>
  );
};

RepeatableFields.propTypes = {
  ...formTypes,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      activelayoutId: string,
      field: string,
      message: string,
      type: string,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.number,
          helperText: string,
          sortOrder: PropTypes.number,
          type: string,
          value: string,
        })
      ),
    })
  ),
  layoutType: string,
};

export default RepeatableFields;
