import React, { memo, useCallback, useState } from 'react';
import { RadioGroup, Radio } from '@nike/eds';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import styles from './RadioSet.module.scss';
import { isTextOrHiddenType, requiredError } from 'components/forms/utils';
import RadioLabel from './RadioLabel';

const RadioSet = ({
  onChange,
  disabled,
  label,
  className,
  options,
  selectedValue,
  hasErrors,
  errorMessage,
  answerOptionsExists,
  disableHiddenOption,
  ...props
}) => {
  const [{ name }, meta] = useField(props);
  const [isHovered, setIsHovered] = useState(false);
  const [isDisableHiddenType, setIsDisableHiddenType] = useState(false);

  const handleMouseEnter = useCallback(
    (value) => {
      const disableHidden = disableHiddenOption && value === 'hidden';
      setIsHovered(
        disableHidden ||
          (!isTextOrHiddenType(selectedValue) &&
            answerOptionsExists &&
            isTextOrHiddenType(value))
      );
      setIsDisableHiddenType(disableHidden);
    },
    [selectedValue, answerOptionsExists]
  );

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  });

  return (
    <FieldWrapper>
      <div onMouseOut={ handleMouseLeave }>
        <RadioGroup
          className={ styles.radioFieldLabel }
          label={ label }
          name={ name }
          valueSelected={ selectedValue }
          orientation="horizontal"
          onChange={ onChange }
          hasErrors={
            !!(meta.touched && hasErrors) || !!(meta.touched && meta.error)
          }
          errorMessage={ errorMessage || requiredError }
        >
          { options &&
            options.map((opt) => {
              const isDisabled =
                (disableHiddenOption && opt.value === 'hidden') ||
                (isTextOrHiddenType(opt.value) && answerOptionsExists);

              return (
                <Radio
                  key={ `${ name }-${ opt.value }` }
                  className={ `${ isDisabled ? styles.disabled : '' }` }
                  label={
                    <RadioLabel
                      label={ opt.label }
                      value={ opt.value }
                      handleMouseEnter={ handleMouseEnter }
                    />
                  }
                  value={ opt.value }
                  id={ `${ name }-${ opt.value }` }
                  disabled={
                    isDisabled ? true : disabled ? disabled : opt.disabled
                  }
                  // eslint-disable-next-line
                  onMouseEnter={useCallback(() => handleMouseEnter(opt.value))}
                />
              );
            }) }
        </RadioGroup>

        { isHovered && (
          <div
            className={ `${ styles.tooltip } ${
              isDisableHiddenType
                ? styles.hiddenStyle
                : styles.textAndHiddenStyle
            }` }
          >
            <span> ⚠️ </span>
            { isDisableHiddenType
              ? 'Conditional question cannot have a hidden question type'
              : 'Question Type cannot be changed when Answer Options are present.' }
          </div>
        ) }
      </div>
    </FieldWrapper>
  );
};

RadioSet.propTypes = {
  answerOptionsExists: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableHiddenOption: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasErrors: PropTypes.bool,
  label: PropTypes.element,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedValue: PropTypes.string,
};

export default memo(RadioSet);
