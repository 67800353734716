/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import Editor from 'rich-markdown-editor';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { nike as tokens } from '@nike/nike-design-tokens';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import { FieldError } from 'components/fields';
import { useParams } from 'react-router-dom';
import { CONSENT_FORM } from 'lib/layoutUtils';
import { Text } from '@nike/eds';
import { sanitizeRichText } from './utils';

const useStyles = makeStyles((theme) => ({
  fadeInContainer: {
    animation: 'fadeIn 0.2s ease-in-out',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  main: {
    '& >div>div': {
      margin: 16,
      fontSize: 16,
    },
    '& >div': {
      padding: 8,
      borderRadius: 4,
      resize: 'auto',
      overflow: 'auto',
    },
    '& ul': {
      listStyle: 'disc',
    },
    '& ol': {
      listStyleType: 'decimal',
    },
  },
  error: {
    '& >div': {
      border: '1px solid red',
    },
  },
  disabled: {
    '& >div': {
      border: '1px solid #CACACB',
      backgroundColor: tokens.paletteGrey3,
    },
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    paddingBottom: 10,
    display: 'block',
  },
}));

const RichTextField = ({ label, message, ...props }) => {
  const [field] = useField(props.id);
  const classes = useStyles();
  const { disabled, errorMessage, hasErrors, value } = props;
  const { type } = useParams();
  const isConsentForm = type === CONSENT_FORM;

  const handleChange = debounce(
    (value) => {
      const name = field.name;
      const text = sanitizeRichText(value());
      props?.onChange({ target: { id: name, value: text, name } });
    },
    isConsentForm ? 0 : 300
  ); //Adding some delay to avoid unnecessary rendering
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    // rerendering the comp to reflect the updated value in Editor
    const delay = setTimeout(
      () => {
        setShouldRender(true);
      },
      isConsentForm ? 0 : 500
    );
    return () => clearTimeout(delay);
  }, []);

  return (
    shouldRender && (
      <div className={ `${ !isConsentForm && classes.fadeInContainer }` }>
        <label className={ classes.label }>{ label }</label>
        <FieldWrapper disabled={ disabled }>
          <Editor
            id={ props.id }
            readOnly={ props.disabled }
            readOnlyWriteCheckboxes={ true }
            placeholder={ null }
            // Using default-value-field over value-field.
            // Setting value will cause unnecessary side-effects ex: cursor becomes funky
            // ref: https://github.com/outline/rich-markdown-editor#value
            defaultValue={ value }
            template={ false }
            className={ `${ classes.main } ${ hasErrors && classes.error } ${
              disabled && classes.disabled
            }` }
            onChange={ handleChange }
            autoFocus={ props.autoFocus }
            disableExtensions={ [
              'code_inline',
              'checkbox_item',
              'checkbox_list',
              'image',
              'placeholder',
              'container_notice',
              'emoji',
              'hr',
              'code_fence',
              'code_block',
              'youtube',
            ] }
          />
          { message && (
            <Text
              font={ 'legal-1' }
              as={ 'p' }
              color="secondary"
              className={ 'message' }
            >
              { message }
            </Text>
          ) }
          { hasErrors ? <FieldError>{ errorMessage }</FieldError> : null }
        </FieldWrapper>
      </div>
    )
  );
};

RichTextField.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  hasErrors: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.element,
  message: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

RichTextField.defaultProps = {
  disabled: false,
  autoFocus: false,
};

export default RichTextField;
