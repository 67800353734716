import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@nike/eds';
import { applyTo, pipe, pick } from 'ramda';
import { withFormik } from 'formik';

import { Grid } from '@material-ui/core';
import { TextField } from 'components/fields';
import Button from 'components/Button';

// aliased
import { propTypes, defaultProps } from 'lib/react';

// local
import styles from './index.module.scss';

export default applyTo(
  ({ goBack }) => {
    return (
      <form>
        <Grid container={ true } spacing={ 2 }>
          <Grid item={ true } className={ styles.gridRow }>
            <Text font="title-1" className={ styles.title }>
              Event-Job Details
            </Text>
          </Grid>

          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField
              data-testid="Job ID-input"
              name="jobId"
              label="Job ID"
              disabled={ true }
            />
          </Grid>

          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField name="messageId" label="Message ID" disabled={ true } />
          </Grid>

          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField name="eventData" label="Event Data" disabled={ true } />
          </Grid>
          <Grid
            item={ true } className={ styles.gridColumn } xs={ 12 } sm={ 6 }
            lg={ 5 }>
            <TextField name="eventType" label="Event Type" disabled={ true } />
          </Grid>

          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField name="attempts" label="Attempts" disabled={ true } />
          </Grid>

          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField name="who" label="Updated by Whom" disabled={ true } />
          </Grid>

          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField
              name="updateTimestamp"
              label="Updated Date"
              disabled={ true }
            />
          </Grid>
          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } lg={ 5 }>
            <TextField
              name="createTimestamp"
              label="Created Date"
              disabled={ true }
            />
          </Grid>

          <Grid
            item={ true } className={ styles.gridColumn } xs={ 12 } sm={ 6 }
            lg={ 5 }>
            <TextField name="status" label="Status" disabled={ true } />
          </Grid>
        </Grid>

        <div className={ styles.btns }>
          <Grid
            item={ true } className={ styles.gridColumn } xs={ 12 } sm={ 6 }
            lg={ 3 }>
            <Button appearance="secondary" onClick={ goBack } type="button">
              Back
            </Button>
          </Grid>
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: (data) =>
        pick(
          [
            'jobId',
            'createTimestamp',
            'eventType',
            'status',
            'messageId',
            'eventData',
            'attempts',
            'updateTimestamp',
            'who',
            'goBack',
          ],
          data
        ),
      displayName: 'Event Jobs',
    }),
    propTypes({
      handleCancel: PropTypes.func,
      initialValues: PropTypes.shape({
        jobId: PropTypes.string,
        messageId: PropTypes.string,
        eventType: PropTypes.string,
        eventData: PropTypes.string,
        attempts: PropTypes.number,
        status: PropTypes.string,
        createTimestamp: PropTypes.string,
        updateTimestamp: PropTypes.string,
        goBack: PropTypes.func,
        who: PropTypes.string,
      }),
      disabled: PropTypes.bool,
    }),
    defaultProps({
      handleCancel: () => {},
      initialValues: {},
      disabled: false,
    }),
    memo
  )
);
