/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import { Field } from 'formik';
import { getFieldLabel, validateCustomInquiryVariables } from '../../lib';
import { TextField } from '@nike/eds';
import {
  isCategoryField,
  QuestionSetTypes,
} from '../../RepeatableFieldsetUtils';
import {
  customVariableSupportText,
  requiredError,
} from 'components/forms/utils';
import styles from './index.module.scss';
import { GuideModal } from '../GuideModal';
import { debounce, isEmpty } from 'lodash';
import WarningPopover from './PopoverWarning';
import OptionalToggle from './OptionalToggle';

export const TextComponent = ({
  fieldId,
  fieldLabel,
  fieldValue,
  fieldName,
  fieldType,
  fieldHelperText,
  className,
  placeholder,
  disabled,
  requiredFields,
  onChange,
  errorMessage,
  isInquiry,
  isOptionalQuestion,
  inheritedValue,
}) => {
  const [textFieldValue, setTextFieldValue] = useState(fieldValue ?? '');

  const [currentFieldName, fieldSetId] = fieldName?.split('-');

  const isQuestionField = currentFieldName === QuestionSetTypes.QUESTION_LABEL;
  const isCategoryTextField = isCategoryField(currentFieldName);
  const isCategoryNameField =
    currentFieldName === QuestionSetTypes.CATEGORY_NAME;
  const isRequiredField =
    requiredFields[fieldName] || isQuestionField || isCategoryNameField;

  const inquiryVariables = useMemo(() => {
    if (isCategoryTextField) {
      const variables = sessionStorage.getItem('inquiryVariables');
      return variables ? JSON.parse(variables) : [];
    }
    return [];
  }, [isCategoryTextField]);

  // Syncing the categoryName field with questionCategory field
  useEffect(() => {
    if (isCategoryNameField && !isEmpty(inheritedValue)) {
      setTextFieldValue(inheritedValue);
    }
  }, [inheritedValue]);

  // Syncing local state with the formik's fieldValue
  useEffect(() => {
    if (!isInquiry && fieldValue !== textFieldValue) {
      setTextFieldValue(fieldValue);
    }
  }, [fieldValue]);

  const debounceOnChange = useMemo(
    () => debounce((event) => onChange(event), 300),
    [onChange]
  );

  const handleTextChange = useCallback(
    (event) => {
      setTextFieldValue(event.target.value);
      setTimeout(() => {
        debounceOnChange(event);
      }, 0);
    },
    [debounceOnChange]
  );

  return (
    <Field
      key={ fieldId }
      name={ fieldName }
      placeholder={ placeholder || fieldName }
      className={ className }
    >
      { ({ field, meta, form }) => {
        const fieldTouched = meta.touched;

        const showWarning = useMemo(() => {
          if (isCategoryTextField) {
            if (inquiryVariables.length === 0) {
              return false;
            }
            return (
              fieldTouched &&
              validateCustomInquiryVariables(textFieldValue, inquiryVariables)
            );
          }
          return false;
        }, [fieldTouched, textFieldValue, inquiryVariables]);

        const categoryError =
          isCategoryTextField &&
          fieldTouched &&
          textFieldValue?.length > 0 &&
          !!errorMessage &&
          errorMessage;

        const isRequiredError = errorMessage === requiredError;
        const shouldShowErrorMessage = isRequiredError
          ? isEmpty(textFieldValue)
          : !!errorMessage;

        const errorMsg =
          (fieldTouched && shouldShowErrorMessage && errorMessage) ||
          categoryError;

        return (
          <FieldWrapper disabled={ disabled } className={ className }>
            <div className={ showWarning ? styles.rowContainer : '' }>
              <TextField
                hasErrors={ !!errorMsg }
                errorMessage={ errorMsg }
                disabled={ disabled }
                key={ fieldId }
                type={ fieldType }
                id={ fieldName }
                name={ fieldName }
                defaultValue={ textFieldValue || '' }
                className={ className }
                value={ textFieldValue || '' }
                label={
                  <label>
                    { fieldLabel || getFieldLabel(field.name) }
                    { isRequiredField && !errorMsg && (
                      <span className="asterisk">*</span>
                    ) }
                  </label>
                }
                message={
                  fieldHelperText === customVariableSupportText ? (
                    <GuideModal />
                  ) : (
                    fieldHelperText
                  )
                }
                onChange={ handleTextChange }
                // eslint-disable-next-line react/jsx-no-bind
                onBlur={ () => form.setFieldTouched(fieldName, true) }
              />
              { isCategoryTextField &&
                inquiryVariables.length > 0 &&
                showWarning && <WarningPopover /> }
            </div>
            { isInquiry && isQuestionField && (
              <OptionalToggle
                isOptionalQuestion={ isOptionalQuestion }
                fieldSetId={ fieldSetId }
                disabled={ disabled }
                onChange={ onChange }
              />
            ) }
          </FieldWrapper>
        );
      } }
    </Field>
  );
};

TextComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  fieldHelperText: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldType: PropTypes.string,
  fieldValue: PropTypes.string,
  inheritedValue: PropTypes.string,
  isInquiry: PropTypes.bool,
  isOptionalQuestion: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  requiredFields: PropTypes.shape({}),
};
