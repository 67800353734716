/* eslint-disable complexity */
/* eslint-disable max-statements */
import {
  kebabCase,
  keys as _keys,
  startCase,
  isEmpty,
  isArray,
  isUndefined,
  omitBy,
} from 'lodash';
import { dissoc, flatten, propOr, hasPath } from 'ramda';
import {
  InitiativeParticipationStatuses,
  ContentStatus,
  PermissionGroups,
  ViewMode,
  ChoiceTypes,
} from 'lib/enums';
import {
  ACTIVITY_GEAR_PPIS,
  activityGearPPIs,
  convertHandleToTitle,
  getPPIOptions,
  hiddenPPIs,
} from 'components/forms/Assets/lib';
import { useFetchInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import { useMemo } from 'react';
import { addHours, format, parse, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  Activity,
  Club,
  HeartRate,
  LocationPin,
  Notification,
} from '@nike/nike-design-system-icons';
import {
  QuestionSetTypes,
  getFieldValuesByQuestionSetAndNames,
} from 'components/forms/Assets/RepeatableFieldsetUtils';
import {
  customVariableSupportText as message,
  isTextOrHiddenType,
  defaultRequiredPpis,
} from 'components/forms/utils';

const MOBILE_OVERVIEW_CARD = 'mobile-overview-card';
const MOBILE_RETURNING_CARD = 'mobile-returning-card';
export const MOBILE_DETAIL_SCREEN = 'mobile-detail-screen';
export const WEB_OVERVIEW_CARD = 'web-overview-card';
export const WEB_DETAIL_PAGE = 'web-detail-page';
export const CONSENT_FORM = 'consent-document';
export const SCREENER_FORM = 'screener';
export const SURVEY_FORM = 'survey';
export const DEFAULT_MEDIA = 'default-media';

export const CARD_CONSTANTS = [
  MOBILE_OVERVIEW_CARD,
  MOBILE_RETURNING_CARD,
  MOBILE_DETAIL_SCREEN,
  WEB_OVERVIEW_CARD,
  WEB_DETAIL_PAGE,
];

export const STATE_BASED_CONTENT = [MOBILE_DETAIL_SCREEN, WEB_DETAIL_PAGE];

export const CONTENT_CONSTANTS = [...CARD_CONSTANTS, DEFAULT_MEDIA];

export const CONSENT_CONSTANTS = [CONSENT_FORM];

export const INQUIRY_CONSTANTS = [SCREENER_FORM];

export const SURVEY_CONSTANTS = [SURVEY_FORM];

export const INQUIRIES = [SCREENER_FORM, SURVEY_FORM];

export const ASSET_CONSTANTS = [
  ...CONTENT_CONSTANTS,
  ...CONSENT_CONSTANTS,
  ...INQUIRY_CONSTANTS,
  ...SURVEY_CONSTANTS,
];

const TEXT_FIELD = 'text';
const TEXT_AREA = 'textarea';
const IMAGE_FIELD = 'image';
const RICH_TEXT_FIELD = 'richText';
const QUESTION_SET = 'questionSet';

export const permissionsList = [
  {
    label: 'Push Notifications',
    icon: Notification,
  },
  {
    label: 'Location',
    icon: LocationPin,
  },
  {
    label: 'Health Kit',
    icon: HeartRate,
  },
  {
    label: 'Motion and Activity',
    icon: Activity,
  },
  {
    label: 'Social Visibility',
    icon: Club,
  },
];

export const formatCloudinaryImage = (imageFile, maxWidth) => {
  return imageFile.includes('cloudinary') && imageFile.includes('/upload/')
    ? imageFile.replace('/upload/', `/upload/w_${ maxWidth },c_scale/`)
    : imageFile;
};

// eslint-disable-next-line complexity
const layoutValues = (layoutId, initiativeHandle, layoutType, values) => {
  const initiativeValues = {
    initiativeHandle,
    status: ContentStatus.PUBLISHED,
    contentType: layoutType,
    id: layoutId,
  };

  const fields = {
    title: (value) => ({
      value: values?.title || value,
      type: TEXT_FIELD,
      maxChars: 40,
    }),
    subtitle: (value) => ({
      value: values?.subtitle || value,
      type: TEXT_FIELD,
      maxChars: 85,
    }),
    text: (value) => ({
      type:
        kebabCase(layoutType) === WEB_OVERVIEW_CARD
          ? TEXT_AREA
          : RICH_TEXT_FIELD,
      value: values?.text || value,
      maxChars: 300,
    }),
    ctaText: (value) => ({
      type: TEXT_FIELD,
      value: values?.ctaText || value,
      maxChars: 15,
    }),
    logoImage: {
      type: IMAGE_FIELD,
      value: values?.logoImage || null,
      helperText:
        'Please upload a white logo as a transparent PNG or GIF./Recommended size: 240px x 168px./Max file size: 1 MB.',
    },
    backgroundImage: {
      type: IMAGE_FIELD,
      value: values?.backgroundImage || null,
      helperText: 'Recommended size: 984px x 1392px./Max file: size 1 MB.',
    },
    repeatingText: {
      type: RICH_TEXT_FIELD,
      sections: { text: values?.repeatingText, sortOrder: 1 },
      helperText: 'Enter rich text',
    },
    questionSet: {
      type: QUESTION_SET,
      questionSet: { text: values?.questionSet, sortOrder: 1 },
      helperText: 'TBD',
    },
  };

  switch (layoutType) {
    case MOBILE_OVERVIEW_CARD:
      return {
        title: fields.title(),
        subtitle: fields.subtitle(),
        ctaText: fields.ctaText('Learn More'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case MOBILE_RETURNING_CARD:
      return {
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case MOBILE_DETAIL_SCREEN:
      return {
        title: fields.title(),
        text: fields.text(),
        subtitle: fields.subtitle(),
        ctaText: fields.ctaText('Launch'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case WEB_OVERVIEW_CARD:
      return {
        title: fields.title(),
        subtitle: fields.subtitle('Welcome'),
        text: fields.text(),
        ctaText: fields.ctaText('Learn More'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case WEB_DETAIL_PAGE:
      return {
        title: fields.title(),
        text: fields.text(),
        subtitle: fields.subtitle(),
        ctaText: fields.ctaText('Learn More'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case CONSENT_FORM:
      return {
        sections: [fields.repeatingText('')],
        ...initiativeValues,
      };
    case SCREENER_FORM:
      return {
        questionSet: [fields.questionSet('')],
        ...initiativeValues,
      };
    case SURVEY_FORM:
      return {
        questionSet: [fields.questionSet('')],
        ...initiativeValues,
      };
    default:
      return null;
  }
};

function onlyPublishedCardsExist(cardsFiltered) {
  const nonPublishedCardsExists = cardsFiltered.filter(
    (content) => content?.status !== ContentStatus.PUBLISHED
  );

  return !(nonPublishedCardsExists.length > 0);
}

export const retrieveShortHandle = (initiativeHandle, getOrg = false) => {
  const lookupInitiativeDetails = (id) => {
    const { data: initData, isLoading: isInitDataLoading } =
      useFetchInitiativeQuery(id, { skip: !id });
    const initDataReturn = useMemo(() => {
      return !isInitDataLoading ? initData : {};
    }, [isInitDataLoading, initData]);
    return initDataReturn;
  };
  const initiativeDetails = lookupInitiativeDetails(initiativeHandle);
  const shortHandle = propOr('', 'shortHandle', initiativeDetails);
  return !getOrg
    ? shortHandle
    : {
      organization: propOr(
        PermissionGroups.DEFAULT_ORG.toLowerCase(),
        'organization',
        initiativeDetails
      ),
      shortHandle,
    };
};

export function generateDefaultCards(id, layoutData, values, shortHandle) {
  const getLayoutIdByLayoutHandle = (layoutHandle) =>
    layoutData.find(
      ({ status, handle }) =>
        handle === layoutHandle && status === ContentStatus.PUBLISHED
    )?.id;

  const cardsFiltered = layoutData.filter(
    (itm) => itm.contentType !== DEFAULT_MEDIA
  );

  const noCardsPresent = layoutData ? cardsFiltered.length === 0 : false;

  if (noCardsPresent || onlyPublishedCardsExist(cardsFiltered)) {
    const cardsShouldBeUpdated =
      !noCardsPresent && onlyPublishedCardsExist(cardsFiltered);

    const allLayoutTypesWithStates = [
      ...CARD_CONSTANTS.filter(
        (card) => !STATE_BASED_CONTENT.includes(card)
      ).map((card) => {
        return { layoutType: card, handleType: card };
      }),
      ...flatten(
        CARD_CONSTANTS.filter((card) => STATE_BASED_CONTENT.includes(card)).map(
          (card) =>
            InitiativeParticipationStatuses.map(
              (initiativeParticipationStatus) => {
                return {
                  layoutType: card,
                  handleType: `${ card }-${ initiativeParticipationStatus }`,
                  initiativeParticipationStatus,
                };
              }
            )
        )
      ),
    ];

    return allLayoutTypesWithStates.map((layout) => {
      const layoutHandle = constructLayoutHandle(
        shortHandle,
        layout.handleType.toLowerCase()
      );

      const layoutId = noCardsPresent
        ? null
        : cardsShouldBeUpdated && getLayoutIdByLayoutHandle(layoutHandle);

      const call = !layoutId ? 'post' : 'patch';
      const transformedValues = layoutValues(
        layoutId,
        id,
        layout.layoutType,
        values
      );
      if (call === 'patch') {
        transformedValues.id = layoutId;
      }
      transformedValues.initiativeParticipationStatus =
        layout.initiativeParticipationStatus;
      const fieldValues = Object.entries(transformedValues).reduce(
        (acc, [key, field]) => {
          const fieldValue = typeof field === 'object' ? field?.value : field;
          return { ...acc, [key]: fieldValue };
        },
        {}
      );

      return {
        call,
        layoutType: layout.layoutType,
        transformedValues: fieldValues,
      };
    });
  }
}

// eslint-disable-next-line max-params, complexity
export function checkAndUpdateConsentFields(
  id,
  values,
  layoutType,
  draftExist,
  initiativeParticipationStatus = undefined,
  shortHandle = undefined,
  viewMode = undefined
) {
  const cardType = kebabCase(layoutType);

  const layoutId = values.id;

  const updateType = layoutId && draftExist ? 'patch' : 'post';

  //Update status
  const status =
    cardType === CONSENT_FORM
      ? values?.status === ContentStatus.PUBLISHED && draftExist
        ? ContentStatus.PUBLISHED
        : ContentStatus.PREVIEW
      : ContentStatus.PREVIEW;

  //POST accepted handle and platform
  //PATCH is saying no to handle....
  const transformedValues = {
    status,
    initiativeHandle: id,
    sections: values.sections.map((section) => {
      return { sortOrder: section.sortOrder, text: section.value };
    }),
  };

  if (updateType === 'patch') {
    transformedValues.id = layoutId;
  }

  return {
    call: updateType,
    layoutType: cardType,
    values: transformedValues,
  };
}

const combineDateTime = (date, time) => `${ date }T${ time }`;

export function isEDTInEffect(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  if (isNaN(date.getTime())) {
    return false;
  }

  // Creating a Date object for the second Sunday in March at 2:00 AM
  const startEDT = new Date(year, 2, 8, 2, 0, 0);
  // Calculating the second Sunday in March (month 2, 0-indexed) from March 8th
  startEDT.setDate(8 + ((7 - startEDT.getDay()) % 7));

  // Creating a Date object for the first Sunday in November at 2:00 AM
  const endEDT = new Date(year, 10, 1, 2, 0, 0);
  // Calculating the first Sunday in November (month 10) from November 1st
  endEDT.setDate(1 + ((7 - endEDT.getDay()) % 7));

  // Handling the edge cases around the start and end of EDT
  const oneHourBeforeSecondSundayInMarch = new Date(startEDT);
  oneHourBeforeSecondSundayInMarch.setHours(
    oneHourBeforeSecondSundayInMarch.getHours() + 1
  ); // 1 hour after start of EDT

  const oneHourBeforeFirstSundayInNovember = new Date(endEDT);
  oneHourBeforeFirstSundayInNovember.setHours(
    oneHourBeforeFirstSundayInNovember.getHours() - 1
  ); // 1 hour before end of EDT

  // During the transition from EST to EDT
  if (date >= startEDT && date < oneHourBeforeSecondSundayInMarch) {
    return false; //Since, it's not yet EDT between 1:00 AM and 2:00 AM]
  }
  // During the transition from EDT to EST
  if (date >= oneHourBeforeFirstSundayInNovember && date < endEDT) {
    return false; //Since, it's still EDT between 1:00 AM and 2:00 AM]
  }
  // General check if the given date is within the EDT period
  return date >= startEDT && date < endEDT;
}

export const convertETtoUTC = (dateTime) => {
  if (!dateTime) {
    return null;
  }
  const parsedDateTime = parse(dateTime, 'yyyy-MM-dd\'T\'HH:mm', new Date());
  if (isNaN(parsedDateTime.getTime())) {
    return null;
  }
  const isEDT = isEDTInEffect(dateTime);
  const offset = isEDT ? 4 : 5;
  const adjustedDateTime = addHours(parsedDateTime, offset);
  return format(adjustedDateTime, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'');
};

export const adjustFromUTCtoET = (dateTime) => {
  if (!dateTime) {
    return null;
  }
  // Appending 'T00:00:00Z'/ :00Z to the input date if no time is provided
  const dateTimeWithTime =
    dateTime.length === 10
      ? `${ dateTime }T00:00:00Z`
      : dateTime.includes('T') && !dateTime.endsWith('Z')
        ? `${ dateTime }:00Z`
        : dateTime;

  const utcDate = parseISO(dateTimeWithTime);
  if (isNaN(utcDate.getTime())) {
    return null;
  }
  const easternDate = utcToZonedTime(utcDate, 'America/New_York');
  const formattedDate = format(easternDate, 'yyyy-MM-dd\'T\'HH:mm');
  return formattedDate;
};

export const getDateTime = (scheduleDataDateTime) => {
  const adjustedDateTime = adjustFromUTCtoET(scheduleDataDateTime);
  if (!adjustedDateTime) return [null, null];
  return adjustedDateTime.split('T');
};

const generatePPILabel = (str) => {
  if (str.startsWith('ppi-')) {
    return startCase(str.slice(4));
  }
  return str;
};

// eslint-disable-next-line
export function checkAndUpdateScreenerFields(
  id,
  values,
  layoutType,
  draftExist,
  _,
  shortHandle,
  viewMode
) {
  let updatedValues = values;
  const isValid = !isEmpty(values) && values?.questionSet instanceof Map;
  const questionSets = [...values.questionSet.values()].filter(
    (questionSet) => questionSet.value instanceof Map
  );
  if (layoutType === SCREENER_FORM || layoutType === SURVEY_FORM) {
    const gearModelQuestion =
      isValid &&
      questionSets.find((questionSet) => {
        return (
          questionSet.value.get(QuestionSetTypes.EXISTING_PPI)?.value?.value ===
          ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL
        );
      });
    const isAllHiddenPPIsExists =
      isValid &&
      hiddenPPIs.every((hiddenPPI) => {
        return questionSets.some((questionSet) => {
          return (
            questionSet.value.get(QuestionSetTypes.EXISTING_PPI)?.value
              ?.value === hiddenPPI
          );
        });
      });

    if (isValid && !isEmpty(gearModelQuestion) && !isAllHiddenPPIsExists) {
      updatedValues = structuredClone(values);
      let baseFieldSetId = gearModelQuestion.fieldSetId;
      let baseSortOrder =
        (isValid && values.questionSet.size) || gearModelQuestion.sortOrder;

      hiddenPPIs.forEach((ppi) => {
        const newQuestion = structuredClone(gearModelQuestion);
        baseFieldSetId += 1;
        baseSortOrder += 1;
        newQuestion.fieldSetId = baseFieldSetId;
        newQuestion.sortOrder = baseSortOrder;
        if (newQuestion.value instanceof Map) {
          newQuestion.value.forEach((field) => {
            if (field.field.includes(QuestionSetTypes.EXISTING_PPI)) {
              field.value = { label: generatePPILabel(ppi), value: ppi };
            }
            if (field.field.includes(QuestionSetTypes.ANSWER_TYPE)) {
              field.value = 'hidden';
            }
            if (field.field.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
              field.ppiHandle = ppi;
            }
          });
        }
        updatedValues.questionSet.set(newQuestion.fieldSetId, newQuestion);
      });
    } else if (isValid && isEmpty(gearModelQuestion)) {
      updatedValues.questionSet.forEach((questionSet, fieldSetId) => {
        if (questionSet.value instanceof Map) {
          const existingPPIValue = questionSet.value.get(
            QuestionSetTypes.EXISTING_PPI
          )?.value?.value;
          if (hiddenPPIs.includes(existingPPIValue)) {
            updatedValues.questionSet.delete(fieldSetId);
          }
        }
      });
    }
  }
  const contentType = kebabCase(layoutType);
  const layoutId = updatedValues.id;
  const updateType = layoutId && draftExist ? 'patch' : 'post';
  const { isAutomated } = updatedValues?.attributes;

  const { surveyStartDate, surveyEndDate, surveyTime, scheduleRate } =
    updatedValues;
  const startDateTime = combineDateTime(surveyStartDate, surveyTime);
  const startDateTimeUTC = convertETtoUTC(startDateTime);
  const endDateTime = combineDateTime(surveyEndDate, surveyTime);
  const endDateTimeUTC = endDateTime ? convertETtoUTC(endDateTime) : null;
  const schedule = {
    startDate: startDateTimeUTC,
    endDate: scheduleRate === 'Once' ? startDateTimeUTC : endDateTimeUTC,
    rate:
      scheduleRate === 'Once'
        ? null
        : {
          unit: 'days',
          value: scheduleRate === 'Daily' ? 1 : 7,
        },
  };
  const updatedQuestionSets = [...updatedValues.questionSet.values()];
  const categoryList =
    isValid &&
    updatedQuestionSets.map((questionSet) => {
      if (questionSet.value instanceof Map) {
        return getFieldValuesByQuestionSetAndNames(questionSet.value, [
          QuestionSetTypes.QUESTION_CATEGORY,
          QuestionSetTypes.CATEGORY_NAME,
          QuestionSetTypes.CATEGORY_TITLE,
          QuestionSetTypes.CATEGORY_SUBTITLE,
          QuestionSetTypes.CATEGORY_TEXT,
          QuestionSetTypes.BACKGROUND_IMAGE,
          QuestionSetTypes.CATEGORY_CHOICE,
        ]);
      }
    });

  const allCategories = categoryList.reduce((acc, category) => {
    const key = category[QuestionSetTypes.QUESTION_CATEGORY]?.value;
    if (!acc[key]) {
      acc[key] = { ...category };
    }
    return acc;
  }, []);

  const choicesFormat = (choiceSet) => {
    const hasName = hasPath([0, 'name'], choiceSet);
    const result =
      choiceSet &&
      (hasName
        ? choiceSet.map((itm) => ({
          ...itm,
          value:
              itm.choiceType === ChoiceTypes.EDITABLE
                ? itm.name
                : itm.value || itm,
        }))
        : choiceSet.map((itm, idx) => {
          const baseItem = {
            name: itm.label || itm.value || itm,
            sortOrder: idx,
            value:
                itm.choiceType === ChoiceTypes.EDITABLE
                  ? itm.label
                  : itm.value || itm,
            selected: false,
            choiceType: itm.choiceType || ChoiceTypes.FIXED,
          };

          return {
            ...baseItem,
            ...{
              attributes: {
                ...(layoutType === SCREENER_FORM && {
                  isReject: !!(itm.isReject && isAutomated),
                }),
                description: itm?.description || null,
              },
            },
          };
        }));
    return result;
  };

  const transformedValues = {
    status: 'preview',
    name:
      layoutType === SURVEY_FORM
        ? updatedValues.surveyName
        : `${ id }-${ contentType }`,
    initiativeHandle: id,
    inquiryType: contentType,
    attributes: updatedValues?.attributes ?? null,
    ...(layoutType === SURVEY_FORM && updatedValues?.isSurveyScheduled
      ? { schedule }
      : null),
    categories: Object.values(allCategories).map((category, idx) => {
      return {
        // this will be changed to the populate the categoryName field of a particular categoryHandle
        handle: `cat-${ kebabCase(
          propOr(null, 'value')(category[QuestionSetTypes.QUESTION_CATEGORY])
        ) }`,
        name: category[QuestionSetTypes.CATEGORY_NAME],
        title: category[QuestionSetTypes.CATEGORY_TITLE] || null,
        subtitle: category[QuestionSetTypes.CATEGORY_SUBTITLE] || null,
        text: category[QuestionSetTypes.CATEGORY_TEXT] || null,
        backgroundImage: category[QuestionSetTypes.BACKGROUND_IMAGE] || null,
        sortOrder: category?.sortOrder || idx + 1,
        questions: category[QuestionSetTypes.CATEGORY_CHOICE]
          ? []
          : updatedQuestionSets
            .filter(
              (questionSet) =>
                propOr(
                  null,
                  'value'
                )(
                  questionSet.value.get(QuestionSetTypes.QUESTION_CATEGORY)
                    ?.value
                ) ===
                  propOr(
                    null,
                    'value'
                  )(category[QuestionSetTypes.QUESTION_CATEGORY])
            )
          //eslint-disable-next-line complexity
            .map((questionSet) => {
              const questionFields = questionSet.value;
              const ppiHandle = questionFields.get(
                QuestionSetTypes.EXISTING_PPI
              )?.value?.value;
              const isActivityGearPPI = activityGearPPIs.includes(ppiHandle);
              const questionField = questionFields.get(
                QuestionSetTypes.QUESTION_LABEL
              );
              const questionLabel = isActivityGearPPI
                ? generatePPILabel(ppiHandle)
                : questionField?.value || '';
              const answerType = questionFields.get(
                QuestionSetTypes.ANSWER_TYPE
              )?.value;
              const questionAnswerType = isTextOrHiddenType(answerType)
                ? 'string'
                : answerType || 'choice';
              const answerOptions =
                  questionFields.get(QuestionSetTypes.ANSWER_OPTIONS)?.value ||
                  [];
              const ppiOptions = getPPIOptions(ppiHandle);
              const isHiddenAnswerType =
                  answerType === 'hidden' && !answerOptions.length;
              const displayType = isHiddenAnswerType ? 'hidden' : null;
              const isOptionalQuestion = questionField?.optional || false;
              const conditionalToggle = questionFields.get(
                QuestionSetTypes.CONDITIONAL_TOGGLE
              ).value;
              let conditionalOptions = [];
              let conditionalOptionShow = [];
              let conditionalSelectionType = 'any';
              let conditionalPPI = '';
              if (conditionalToggle) {
                const conditionalField = questionFields.get(
                  QuestionSetTypes.CONDITIONAL_OPTIONS
                );
                conditionalSelectionType = conditionalField?.selectionType;
                conditionalPPI = conditionalField?.conditionalPPI;
                conditionalOptions = conditionalField?.value;
                conditionalOptionShow = Array.isArray(conditionalOptions)
                  ? conditionalOptions?.map(({ value }) => value)
                  : [conditionalOptions?.value ?? null].filter(Boolean);
              }
              return {
                name: questionLabel,
                text: questionLabel,
                ppiHandle,
                displayType,
                optional: isOptionalQuestion,
                questionType: questionAnswerType,
                attributes: {
                  ...(isHiddenAnswerType && {
                    deepLinkEnabled: true,
                  }),
                  ...(conditionalToggle && {
                    visibility: 'conditional',
                    'conditional-ppi':
                        questionSet.conditionalPPI ?? conditionalPPI,
                    'conditional-operator':
                        conditionalSelectionType === 'all'
                          ? 'equals'
                          : 'includes',
                    'conditional-option-show': conditionalOptionShow,
                  }),
                  ...(layoutType === SCREENER_FORM &&
                      !isEmpty(answerOptions) && {
                    ...(answerOptions.some((choice) => choice.isReject) &&
                          isAutomated && {
                      hasRejectChoices: true,
                    }),
                    ...(answerOptions.every((choice) => !choice.isReject) &&
                          isAutomated && {
                      hasRejectChoices: false,
                    }),
                  }),
                },
                sortOrder: questionSet.sortOrder || 1,
                //Uses dynamic answer options
                choices:
                    layoutType === SCREENER_FORM || SURVEY_FORM
                      ? choicesFormat(answerOptions)
                      : ppiOptions
                        ? choicesFormat(ppiOptions)
                        : questionAnswerType === 'string'
                          ? []
                          : choicesFormat(answerOptions),
              };
            }),
      };
    }),
  };

  const updateAttributes = {
    ...omitBy(transformedValues.attributes, isUndefined),
    ...(updatedValues?.surveyId && { surveyId: updatedValues.surveyId }),
  };
  if (updateType === 'patch') {
    transformedValues.id = layoutId;
    transformedValues.attributes = updateAttributes;
  } else if (contentType === SURVEY_FORM) {
    const handle = updatedValues.surveyHandle ?? updatedValues.handle;
    const prefixedHandle = `svy-${ handle }`;
    //Handling survey handles for existing and new surveys
    transformedValues.handle =
      (!draftExist && !layoutId) || viewMode === ViewMode.CREATE
        ? prefixedHandle
        : handle;
    transformedValues.attributes = updateAttributes;
  } else {
    //Uses existing way of creating handles for non-survey assets
    transformedValues.handle = `${ shortHandle }-${ contentType }`;
  }

  return {
    call: updateType,
    inquiryType: contentType,
    values: transformedValues,
  };
}

export function addAssetType(data, assetType, contentType) {
  return {
    ...data,
    assetType,
    contentType,
  };
}

export function matchContentToAssetFormat(layoutData) {
  return {
    ...layoutData,
    assetType: 'content',
  };
}

export function formatAssetData(data, type) {
  const updatedData = [SCREENER_FORM, CONSENT_FORM, SURVEY_FORM].includes(type)
    ? dissoc(
      'platform',
      dissoc('assetType', dissoc('contentType', dissoc('handle', data)))
    )
    : dissoc('platform', dissoc('assetType', dissoc('handle', data)));

  return updatedData;
}

//eslint-disable-next-line max-params
export async function updateLayout(
  updateType,
  layoutId,
  useUpdate,
  contentData
) {
  const siblingData = contentData.filter((item) => item.id === layoutId).pop();

  const dataPerUpdateType =
    updateType === 'patch'
      ? dissoc('handle', siblingData)
      : dissoc('id', siblingData);

  const dataCleaned = dissoc(
    'assetType',
    dissoc('createTimestamp', dissoc('updateTimestamp', dataPerUpdateType))
  );

  const transformedValues =
    updateType === 'patch'
      ? { ...dataCleaned, status: 'published' }
      : { ...dataCleaned, status: 'preview' };

  await useUpdate(transformedValues);
}

// eslint-disable-next-line max-params, complexity
export function checkAndUpdateMediaFields(
  id,
  values,
  layoutType,
  draftExist,
  initiativeParticipationStatus,
  shortHandle = undefined,
  viewMode = undefined
) {
  const contentType = kebabCase(layoutType);

  const layoutId = values.id;

  const updateType =
    contentType === DEFAULT_MEDIA && layoutId
      ? 'patch'
      : draftExist
        ? 'patch'
        : 'post';

  const transformedValues = {
    initiativeHandle: id,
    status:
      contentType === DEFAULT_MEDIA
        ? ContentStatus.PUBLISHED
        : ContentStatus.PREVIEW,
    contentType,
    text: values.text || values.richText,
    title: values.headline || values.title,
    subtitle: values.tagline || values.subtitle,
    initiativeParticipationStatus: initiativeParticipationStatus || null,
    ctaText: values.ctaText,
    logoImage: values.logoImageFileId || values.logoImage,
    backgroundImage: values.backgroundImageFileId || values.backgroundImage,
  };

  if (updateType === 'patch') {
    transformedValues.id = layoutId;
  }

  return {
    call: updateType,
    layoutType: contentType,
    values: transformedValues,
  };
}

export const getAssetType = (layoutType) => {
  const assetType = layoutType.includes('-')
    ? layoutType
    : kebabCase(layoutType);
  if (CARD_CONSTANTS.includes(assetType)) {
    return 'content';
  }
  return assetType;
};

const getQuestionType = (questionSet) => {
  return questionSet.questionType === 'string'
    ? questionSet.displayType && questionSet.displayType === 'hidden'
      ? 'hidden'
      : 'text'
    : questionSet.questionType;
};

const categoryHandleToCategoryName = (str) => {
  if (str.startsWith('cat-')) {
    return startCase(str.slice(4));
  }
  return str;
};

const mapOptions = (options) =>
  options?.map((option) => ({
    label: option.label || option.name || option,
    value: option.value || option,
  }));

export const radioSetOptions = [
  { label: 'Single select', value: 'choice' },
  { label: 'Multi-select', value: 'multi' },
  { label: 'Text', value: 'text' },
  { label: 'Hidden', value: 'hidden' },
];

export const getFilteredPPIs = (ppiOptions) => {
  const excludedPPIs = new Set([...defaultRequiredPpis, 'ppi-phone-number']);
  const ppiList = ppiOptions.filter((opt) => !excludedPPIs.has(opt.value));
  return ppiList;
};
export function formatInquiryDataForAsset(data, assetType, ppisList) {
  const categoryOptions = [];
  const sortedAndGroupedCategories = data.categories
    .map((category) => {
      const sortedQuestions = [...category.questions].sort(
        (idx1, idx2) => idx1.sortOrder - idx2.sortOrder
      );
      const handle = category.handle ?? category.name;
      categoryOptions.push({
        label: handle,
        value: handle,
      });
      return {
        ...category,
        questions: !isEmpty(sortedQuestions) ? sortedQuestions : [{}],
        handle: categoryHandleToCategoryName(handle),
      };
    })
    .sort((cat1, cat2) => cat1.sortOrder - cat2.sortOrder);

  const sortedData = {
    ...data,
    categories: sortedAndGroupedCategories,
  };

  const questionSets = [];

  sortedData.categories?.forEach((categorySet) =>
    categorySet.questions.forEach((questionSet, idx) => {
      const categoryValue = questionSet.questionCategory
        ? {
          label: questionSet.questionCategory,
          value: questionSet.questionCategory,
        }
        : { label: categorySet.handle, value: categorySet.handle };
      const ppiHandle = questionSet.ppiHandle;

      const conditionalPPI = questionSet.attributes?.['conditional-ppi'];
      let conditionalOptionsFieldVal;
      let conditionalPPIAnsOptions;
      if (conditionalPPI) {
        const conditionalOptionShow =
          questionSet.attributes?.['conditional-option-show'];
        conditionalPPIAnsOptions = getPPIOptions(conditionalPPI);
        const conditionalValues = conditionalPPIAnsOptions?.filter((option) =>
          conditionalOptionShow?.includes(option.value)
        );
        conditionalOptionsFieldVal = isArray(conditionalValues)
          ? mapOptions(conditionalValues)
          : isArray(conditionalOptionShow)
            ? mapOptions(conditionalOptionShow)
            : conditionalOptionShow
              ? { label: conditionalOptionShow, value: conditionalOptionShow }
              : null;
      }
      const fieldSetId = getRandomInt();

      questionSets.push({
        field: 'questionSet',
        type: 'questionSet',
        fieldSetId,
        sortOrder: questionSet.sortOrder || idx + 1,
        hideQuestion:
          questionSet.displayType === 'hidden' &&
          hiddenPPIs.includes(ppiHandle),
        ppiHandle,
        ...(conditionalPPI && { conditionalPPI }),
        value: [
          {
            field: QuestionSetTypes.QUESTION_CATEGORY + '-' + fieldSetId,
            sortOrder: 1,
            type: 'select',
            value: categoryValue,
            label: 'Category',
            options: categoryOptions,
            isCreatable: true,
          },
          {
            field: QuestionSetTypes.CATEGORY_NAME + '-' + fieldSetId,
            sortOrder: 2,
            type: 'text',
            label: 'Category Name',
            value: categorySet.name ?? categoryValue?.value,
            className: 'categoryFields',
            message,
          },
          {
            field: QuestionSetTypes.CATEGORY_TITLE + '-' + fieldSetId,
            sortOrder: 3,
            type: 'text',
            label: 'Category Title',
            value: categorySet.title,
            className: 'categoryFields',
            message,
          },
          {
            field: QuestionSetTypes.CATEGORY_SUBTITLE + '-' + fieldSetId,
            sortOrder: 4,
            label: 'Category Subtitle',
            type: 'text',
            value: categorySet.subtitle,
            className: 'categoryFields',
            message,
          },
          {
            field: QuestionSetTypes.CATEGORY_TEXT + '-' + fieldSetId,
            sortOrder: 5,
            label: 'Category Text',
            type: 'richText',
            value: categorySet.text,
            className: 'categoryFields',
            message,
          },
          {
            field: QuestionSetTypes.BACKGROUND_IMAGE + '-' + fieldSetId,
            sortOrder: 6,
            label: 'Background Image',
            type: 'backgroundImage',
            value: categorySet.backgroundImage,
            className: 'categoryFields',
          },
          {
            field: QuestionSetTypes.CATEGORY_CHOICE + '-' + fieldSetId,
            sortOrder: 7,
            label: 'This category does not have questions associated with it',
            type: 'choice',
            value: !questionSet.text,
            className: 'categoryFields',
            disabled: !!questionSet.text,
          },
          {
            field: QuestionSetTypes.CONDITIONAL_TOGGLE + '-' + fieldSetId,
            label: 'Conditional Toggle',
            sortOrder: 8,
            type: 'toggle',
            value: questionSet?.attributes?.visibility === 'conditional',
          },
          {
            field: QuestionSetTypes.CONDITIONAL_OPTIONS + '-' + fieldSetId,
            label: 'Conditional Options',
            sortOrder: 9,
            type: 'select',
            value: conditionalOptionsFieldVal,
            options:
              conditionalPPIAnsOptions?.map((option) => ({
                label: option.label || option.name,
                value: option.value,
              })) ?? [],
            selectionType:
              questionSet.attributes?.['conditional-operator'] === 'all'
                ? 'all'
                : 'any',
            conditionalPPI,
          },
          {
            field: QuestionSetTypes.QUESTION_LABEL + '-' + fieldSetId,
            sortOrder: 10,
            type: 'text',
            value: questionSet.text,
            label: 'Question',
            optional: questionSet?.optional || false,
          },
          {
            field: QuestionSetTypes.EXISTING_PPI + '-' + fieldSetId,
            label: 'Associated Profile Property (PPI)',
            sortOrder: 11,
            type: 'select',
            value: {
              label: convertHandleToTitle(ppiHandle),
              value: ppiHandle || '',
            },
            isCreatable: false,
            options: getFilteredPPIs(ppisList),
          },
          {
            field: QuestionSetTypes.ANSWER_TYPE + '-' + fieldSetId,
            label: 'Question Type',
            sortOrder: 12,
            type: 'radioSet',
            value: getQuestionType(questionSet),
            options: radioSetOptions,
          },
          {
            field: QuestionSetTypes.ANSWER_OPTIONS + '-' + fieldSetId,
            label: 'Options',
            sortOrder: 13,
            type: 'tagsInput',
            value:
              assetType === SCREENER_FORM || assetType === SURVEY_FORM
                ? questionSet.choices?.map((choice) => ({
                  ...choice,
                  choiceType: choice?.choiceType || ChoiceTypes.FIXED,
                  value:
                      choice?.choiceType === ChoiceTypes.EDITABLE
                        ? ''
                        : choice.value,
                }))
                : questionSet.choices?.map((choice) => choice.value),
          },
        ],
      });
    })
  );

  /* Adding the lastNonConditionalFieldSetId to the conditional questionSet for future access */
  // Creating this map for efficient look ups to avoid nested loops
  const ppiHandleMap = new Map();
  questionSets.forEach((questionSet) => {
    // when conditional question is encountered, it means that nonConditional questionSet is already processed
    if (
      questionSet.conditionalPPI &&
      ppiHandleMap.has(questionSet.conditionalPPI)
    ) {
      questionSet.lastNonConditionalFieldSetId = ppiHandleMap.get(
        questionSet.conditionalPPI
      );
    }
    // Adding the ppiHandle(s) only for non-conditional questionSets
    if (!questionSet.conditionalPPI) {
      ppiHandleMap.set(questionSet.ppiHandle, questionSet.fieldSetId);
    }
  });

  const convertedData = {
    ...sortedData,
    questionSet: questionSets,
  };

  return convertedData;
}

export function getRandomInt() {
  return Math.floor(Math.random() * 9999999);
}

export function constructLayoutHandle(shortHandle, layoutType) {
  return `${ shortHandle }-${ layoutType }`;
}

export const openPreviewWindow = (
  previewInNewWindow,
  entityType,
  previewData
) => {
  if (previewInNewWindow === 'preview-in-new-window') {
    const previewURL = `../${ ViewMode.PREVIEW }/${ entityType }`;
    const newWindow = window.open(
      `${ previewURL }`,
      '_blank',
      'height=1450,width=1450'
    );
    newWindow.previewData = previewData;
    newWindow.layoutType = entityType;
  }
};
