import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SCREENER_FORM } from 'lib/layoutUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import {
  QuestionSetTypes,
  getFieldValuesByQuestionSetAndNames,
} from '../../RepeatableFieldsetUtils';
import { RadioSet } from 'components/fields';
import { getFieldLabel, ACTIVITY_GEAR_PPIS } from '../../lib';
import { Field } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import { selectOptionPropType } from 'lib/propTypes';
import { debounce } from 'lodash';
import { ChoiceTypes } from 'lib/enums';

export const InquiryRadioField = ({
  disabled,
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  hasErrors,
  errorMessage,
  placeholder,
  onChange,
  layoutType,
  fieldOptions,
  fieldSet,
  existingPPI,
}) => {
  const filteredOptions =
    layoutType === SCREENER_FORM
      ? fieldOptions?.filter(({ value }) => !isTextOrHiddenType(value))
      : fieldOptions;

  const { answerOptions, conditionalToggle } =
    getFieldValuesByQuestionSetAndNames(fieldSet?.value, [
      QuestionSetTypes.ANSWER_OPTIONS,
      QuestionSetTypes.CONDITIONAL_TOGGLE,
    ]);

  const hasValidOptions = answerOptions?.some(
    (option) =>
      typeof option === 'object' &&
      option.choiceType === ChoiceTypes.FIXED &&
      option?.value
  );

  const [radioFieldVal, setRadioFieldVal] = useState(fieldValue);

  const debounceOnChange = useMemo(
    () =>
      debounce(
        (value) => onChange({ target: { name: fieldName, value } }),
        200
      ),
    [onChange, fieldName]
  );

  const handleRadioFieldChange = useCallback((event) => {
    setRadioFieldVal(event.target.value);
    debounceOnChange(event.target.value);
  }, []);

  return (
    existingPPI !== ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL && (
      <FieldWrapper disabled={ disabled }>
        <Field
          key={ fieldId }
          name={ fieldName }
          placeholder={ placeholder || fieldName }
        >
          { ({ field }) => {
            return (
              <RadioSet
                hasErrors={ !!hasErrors }
                errorMessage={ errorMessage }
                disabled={ disabled }
                name={ fieldName }
                id={ fieldName }
                label={
                  <label>
                    { getFieldLabel(fieldLabel || field.name) }
                    <span
                      className={ `asterisk ${ hasErrors ? 'hasErrors' : '' }` }
                    >
                      *
                    </span>
                  </label>
                }
                selectedValue={ radioFieldVal }
                options={ filteredOptions }
                onChange={ handleRadioFieldChange }
                answerOptionsExists={ hasValidOptions }
                disableHiddenOption={ conditionalToggle }
              />
            );
          } }
        </Field>
      </FieldWrapper>
    )
  );
};

InquiryRadioField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  existingPPI: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldOptions: PropTypes.arrayOf(selectOptionPropType),
  fieldSet: PropTypes.shape({
    value: PropTypes.instanceOf(Map),
  }),
  fieldValue: PropTypes.string,
  hasErrors: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
