import { CONSENT_FORM } from 'lib/layoutUtils';

export const invalidDate = 'Invalid Date';
export const notAvailable = 'Not Available';

export const message =
  'Compilation of published card is in progress. \n Check back to this page in a few minutes.';

export const headers = [
  {
    field: 'version',
    headerName: 'Version',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'versionNumber',
    headerName: 'Version Number',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    headerName: '',
    flex: 1,
    minWidth: 100,
  },
];

export const filterFields = (id, type) =>
  id === 'versionNumber' && type !== CONSENT_FORM;

export const confirmModalDetails = {
  heading: ' Confirm Publish Consent',
  leftCta: 'Confirm Publish',
  rightCta: 'Cancel',
};

export const confirmPublishModalContent = (compiledConsentsLength) =>
  (compiledConsentsLength >= 1
    ? 'Publishing this consent document will trigger an email notification to all active users and invalidate current consent status for this initiative'
    : 'Publishing this consent document will trigger an email notification and require all current users to approve consent status for this initiative');

export const getReturnPath = (initiativeType, id, location) => {
  const initEditPage = `/initiative/${ initiativeType }/edit/${ id }`;
  const initAssetsPage = `/initiative/${ initiativeType }/${ id }/assets`;

  const getPath = (isDefaultMedia = false) =>
    (isDefaultMedia
      ? (location?.state?.prevUrl ?? '').endsWith('/assets')
        ? initAssetsPage
        : initEditPage
      : initAssetsPage);

  const assetPath = {
    create: getPath(),
    edit: getPath(),
  };

  return {
    screener: assetPath,
    consentDocument: assetPath,
    mobileOverviewCard: assetPath,
    mobileReturningCard: assetPath,
    mobileDetailScreen: assetPath,
    webOverviewCard: assetPath,
    webDetailPage: assetPath,
    defaultMedia: {
      create: getPath(true),
      edit: getPath(true),
    },
    survey: assetPath,
  };
};
