/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  QuestionSetTypes,
  getFieldValuesByQuestionSetAndNames,
  isCategoryField,
} from '../../RepeatableFieldsetUtils';
import { Field } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import { SelectField } from 'components/fields';
import styles from '../../index.module.scss';
import { debounce, isArray, isEmpty } from 'lodash';
import { TooltipContainer } from 'components/fields/SelectWithCriteria/styles';
import TooltipWrapper from 'components/TooltipWrapper';
import { Text } from '@nike/eds';
import CategoryContentToggle from '../CategoryContentToggle';
import { objectOrArray, selectOptionPropType } from 'lib/propTypes';
import { filterPPI, getFieldLabel } from '../../lib';
import { useParams } from 'react-router-dom';
import { ViewMode } from 'lib/enums';
import { ConditionalSelectSubtitle } from './ConditionalSelectSubtitle';

export const InquirySelectField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  placeholder,
  disabled,
  fieldSet: currentQuestionSet,
  fieldOptions,
  errorMessage,
  onChange,
  isCreatable,
  showCategoryContent,
  setShowCategoryContent,
  errors,
}) => {
  const [currentFieldName, fieldSetId] = fieldName?.split('-');
  const [selectionType, setSelectionType] = useState('any');
  const { viewMode } = useParams();

  const isConditionalSelect =
    currentFieldName === QuestionSetTypes.CONDITIONAL_OPTIONS;
  const isPPISelect = currentFieldName === QuestionSetTypes.EXISTING_PPI;
  const isCategorySelect =
    currentFieldName === QuestionSetTypes.QUESTION_CATEGORY;

  const isConditionalToggled = currentQuestionSet?.value.get(
    QuestionSetTypes.CONDITIONAL_TOGGLE
  )?.value;
  const shouldRenderConditionalSelect =
    isConditionalToggled && isConditionalSelect;
  const shouldRenderSelect =
    shouldRenderConditionalSelect || isPPISelect || isCategorySelect;

  let conditionalOptions = [];
  let conditionalValues = {};
  let conditionalSelectionType = 'any';
  const isSelectDisabled =
    (isPPISelect && currentQuestionSet?.isUsedInConditional) || disabled;

  let isLastNonConditionalIdxChoiceQType = false;
  if (shouldRenderConditionalSelect) {
    // lastNonConditionalQuestionSet is available inside the currentQuestionSet if it is a conditional question
    const lastNonConditionalQuestionSet =
      currentQuestionSet?.lastNonConditionalQuestionSet?.value;
    const { answerOptions, answerType } = getFieldValuesByQuestionSetAndNames(
      lastNonConditionalQuestionSet,
      [QuestionSetTypes.ANSWER_OPTIONS, QuestionSetTypes.ANSWER_TYPE]
    );
    isLastNonConditionalIdxChoiceQType = answerType === 'choice';
    conditionalOptions = answerOptions?.map((value) => ({
      label: typeof value === 'string' ? value : value.label || value.name,
      value: typeof value === 'string' ? value : value.value,
    }));
    const conditionalField = currentQuestionSet?.value.get(
      QuestionSetTypes.CONDITIONAL_OPTIONS
    );
    conditionalValues = conditionalField?.value || {};
    conditionalSelectionType = isLastNonConditionalIdxChoiceQType
      ? 'any'
      : conditionalField?.selectionType;
  }

  useEffect(() => {
    setSelectionType(conditionalSelectionType);
  }, [conditionalSelectionType]);

  const options = isConditionalSelect
    ? conditionalOptions
    : isPPISelect
      ? fieldOptions?.filter(filterPPI)
      : fieldOptions;

  const isRepeatedCategory =
    isArray(fieldOptions) &&
    fieldOptions
      .slice(0, currentQuestionSet?.sortOrder)
      .some((opt) => opt.value === fieldValue?.value);

  useEffect(() => {
    if (isCategorySelect && !isEmpty(errors)) {
      const timer = setTimeout(() => {
        const hasCategoryError = Object.keys(errors).some((errorFieldName) =>
          isCategoryField(errorFieldName)
        );
        if (
          !isRepeatedCategory &&
          hasCategoryError &&
          viewMode !== ViewMode.CREATE
        ) {
          setShowCategoryContent(true);
        }
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [errors, isRepeatedCategory, currentFieldName, setShowCategoryContent]);

  const subtitle = shouldRenderConditionalSelect && (
    <ConditionalSelectSubtitle
      fieldName={ `${ QuestionSetTypes.SELECTION_TYPE }-${ fieldSetId }` }
      selectionType={ selectionType }
      onChange={ onChange }
      disabled={ isLastNonConditionalIdxChoiceQType }
    />
  );

  const [selectFieldVal, setSelectFieldVal] = useState(
    fieldValue || (isConditionalSelect ? conditionalValues : {})
  );

  useEffect(() => {
    if (setSelectFieldVal?.value !== fieldValue?.value) {
      setSelectFieldVal(fieldValue);
    }
  }, [fieldValue]);

  const debounceOnChange = useMemo(
    () => debounce((response, actionMeta) => onChange(response, actionMeta), 0),
    [onChange]
  );

  const handleSelectFieldChange = useCallback(
    (value, actionMeta) => {
      setSelectFieldVal(value);
      debounceOnChange(value, actionMeta);
    },
    [debounceOnChange]
  );

  const fieldError = errorMessage
    ? isEmpty(selectFieldVal)
      ? errorMessage
      : ''
    : '';

  return (
    <FieldWrapper disabled={ disabled }>
      <Field
        key={ fieldId }
        name={ fieldName }
        placeholder={ placeholder || fieldName }
      >
        { ({ field }) => {
          return (
            shouldRenderSelect && (
              <TooltipContainer>
                <TooltipWrapper
                  active={ isSelectDisabled }
                  message={
                    <Text
                      font="subtitle-2"
                      className="eds-spacing--mb-4"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {
                        '⚠️ PPI select is restricted as options are used in a conditional question.'
                      }
                    </Text>
                  }
                  children={
                    <div className={ styles.selectFieldWrapper }>
                      <SelectField
                        hasErrors={ !!fieldError }
                        errorMessage={ fieldError }
                        disabled={ isSelectDisabled }
                        name={ fieldName }
                        id={ fieldName }
                        multiple={ !!isConditionalSelect }
                        label={ getFieldLabel(fieldLabel || field.name) }
                        value={ selectFieldVal }
                        options={ options }
                        isCreatable={ isCreatable }
                        onChange={ handleSelectFieldChange }
                        subtitle={ subtitle ?? '' }
                        placeholder={
                          isConditionalSelect ? 'Select answers' : ''
                        }
                        required={ true }
                      />
                      { isCategorySelect && (
                        <CategoryContentToggle
                          showCategoryContent={ showCategoryContent }
                          setShowCategoryContent={ setShowCategoryContent }
                          isRepeatedCategory={ isRepeatedCategory }
                          showToggle={ !!(fieldValue || field.value) }
                        />
                      ) }
                    </div>
                  }
                />
              </TooltipContainer>
            )
          );
        } }
      </Field>
    </FieldWrapper>
  );
};

InquirySelectField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldOptions: PropTypes.arrayOf(selectOptionPropType),
  fieldSet: PropTypes.shape({
    isUsedInConditional: PropTypes.bool,
    sortOrder: PropTypes.number,
    lastNonConditionalQuestionSet: PropTypes.shape({
      value: PropTypes.instanceOf(Map),
    }),
    value: PropTypes.instanceOf(Map),
  }),
  fieldValue: PropTypes.oneOfType([
    selectOptionPropType,
    PropTypes.arrayOf(selectOptionPropType),
    PropTypes.shape({}),
  ]),
  hasErrors: PropTypes.bool,
  isCreatable: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setShowCategoryContent: PropTypes.func,
  showCategoryContent: PropTypes.bool,
};
