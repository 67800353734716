/* eslint-disable max-statements */
/* eslint-disable complexity */
// modules
import React, { useState, memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabGroup, Text } from '@nike/eds';
import { applyTo, pipe, isEmpty } from 'ramda';
import { withFormik } from 'formik';
import { Grid } from '@material-ui/core';
import { SelectField, TextField, TagsInput } from 'components/fields';
import Button from 'components/Button';
import { propTypes, defaultProps } from 'lib/react';
import {
  INITIAL_VALUES,
  selectInitAudienceOptions,
  selectOptions,
  validationSchema,
} from './lib';
import styles from './index.module.scss';
import { EntityType, ViewMode } from 'lib/enums';
import PpiDefinition from './ppiDefinition';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import { audiencePrefix, trimAndReplace } from '../utils';
import { isNil } from 'lodash';
import { AudienceTypesEnum, getRequiredFields } from 'utils';
import { CreateRule } from '@nike/nike-design-system-icons';
import useUserPermissions from 'lib/hooks/useUserPermissions';

export default applyTo(
  ({
    disabled,
    onCancel: handleCancel,
    handleSubmit,
    errors,
    dirty,
    viewMode,
    submitText,
    values,
    setFieldValue,
    onTagAdd,
    showAddMembers,
    touched,
    onSubmit,
    initialValues,
    audienceType: tabAudienceName,
  }) => {
    const { ppiDefinition, audienceType, upmids, athleteids, status } = values;
    const createPage = viewMode === ViewMode.CREATE;
    const [addIdsPreference, setAddIdsPreference] = useState(
      INITIAL_VALUES.addIdsPreference
    );
    const [isAddByUpmIdSelected, setIsAddByUpmIdSelected] = useState(
      addIdsPreference === 'upmid'
    );
    const [shouldAddAudienceMembers, setShouldAddAudienceMembers] =
      useState(false);

    const { isCreateAllowed, isEditAllowed } = useUserPermissions(
      values?.handle,
      EntityType.AUDIENCE
    );

    // When 'audienceType' is not 'static'; 'upmIds' & 'athleteids' field exists only in case of Static type selection
    // Removing the 'upmIds' & 'athleteIds' field from errors if exists
    useEffect(() => {
      if (audienceType !== 'Static') {
        if ('upmids' in errors) {
          delete errors.upmids;
        }
        if ('athleteids' in errors) {
          delete errors.athleteids;
        }
      } else if (audienceType === 'Static' && !createPage && !showAddMembers) {
        delete errors.upmids;
        delete errors.athleteids;
      }
    }, [errors, audienceType, createPage, showAddMembers]);

    useEffect(() => {
      if (audienceType === 'Static') {
        setFieldValue('ppiDefinition', undefined);
      }
    }, [audienceType]);

    useEffect(() => {
      setFieldValue(
        'audienceType',
        tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
          ? AudienceTypesEnum.STAT_AUDIENCE
          : !tabAudienceName ||
            tabAudienceName === AudienceTypesEnum.SHARED_AUDIENCE
            ? audienceType
            : tabAudienceName
      );
    }, [tabAudienceName, audienceType]);

    useEffect(() => {
      const val = trimAndReplace(values?.name);
      if (val && createPage) setFieldValue('handle', val);
      if (!isNil(val) && createPage) setFieldValue('handle', val);
    }, [values.name]);

    const handleChange = useCallback((ev) => {
      const val = ev.target?.value;
      return setFieldValue('handle', trimAndReplace(val));
    }, []);

    const onChangeAddIdsPreference = useCallback(
      (event) => setAddIdsPreference(event.target.id),
      []
    );

    useEffect(() => {
      setFieldValue('addIdsPreference', addIdsPreference);
      setIsAddByUpmIdSelected(addIdsPreference === 'upmid');
    }, [addIdsPreference]);

    useEffect(() => {
      //resetting addIdsPreference field after Add Members is hidden
      if (!showAddMembers) {
        setFieldValue('addIdsPreference', INITIAL_VALUES.addIdsPreference);
        setAddIdsPreference(INITIAL_VALUES.addIdsPreference);
      }
      setFieldValue('showAddMembers', showAddMembers);
    }, [showAddMembers]);

    useEffect(() => {
      //while creating audience if the user switches from Static to Dynamic after inputting the users
      //fieldValue still exists in formik so setting that field to empty
      if (audienceType === 'Dynamic') {
        setFieldValue(
          addIdsPreference === 'upmid' ? 'upmids' : 'athleteids',
          []
        );
        setFieldValue('shouldAddAudienceMembers', false);
        setShouldAddAudienceMembers(false);
        setIsAddByUpmIdSelected(addIdsPreference === 'upmid');
        if (createPage) {
          setFieldValue('ppiDefinition', []);
        }
      }
    }, [createPage, audienceType]);

    const setAudienceTypeSelectFieldDisabled = useCallback(
      () =>
        disabled ||
        //should be disabled in edit/view mode as it is already
        !createPage ||
        //should not be able to change the audienceType while creating audience from initiative's audience menu
        (createPage && tabAudienceName),
      [disabled, viewMode, tabAudienceName]
    );

    const audienceTypeOptions = useMemo(
      () =>
        (tabAudienceName === AudienceTypesEnum.INIT_AUDIENCE
          ? selectInitAudienceOptions
          : selectOptions),
      [tabAudienceName]
    );

    const setSubmitBtnDisabled = useCallback(
      () =>
        //since we show Edit btn enabling that on View mode
        (viewMode === ViewMode.READ
          ? !(isEditAllowed && isCreateAllowed)
          : !isEmpty(errors) || !dirty || isEmpty(touched)),
      [
        shouldAddAudienceMembers,
        audienceType,
        values,
        viewMode,
        errors,
        dirty,
        touched,
      ]
    );

    const handleAddToAudience = useCallback(() => {
      setFieldValue('shouldAddAudienceMembers', true);
      setShouldAddAudienceMembers(true);
    });

    const [showAddMembersBtn, showAddMembersSection] = useMemo(() => {
      const btnCondition =
        audienceType === 'Static' &&
        isCreateAllowed &&
        createPage &&
        !shouldAddAudienceMembers;
      const sectionCondition =
        audienceType === 'Static' &&
        isCreateAllowed &&
        (createPage ? shouldAddAudienceMembers : showAddMembers);
      return [btnCondition, sectionCondition];
    }, [audienceType, createPage, shouldAddAudienceMembers, showAddMembers]);

    const requiredFields = getRequiredFields(validationSchema);

    return (
      <form onSubmit={ handleSubmit }>
        { disabled && audienceType !== 'Static' && (
          <Text font="title-6" color="grey-1" style={{ paddingBottom: '24px' }}>
            Note: Audience is in use and therefore cannot be modified
          </Text>
        ) }
        <Grid container={ true } className={ styles.gridRow } spacing={ 2 }>
          <Grid item={ true } className={ styles.gridRow }>
            <Text font="title-4" className={ styles.title }>
              Audience Properties
            </Text>
          </Grid>
          <Grid item={ true } className={ styles.gridColumn } xs={ 12 } sm={ 6 }>
            <TextField
              name="name"
              label="Name"
              disabled={ disabled || !createPage }
              required={ requiredFields.name }
            />
          </Grid>
          <Grid item={ true } xs={ 12 } sm={ 6 }>
            <MaskedTextField
              name="handle"
              label="Handle"
              onChange={ handleChange }
              handleType={ audiencePrefix }
              createPage={ createPage }
              value={
                !createPage
                  ? values?.handle
                  : values?.handle || touched?.handle
                    ? values?.handle
                    : trimAndReplace(values?.name)
              }
              disabled={ (!createPage && true) || disabled }
              className={ !createPage ? styles.greyOut : '' }
              required={ requiredFields.handle }
            />
          </Grid>
        </Grid>
        <Grid container={ true } className={ styles.gridRow } spacing={ 2 }>
          <Grid item={ true } className={ styles.gridRow }>
            <TextField
              name="description"
              label="Description"
              disabled={ disabled || viewMode === ViewMode.READ }
              required={ requiredFields.description }
            />
            <TextField
              name="status"
              label="Status"
              disabled={ true }
              value={ createPage ? initialValues?.status : status }
              required={ requiredFields.status }
            />
            <SelectField
              placeholder="Select one"
              label="Audience Type"
              name="audienceType"
              disabled={ setAudienceTypeSelectFieldDisabled() }
              options={ audienceTypeOptions }
              required={ requiredFields.audienceType }
            />
          </Grid>
        </Grid>
        { showAddMembersBtn && (
          <Button
            className={ styles.addIdsButton }
            size="medium"
            disabled={ disabled }
            onClick={ handleAddToAudience }
            variant="secondary"
            afterSlot={ <CreateRule /> }
          >
            Add members to Audience
          </Button>
        ) }
        { showAddMembersSection && (
          <>
            <Grid container={ true } className={ styles.gridRow } spacing={ 2 }>
              <Grid item={ true } className={ styles.gridRow }>
                <Text font="title-4" className={ styles.title }>
                  Add Members
                </Text>
              </Grid>
              <div className={ styles.parentTabGroupContainer }>
                <TabGroup
                  name="audience-add-preference-tab-btn-group"
                  activeId={ values?.addIdsPreference || addIdsPreference }
                  defaultId={ values?.addIdsPreference || addIdsPreference }
                  onChange={ onChangeAddIdsPreference }
                  className={ styles.addPrefTabGroup }
                >
                  <Tab
                    id="upmid"
                    className={ styles.addPrefTab }
                    disabled={ athleteids?.length > 0 }
                  >
                    Add by UPMID
                  </Tab>
                  <Tab
                    id="athleteId"
                    className={ styles.addPrefTab }
                    disabled={ upmids?.length > 0 }
                  >
                    Add by Athlete Id
                  </Tab>
                </TabGroup>
              </div>
              <Grid item={ true } className={ styles.gridRow }>
                <TagsInput
                  fullWidth={ true }
                  variant="outlined"
                  id={ isAddByUpmIdSelected ? 'upmids' : 'athleteids' }
                  name={ isAddByUpmIdSelected ? 'upmids' : 'athleteids' }
                  tags={ isAddByUpmIdSelected ? upmids : athleteids }
                  disabled={ disabled || viewMode === ViewMode.READ }
                  title="User Ids"
                  setFieldValue={ setFieldValue }
                  onTagAdd={ onTagAdd }
                  helperText={ `Type or paste ${
                    isAddByUpmIdSelected ? 'upmId(s)' : 'athleteId(s)'
                  } separated by a space, comma or line break` }
                />
              </Grid>
            </Grid>
          </>
        ) }

        <PpiDefinition
          addButtonLabel="Add Property"
          title="Audience Profile Properties:"
          disabled={ disabled || viewMode === ViewMode.READ }
          ppiDefinition={ ppiDefinition }
          render={ audienceType === 'Dynamic' }
          propertyLabel="Property:"
          operatorLabel="Operator:"
          valueLabel="Value:"
        />
        <div className={ styles.btns }>
          { handleCancel && (
            <Button variant="secondary" onClick={ handleCancel } type="button">
              { viewMode === ViewMode.READ ? 'Back' : 'Cancel' }
            </Button>
          ) }
          <Button
            variant="primary"
            disabled={ setSubmitBtnDisabled() }
            type={ viewMode === ViewMode.READ ? 'button' : 'submit' }
            onClick={ viewMode === ViewMode.READ ? onSubmit : undefined }
          >
            { submitText || 'Submit' }
          </Button>
        </div>
      </form>
    );
  },
  pipe(
    withFormik({
      mapPropsToValues: ({ initialValues }) => {
        if (!isEmpty(initialValues)) {
          const { ppiDefinition, ...rest } = initialValues;
          const adaptedPpiDefinition = ppiDefinition?.map((definition) => {
            if (definition.handle === 'ppi-located-near-portland-metro') {
              return {
                ...definition,
                value: definition.value === true ? 'true' : 'false',
              };
            }
            return definition;
          });
          return {
            ...rest,
            ppiDefinition: adaptedPpiDefinition,
            handle: initialValues.audienceHandle || initialValues.handle,
          };
        }
        return INITIAL_VALUES;
      },
      validationSchema,
      // do the right handle submit, formik provides one
      handleSubmit: (
        values,
        {
          setSubmitting,
          props: { onSubmit, viewMode, submitText },
          setFieldValue,
          resetForm,
        }
      ) => {
        const { ppiDefinition, ...rest } = values;
        const adaptedPpiDefinition = ppiDefinition?.map((definition) => {
          if (definition.handle === 'ppi-located-near-portland-metro') {
            return {
              ...definition,
              value: definition.value === 'true',
            };
          }
          return definition;
        });

        const request = { ...rest, ppiDefinition: adaptedPpiDefinition };
        const { handle } = request;

        const digitalHandle =
          (viewMode === ViewMode.CREATE && `${ audiencePrefix }-${ handle }`) ||
          handle;

        const modifiedRequest = {
          ...request,
          handle: digitalHandle,
          setFieldValue,
        };

        if (submitText === 'Edit') {
          onSubmit();
        }
        onSubmit(modifiedRequest);
        setSubmitting(false);
        resetForm({ values });
      },
      displayName: 'AudienceForm',
    }),
    propTypes({
      handleCancel: PropTypes.func,
      handleSubmit: PropTypes.func,
      hasUsers: PropTypes.bool,
      initialValues: PropTypes.shape({}),
      onSubmit: PropTypes.func,
      disabled: PropTypes.bool,
      viewMode: PropTypes.string,
      submitText: PropTypes.string,
    }),
    defaultProps({
      handleCancel: () => {},
      initialValues: {},
      onSubmit: () => {},
      disabled: false,
      submitText: 'Submit',
    }),
    memo
  )
);
