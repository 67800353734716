import { map, pick, uniqBy } from 'ramda';

export const getRows = (jobs = []) => {
  return uniqBy(
    (job) => job.jobId,
    map(pick(['jobId', 'eventType', 'createTimestamp', 'status']), jobs)
  );
};

export const devUrl = '/dev/jobs/';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

export const columns = [
  { field: 'jobId', headerName: 'Job Id', flex: 1, minWidth: 200 },
  {
    field: 'createTimestamp',
    headerName: 'Date',
    flex: 1,
    type: 'dateTime',
    minWidth: 200,
  },
  {
    field: 'eventType',
    headerName: 'Event Type',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 200,
  },
];
