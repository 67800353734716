/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { AssetList } from './views';
import AssetWrapper from './AssetWrapper';
import withRolePermissions from 'lib/hooks/withRolePermissions';

const ManageAssets = () => {
  const { path } = useRouteMatch();
  const ProtectedAssetWrapper = useMemo(
    () => withRolePermissions(AssetWrapper),
    []
  );

  return (
    <Switch>
      <Route path={ path } exact={ true } component={ AssetList } />
      <Route
        path={ '/initiative/:initiativeType/:id/assets/:viewMode/:type' }
        exact={ true }
        component={ ProtectedAssetWrapper }
      />
    </Switch>
  );
};

export default ManageAssets;
