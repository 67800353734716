/*eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import { InitiativeParticipationStatuses, ViewMode } from 'lib/enums';
import PropTypes from 'prop-types';
import { concat, omit, propOr } from 'ramda';
import { AssetForm } from 'components/forms';
import { useCompileLayoutMutation } from 'features/adminApi/endpoints/cms';
import {
  STATE_BASED_CONTENT,
  DEFAULT_MEDIA,
  updateLayout,
  generateDefaultCards,
  constructLayoutHandle,
  retrieveShortHandle,
  CARD_CONSTANTS,
  INQUIRIES,
  openPreviewWindow,
} from 'lib/layoutUtils';
import { isEmpty, isEqual, kebabCase, startCase } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Tab, TabGroup, Text } from '@nike/eds';
import { lookupTabState, renderCtasBasedOnParticipation } from '../utils';
import PreviewInANewWinBtn from './PreviewNewWinBtn';
import { saveInitNavData } from 'utils';
import { initialValuesTypes } from 'lib/propTypes';
import SurveyErrorModal from './SurveyErrorModal';
import useHandleContentUpdate from '../hooks/useHandleContentUpdate';

// eslint-disable-next-line complexity
const AddEditForm = ({
  activeId,
  initialData,
  assetType,
  layoutType,
  preview,
  setPreview,
  formFields,
  handleTabChange,
  data,
  usePatch,
  useCreate,
  patchResult,
  submitText,
  postResult,
  updateFields,
  layoutId,
  initiativeHandle,
  isApproved,
  handleIsApproved,
  viewMode: layoutOption,
  showWebPreview = false,
}) => {
  const history = useHistory();
  const location = useLocation();
  const draftExist = location?.state?.draftExist;
  const [currentData, setCurrentData] = useState({});
  const [useCompileLayout] = useCompileLayoutMutation();
  const [formFieldsWithState, setFormFieldsWithState] = useState([]);
  const [formState, setFormState] = useState(initialData);
  const [activeLayoutId, setActiveLayoutId] = useState(activeId);
  const { initiativeType, viewMode, id } = useParams();
  const shortHandle = retrieveShortHandle(id);
  const [isTabDisabled, setIsTabDisabled] = useState(false);

  useEffect(() => {
    if (preview) {
      setFormState((prevState) => ({ ...prevState, ...preview }));
    }
  }, [preview]);

  const handleChange = useCallback((event) => {
    handleTabChange(event);
  }, []);

  useEffect(() => {
    const assetData = !isEmpty(data)
      ? data
        ?.filter((card) => {
          return layoutType === 'defaultMedia'
            ? card?.contentType === 'default-media'
            : card?.id === layoutId;
        })
        .shift()
      : [];
    setCurrentData(assetData);
  }, [layoutId, data]);

  const siblingIds = history?.location?.state?.siblingIds ?? [];

  const tabSet = siblingIds?.sort(
    (tab1, tab2) =>
      InitiativeParticipationStatuses.indexOf(tab1?.tabName) -
      InitiativeParticipationStatuses.indexOf(tab2?.tabName)
  );

  const initiativeParticipationStatus = lookupTabState(activeLayoutId, tabSet);
  const isContentAssetType = CARD_CONSTANTS.includes(kebabCase(layoutType));
  const inquiryLayoutType = INQUIRIES.includes(kebabCase(assetType));

  useEffect(() => {
    if (isContentAssetType && initialData) setFormState(initialData);
  }, [initialData]);

  useEffect(() => {
    if (isContentAssetType) {
      setIsTabDisabled(isEqual(formState, initialData));
      setActiveLayoutId(activeId);
    }
  }, [activeId, formState, initialData]);

  const composeFormData = useCallback(
    async (values) => {
      const valuesToUpdate = {
        ...currentData,
        ...values,
        questionSet: values?.questionSet || formFields,
        attributes: {
          isAutomated: isApproved,
        },
        text: values?.text?.replace(/\n\\\n/g, '\n\n').replace(/\\/g, ''),
      };

      const fieldData = updateFields(
        id,
        valuesToUpdate,
        assetType,
        draftExist,
        inquiryLayoutType ? undefined : initiativeParticipationStatus,
        shortHandle,
        viewMode
      );

      return {
        ...fieldData,
        valuesToUpdate,
      };
    },
    [isApproved, currentData, formFields]
  );

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      const {
        call,
        layoutType: updatedLayout,
        values: contentValues,
        valuesToUpdate,
      } = await composeFormData(values);

      if (call === 'patch') {
        const modifyContentValues = { ...contentValues };
        await usePatch(modifyContentValues);
        if (contentValues.contentType === 'default-media') {
          saveInitNavData(contentValues, false);
        }
        setSubmitting(false);
      } else if (call === 'post') {
        await useCreate(contentValues);
        setSubmitting(false);
      }

      //For sibling content (initPartStatus)- if call=post + status=preview,
      //add previews for the siblings
      if (!isEmpty(tabSet) && call === 'post') {
        tabSet
          .filter((item) => item.layoutId !== valuesToUpdate.id)
          .map((layout) => {
            const areIdsDifferent =
              isNaN(layout.layoutId) || isNaN(valuesToUpdate.id)
                ? layout.layoutId !== valuesToUpdate.id // Comparing as strings if it's a uuid
                : Number(layout.layoutId) !== Number(valuesToUpdate.id); // Comparing as numbers if both are numeric
            if (areIdsDifferent) {
              updateLayout('post', layout.layoutId, useCreate, data);
            }
          });
      }

      if (updatedLayout === DEFAULT_MEDIA) {
        const layoutHandle = constructLayoutHandle(shortHandle, updatedLayout);
        await useCompileLayout(layoutHandle);
        const contentCards = generateDefaultCards(
          id,
          data,
          values,
          shortHandle
        );

        const contentCardsUpdated = [];

        contentCards?.map(async (content) => {
          const stateBased = STATE_BASED_CONTENT.includes(content.layoutType);
          if (stateBased) {
            const initStatus =
              content.transformedValues.initiativeParticipationStatus;
            const layoutType =
              initStatus &&
              concat(
                propOr('', 'layoutType', content),
                concat('-', initStatus.toString().toLowerCase())
              );

            contentCardsUpdated.push({
              call: content.call,
              layoutType,
              transformedValues: {
                ...omit(['contentType'], content.transformedValues),
                contentType: propOr('', 'layoutType', content),
                initiativeParticipationStatus: initStatus,
              },
            });
          } else {
            contentCardsUpdated.push(content);
          }
        });

        const promises = contentCardsUpdated?.map(async (content) => {
          if (content?.call) {
            const layoutHandle = constructLayoutHandle(
              shortHandle,
              content?.layoutType
            );
            if (content?.call === 'patch') {
              //Enable below if want to compile after a default media update
              const patchContentResult = await usePatch(
                content.transformedValues
              );
              await useCompileLayout(layoutHandle);
              return {
                content,
                contentType: content.layoutType,
                layoutId: patchContentResult?.data?.id,
              };
            } else if (content?.call === 'post') {
              const postData = { ...content?.transformedValues };
              const postContentResult = await useCreate(postData);
              //Enable below if want to compile after a default media update
              await useCompileLayout(layoutHandle);
              return {
                content,
                contentType: content.layoutType,
                layoutId: postContentResult?.data?.id,
              };
            }
          }
        });
        const results = await Promise.all(promises);
        return results.filter(Boolean);
      }
    },
    [
      isApproved,
      currentData,
      composeFormData,
      formFields,
      id,
      useCreate,
      usePatch,
    ]
  );

  const handleWebPreview = useCallback(
    async (previewInNewWindow, entityType, values) => {
      // We need to parse the form data to the original format for the web.
      const previewData = await composeFormData(values);
      openPreviewWindow(previewInNewWindow, entityType, previewData.values);
    },
    [formState]
  );

  const handleSave = useCallback(
    (previewInNewWindow, entityType) => {
      openPreviewWindow(previewInNewWindow, entityType, {
        ...formState,
        show: true,
      });
      setPreview({ ...formState, show: true });
    },
    [formState]
  );

  useHandleContentUpdate({
    patchResult,
    postResult,
    initiativeType,
    id,
    viewMode,
    layoutType,
    currentData,
    draftExist,
    layoutId,
  });

  const title = `${ startCase(`${ viewMode || ViewMode.EDIT } ${ assetType }`) } ${
    activeLayoutId ? `(${ initiativeParticipationStatus } initiative)` : ''
  }`;

  const addNew = initialData?.id ? false : viewMode === ViewMode.CREATE;
  const showForm =
    (viewMode || layoutOption) === ViewMode.EDIT || addNew || inquiryLayoutType;

  useEffect(() => {
    if (isContentAssetType) {
      const modifiedFormFields = renderCtasBasedOnParticipation({
        initiativeParticipationStatus,
        cardContent: formFields,
      });

      const updatedFields =
        Array.isArray(modifiedFormFields) &&
        modifiedFormFields.map((field) => {
          return {
            ...field,
            activeLayoutId,
            message:
              activeLayoutId &&
              `Displayed when initiative participation status is '${ initiativeParticipationStatus }'`,
          };
        });
      setFormFieldsWithState(updatedFields);
    }
  }, [formFields, initiativeParticipationStatus]);

  return (
    <div data-testid={ 'add-edit-form' }>
      <SurveyErrorModal postResult={ postResult } layoutType={ layoutType } />
      { showForm && (
        <Text
          font={ 'title-1' }
          as={ 'h1' }
          style={{ textTransform: 'capitalize' }}
        >
          { title }
        </Text>
      ) }
      { activeLayoutId && (
        <TabGroup
          style={ showForm ? { marginTop: 30 } : { marginBottom: 30 } }
          name="manage-assets-tabs"
          activeId={ activeLayoutId }
          onChange={ handleChange }
        >
          { tabSet &&
            tabSet.map((tab) => {
              return (
                <Tab
                  key={ tab.layoutId }
                  id={ propOr('', 'layoutId', tab).toString() }
                  disabled={
                    String(activeLayoutId) !== String(tab.layoutId) &&
                    !isTabDisabled
                  }
                >
                  { tab.tabName }
                </Tab>
              );
            }) }
        </TabGroup>
      ) }
      { showForm ? (
        <AssetForm
          initiativeHandle={ initiativeHandle }
          submitText={ submitText }
          layoutType={ layoutType }
          initialValues={ initialData }
          viewMode={ viewMode }
          fields={ isContentAssetType ? formFieldsWithState : formFields }
          setFormState={ setFormState }
          formState={ formState }
          handleSave={ handleSave }
          handleSubmit={ handleSubmit }
          draftExist={ draftExist }
          cardStatus={ currentData?.status }
          isApproved={ isApproved }
          currentData={ currentData }
          handleIsApproved={ handleIsApproved }
          handleWebPreview={ showWebPreview ? handleWebPreview : null }
        />
      ) // Render below button only on card layouts (except "default-media")
        : isContentAssetType ? (
          <PreviewInANewWinBtn
            handleSave={ handleSave }
            layoutType={ layoutType }
            variant={ 'secondary' }
          />
        ) : null }
    </div>
  );
};

AddEditForm.propTypes = {
  activeId: PropTypes.string,
  assetType: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  formFields: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.instanceOf(Map),
  ]),
  handleIsApproved: PropTypes.func,
  handleTabChange: PropTypes.func,
  initialData: PropTypes.oneOfType([
    initialValuesTypes.initialInquiryData,
    initialValuesTypes.initialConsentData,
    initialValuesTypes.initialContentData,
  ]),
  initiativeHandle: PropTypes.string,
  isApproved: PropTypes.bool,
  layoutId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  layoutType: PropTypes.string,
  patchResult: PropTypes.shape({
    isSuccess: PropTypes.bool,
    error: PropTypes.shape({
      data: PropTypes.shape({ errorMessage: PropTypes.string }),
    }),
  }),
  postResult: PropTypes.shape({
    isSuccess: PropTypes.bool,
    error: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.shape({ errorMessage: PropTypes.string }),
    }),
  }),
  preview: PropTypes.shape({}),
  setPreview: PropTypes.func,
  showWebPreview: PropTypes.bool,
  submitText: PropTypes.string,
  updateFields: PropTypes.func,
  useCreate: PropTypes.func,
  usePatch: PropTypes.func,
  viewMode: PropTypes.string,
};

export default AddEditForm;
