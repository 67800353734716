import React, { useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import { createAnswerOptions, minAnswerOptionsRequired } from './utils';
import { ToggleButton } from './Components/ToggleButton';
import { DeleteButton } from './Components/DeleteButton';
import { OptionInputField } from './Components/OptionInputField';
import { AddAnswerOption } from './Components/AddAnswerOption';
import { OptionDescriptionField } from './Components/OptionDescription';
import { Text } from '@nike/eds';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { objectOrArray } from 'lib/propTypes';
import { isEmpty } from 'lodash';
import { ChoiceTypes } from 'lib/enums';

export const AnswerOptions = (props) => {
  const [answerOptions, setAnswerOptions] = useState(undefined);
  const {
    onChange,
    isCorePPI,
    existingPPI,
    currentFieldInitialPPIValue,
    tags,
    isApproved,
    disabled,
    errors = {},
    name,
  } = props;
  const [{ ...field }] = useField(name);

  useEffect(() => {
    const options = createAnswerOptions(existingPPI, tags, isApproved);

    if (currentFieldInitialPPIValue !== existingPPI) {
      onChange({ target: { name: field.name } }, options);
    }
    setAnswerOptions(options);
  }, [existingPPI]);

  const minOptionsError = useMemo(() => {
    return (
      !isCorePPI && answerOptions !== undefined && answerOptions.length < 2
    );
  }, [isCorePPI, answerOptions]);

  const commonProps = {
    fieldName: field.name,
    disabled,
    onChange,
    setAnswerOptions,
  };

  const shouldDisableWriteInOption = useMemo(
    () =>
      answerOptions?.some(
        (option) => option?.choiceType === ChoiceTypes.EDITABLE
      ),
    [answerOptions]
  );

  return (
    <div className="eds-spacing--mt-24">
      { minOptionsError && (
        <Text className="eds-color--text-danger">
          { minAnswerOptionsRequired }
        </Text>
      ) }
      <div />
      { !isEmpty(answerOptions) &&
        answerOptions?.map(
          ({ id, label, value, description = '', choiceType, isReject }) => {
            const componentProps = {
              ...commonProps,
              id,
            };

            return (
              <div key={ id }>
                <div className={ styles.answerOptionsContainer }>
                  <div className={ styles.ansOptsFieldsContainer }>
                    { !isCorePPI && (
                      <OptionInputField
                        { ...componentProps }
                        value={ label }
                        answerOptionError={ errors[field.name] }
                        choiceType={ choiceType }
                      />
                    ) }
                    <OptionInputField
                      { ...componentProps }
                      value={ isCorePPI ? label : value }
                      isValueField={ true }
                      answerOptionError={ errors[field.name] }
                      disabled={ disabled || isCorePPI }
                      choiceType={ choiceType }
                    />
                    { !isCorePPI && (
                      <OptionDescriptionField
                        { ...componentProps }
                        value={ description }
                      />
                    ) }
                    <div className={ styles.answerButtonsWrapper }>
                      { isApproved && (
                        <ToggleButton { ...componentProps } isReject={ isReject } />
                      ) }
                      { !isCorePPI && <DeleteButton { ...componentProps } /> }
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        ) }
      <div className={ styles.addAnswerOptionsContainer }>
        { !isCorePPI && (
          <AddAnswerOption { ...commonProps } isApproved={ isApproved } />
        ) }
        { !isCorePPI && (
          <AddAnswerOption
            { ...commonProps }
            disabled={ shouldDisableWriteInOption }
            isWriteIn={ true }
          />
        ) }
      </div>
    </div>
  );
};

AnswerOptions.propTypes = {
  currentFieldInitialPPIValue: PropTypes.string,
  disabled: PropTypes.bool,
  errors: objectOrArray,
  existingPPI: PropTypes.string,
  fieldSetId: PropTypes.number,
  isApproved: PropTypes.bool,
  isCorePPI: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  tags: PropTypes.arrayOf(Object),
};
