/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldSetActions } from './FieldSetActions';
import {
  getConditionalSetFromFirst,
  getConditionalSetFromLast,
  getConditionalToggleValue,
  getHiddenSetFromLast,
  getHiddenSetFromStart,
  getLastElement,
  getPPIValue,
  isActivityGearModelQuestion,
} from '../../RepeatableFieldsetUtils';
import { ACTIVITY_GEAR_PPIS } from '../../lib';
import styles from './index.module.scss';
import useSyncAndValidateForm from 'lib/hooks/useSyncAndValidateForm';

export const ReorderingMenu = ({
  fieldSet,
  index,
  repeatableFieldSet,
  disabled,
  setRepeatableFieldSet,
  setFieldTouched,
  setHasUnsavedChanges,
}) => {
  const fieldKey = 'questionSet';
  const [reorderTriggered, setReorderTriggered] = useState(false);

  const handleMoveUpFieldSet = useCallback(
    (setId) => () => {
      setRepeatableFieldSet((prevState) => {
        if (!prevState.has(setId)) return prevState;

        const newFieldSetMap = new Map(prevState);
        const updatedFieldSet = Array.from(newFieldSetMap.values());
        const fieldIndexToMoveUp = updatedFieldSet.findIndex(
          ({ fieldSetId }) => fieldSetId === setId
        );
        if (fieldIndexToMoveUp === -1 || fieldIndexToMoveUp === 0) {
          return prevState;
        }

        const currentField = updatedFieldSet[fieldIndexToMoveUp];
        const prevIndex = fieldIndexToMoveUp - 1;
        const prevField = updatedFieldSet[prevIndex];
        const currentIdxPPIValue = getPPIValue(currentField);
        const prevIdxPPIValue = getPPIValue(prevField);
        const isCurrentFieldConditional =
          getConditionalToggleValue(currentField);
        const isPrevFieldConditional = getConditionalToggleValue(prevField);
        const isCurrentFieldUsedInConditional =
          currentField?.isUsedInConditional;

        // Scenario 1: If the current field has ppi-activity-gear-model PPI then it has associated hidden ppis
        if (isActivityGearModelQuestion(currentIdxPPIValue)) {
          const currentHiddenSet = getHiddenSetFromStart(
            fieldIndexToMoveUp,
            updatedFieldSet
          );
          const hiddenSet = currentHiddenSet.map((i) => updatedFieldSet[i]);

          if (!isPrevFieldConditional) {
            // Scenario 1.1: If prevField is a normal field moving the prevField to the end the hidden set
            const [prevNormalField] = updatedFieldSet.splice(prevIndex, 1);
            updatedFieldSet.splice(
              getLastElement(currentHiddenSet),
              0,
              prevNormalField
            );
          } else if (isPrevFieldConditional) {
            // Scenario 1.2: If prevField is part of a conditional set then retrieving the conditionalSet
            // and moving the hiddenSet before conditional set
            const prevConditionalSet = getConditionalSetFromLast(
              prevIndex,
              updatedFieldSet
            );
            // Reversing the hidden set to delete from the end
            currentHiddenSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(prevConditionalSet[0], 0, ...hiddenSet);
          }
        } else if (
          !isCurrentFieldUsedInConditional &&
          prevIdxPPIValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_TYPE
        ) {
          // Scenario 2: Current field is a normal field and prevField is part of the hidden set
          // Moving the current field above the hidden set
          const currentHiddenSet = getHiddenSetFromLast(
            prevIndex,
            updatedFieldSet
          );
          updatedFieldSet.splice(fieldIndexToMoveUp, 1);
          updatedFieldSet.splice(currentHiddenSet[0], 0, currentField);
        } else if (
          !isCurrentFieldUsedInConditional &&
          !isPrevFieldConditional
        ) {
          // Scenario 3: Both current and previous are normal fields, swapping them directly
          [updatedFieldSet[fieldIndexToMoveUp], updatedFieldSet[prevIndex]] = [
            updatedFieldSet[prevIndex],
            updatedFieldSet[fieldIndexToMoveUp],
          ];
        } else if (isCurrentFieldConditional && isPrevFieldConditional) {
          [updatedFieldSet[fieldIndexToMoveUp], updatedFieldSet[prevIndex]] = [
            updatedFieldSet[prevIndex],
            updatedFieldSet[fieldIndexToMoveUp],
          ];
        } else if (!isCurrentFieldUsedInConditional && isPrevFieldConditional) {
          // Scenario 4: Current field is a normal field and previous is a part of conditional set
          // Moving the current field above the conditional set
          const prevConditionalSet = getConditionalSetFromLast(
            prevIndex,
            updatedFieldSet
          );
          updatedFieldSet.splice(fieldIndexToMoveUp, 1);
          updatedFieldSet.splice(prevConditionalSet[0], 0, currentField);
        } else if (isCurrentFieldUsedInConditional) {
          // Scenario 5: Current field is used in conditional question
          const currentConditionalSet = getConditionalSetFromFirst(
            fieldIndexToMoveUp,
            updatedFieldSet
          );
          const currentSet = currentConditionalSet.map(
            (i) => updatedFieldSet[i]
          );

          if (prevIdxPPIValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_TYPE) {
            // Scenario 5.1: Previous field is part of a hidden Set
            const prevHiddenSet = getHiddenSetFromLast(
              prevIndex,
              updatedFieldSet
            );
            //Removing the conditional set and moving it above the hidden set
            currentConditionalSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(prevHiddenSet[0], 0, ...currentSet);
          } else if (isPrevFieldConditional) {
            // Scenario 5.2: Previous field is part of a conditional set
            const prevConditionalSet = getConditionalSetFromLast(
              prevIndex,
              updatedFieldSet
            );
            //Removing the current conditional set and moving it above the previous conditional set
            currentConditionalSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(prevConditionalSet[0], 0, ...currentSet);
          } else {
            // Scenario 5.3: Previous field is a normal field
            //Removing the current conditional set and moving it above the previous normal field
            currentConditionalSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(prevIndex, 0, ...currentSet);
          }
        }
        // Updating the sort order
        Object.keys(updatedFieldSet).forEach(
          (item, index) => (updatedFieldSet[item].sortOrder = index + 1)
        );
        const updatedFieldSetMap = new Map();
        updatedFieldSet.forEach((field) =>
          updatedFieldSetMap.set(field.fieldSetId, field)
        );
        return updatedFieldSetMap;
      });
      setFieldTouched(fieldKey, true);
      setReorderTriggered(true);
    },
    []
  );

  const handleMoveDownFieldSet = useCallback(
    (setId) => () => {
      setRepeatableFieldSet((prevState) => {
        if (!prevState.has(setId)) return prevState;

        const newFieldSetMap = new Map(prevState);
        const updatedFieldSet = Array.from(newFieldSetMap.values());

        const fieldIndexToMoveDown = updatedFieldSet.findIndex(
          ({ fieldSetId }) => fieldSetId === setId
        );
        if (
          fieldIndexToMoveDown === -1 ||
          fieldIndexToMoveDown === updatedFieldSet.length - 1
        ) {
          return prevState;
        }
        const currentField = updatedFieldSet[fieldIndexToMoveDown];
        const nextIndex = fieldIndexToMoveDown + 1;
        const nextField = updatedFieldSet[nextIndex];
        const currentIdxPPIValue = getPPIValue(currentField);
        const nextIdxPPIValue = getPPIValue(nextField);
        const isCurrentFieldConditional =
          getConditionalToggleValue(currentField);
        const isNextFieldConditional =
          nextField?.hasConditionalToggled ||
          getConditionalToggleValue(nextField?.value);
        const isCurrentFieldUsedInConditional =
          currentField?.isUsedInConditional;
        const isNextFieldUsedInConditional = nextField?.isUsedInConditional;

        // Scenario 1: If the current field has ppi-activity-gear-model PPI then it has associated hidden ppis
        if (currentIdxPPIValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL) {
          const currentHiddenSet = getHiddenSetFromStart(
            fieldIndexToMoveDown,
            updatedFieldSet
          );
          // Determining the next index after the hidden set
          const nextIndexAfterHiddenSet = getLastElement(currentHiddenSet) + 1;
          const nextField = updatedFieldSet[nextIndexAfterHiddenSet];
          const isNextFieldUsedInConditional = nextField?.isUsedInConditional;

          // Scenario 1.1: Next field is a normal field, moving the next field above the hidden set
          if (isNextFieldUsedInConditional === undefined) {
            updatedFieldSet.splice(nextIndexAfterHiddenSet, 1);
            updatedFieldSet.splice(currentHiddenSet[0], 0, nextField);
          } else if (isNextFieldUsedInConditional) {
            // Scenario 1.2: Next field is a part of conditionalSet
            //moving the next conditionalSet above the hidden set
            const nextConditionalSet = getConditionalSetFromFirst(
              nextIndexAfterHiddenSet,
              updatedFieldSet
            );
            const nextSet = nextConditionalSet.map((i) => updatedFieldSet[i]);
            nextConditionalSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(currentHiddenSet[0], 0, ...nextSet);
          }
        } else if (isCurrentFieldUsedInConditional === undefined) {
          // Scenario 2: Current field is a normal field
          if (nextIdxPPIValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL) {
            // Scenario 2.1: Next field has ppi-activity-gear-model ppi
            // Moving the current field below the hidden set
            const nextHiddenSet = getHiddenSetFromStart(
              nextIndex,
              updatedFieldSet
            );
            updatedFieldSet.splice(fieldIndexToMoveDown, 1);
            updatedFieldSet.splice(
              getLastElement(nextHiddenSet),
              0,
              currentField
            );
          } else if (isNextFieldUsedInConditional === undefined) {
            // Scenario 2.2: Both currentField and nextField are normal indexes, swapping them directly
            [
              updatedFieldSet[fieldIndexToMoveDown],
              updatedFieldSet[nextIndex],
            ] = [
              updatedFieldSet[nextIndex],
              updatedFieldSet[fieldIndexToMoveDown],
            ];
          } else if (isNextFieldUsedInConditional) {
            // Scenario 2.3: Next field is part of a conditional set
            // Moving the next conditional set above the current field
            const nextConditionalSet = getConditionalSetFromFirst(
              nextIndex,
              updatedFieldSet
            );
            const nextSet = nextConditionalSet.map((i) => updatedFieldSet[i]);
            nextConditionalSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(fieldIndexToMoveDown, 0, ...nextSet);
          }
        } else if (isCurrentFieldUsedInConditional) {
          // Scenario 3: Current field is used in a conditional question then it has a conditionalSet
          const currentConditionalSet = getConditionalSetFromFirst(
            fieldIndexToMoveDown,
            updatedFieldSet
          );
          const currentConditionalSetInitIdx = currentConditionalSet[0];

          const nextIndexAfterConditional =
            getLastElement(currentConditionalSet) + 1;
          const nextField = updatedFieldSet[nextIndexAfterConditional];
          const nextIdxPPIValue = getPPIValue(nextField);

          if (isActivityGearModelQuestion(nextIdxPPIValue)) {
            // Scenario 3.3: Next field is part of a hidden set
            // Moving the hidden set above the conditional set
            const nextHiddenSet = getHiddenSetFromStart(
              nextIndexAfterConditional,
              updatedFieldSet
            );
            const nextSet = nextHiddenSet.map((i) => updatedFieldSet[i]);
            nextHiddenSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));

            updatedFieldSet.splice(currentConditionalSetInitIdx, 0, ...nextSet);
          } else if (isNextFieldUsedInConditional === undefined) {
            // Scenario 3.3: Next field is a normal field
            // Moving the normal field above the conditional set
            updatedFieldSet.splice(nextIndexAfterConditional, 1);
            updatedFieldSet.splice(currentConditionalSetInitIdx, 0, nextField);
          } else if (isNextFieldUsedInConditional) {
            // Scenario 3.4: Next field is used in a conditional question then it has a conditionalSet
            // Moving the next conditional set above the current conditional set
            const nextConditionalSet = getConditionalSetFromFirst(
              nextIndexAfterConditional,
              updatedFieldSet
            );
            const nextSet = nextConditionalSet.map((i) => updatedFieldSet[i]);
            nextConditionalSet
              .reverse()
              .forEach((i) => updatedFieldSet.splice(i, 1));
            updatedFieldSet.splice(currentConditionalSetInitIdx, 0, ...nextSet);
          }
        } else if (isCurrentFieldConditional && isNextFieldConditional) {
          [updatedFieldSet[fieldIndexToMoveDown], updatedFieldSet[nextIndex]] =
            [updatedFieldSet[nextIndex], updatedFieldSet[fieldIndexToMoveDown]];
        }
        // Updating the sort order
        Object.keys(updatedFieldSet).forEach(
          (item, index) => (updatedFieldSet[item].sortOrder = index + 1)
        );
        const updatedFieldSetMap = new Map();
        updatedFieldSet.forEach((field) =>
          updatedFieldSetMap.set(field.fieldSetId, field)
        );
        return updatedFieldSetMap;
      });
      setFieldTouched(fieldKey, true);
      setReorderTriggered(true);
    },
    []
  );

  useSyncAndValidateForm(
    reorderTriggered,
    setReorderTriggered,
    repeatableFieldSet,
    setHasUnsavedChanges
  );

  return (
    <div className={ styles.menuEllipsis }>
      <FieldSetActions
        fieldSet={ fieldSet }
        index={ index }
        repeatableFieldSet={ repeatableFieldSet }
        handleMoveUpFieldSet={ handleMoveUpFieldSet }
        handleMoveDownFieldSet={ handleMoveDownFieldSet }
        disabled={ disabled }
      />
    </div>
  );
};

ReorderingMenu.propTypes = {
  disabled: PropTypes.bool,
  fieldSet: PropTypes.shape({
    fieldSetId: PropTypes.number,
    showReorderingMenu: PropTypes.bool,
  }),

  index: PropTypes.number,
  repeatableFieldSet: PropTypes.instanceOf(Map),
  setFieldTouched: PropTypes.func,
  setHasUnsavedChanges: PropTypes.func,
  setRepeatableFieldSet: PropTypes.func,
};
