import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Divider, Radio, RadioGroup, Toggle } from '@nike/eds';
import CustomFieldLabel from 'components/fields/CustomFieldLabel';
import DateField from 'components/fields/CustomFieldLabel/DateField';
import { debounce, isEmpty } from 'lodash';

// eslint-disable-next-line max-statements
const ScheduleSurvey = ({
  schedule,
  isSurveyScheduled,
  setIsSurveyScheduled,
  setHasUnsavedChanges,
  setFieldValue,
  setFieldTouched,
  scheduleRate,
  surveyStartDate,
  scheduleSurveyErrors,
  handleFieldChange,
}) => {
  const [scheduleRateVal, setScheduleRateVal] = useState(
    scheduleRate ?? 'Once'
  );

  const debouncedHandleScheduleRateChange = useMemo(
    () => debounce((event) => handleFieldChange(event), 300),
    [handleFieldChange]
  );

  const handleScheduleRateChange = useCallback(
    (event) => {
      setScheduleRateVal(event.target.value);
      debouncedHandleScheduleRateChange(event);
    },
    [debouncedHandleScheduleRateChange]
  );

  const debounceOnToggleScheduleSurvey = useMemo(
    () =>
      debounce((isSurveyScheduled) => {
        setFieldValue('isSurveyScheduled', isSurveyScheduled);
        setFieldTouched('isSurveyScheduled', true);
        setHasUnsavedChanges(true);
      }, 300),
    [setHasUnsavedChanges]
  );

  const onToggleScheduleSurvey = useCallback((event) => {
    setIsSurveyScheduled((prevState) => !prevState);
    debounceOnToggleScheduleSurvey(event.target.checked);
  }, []);

  useEffect(() => {
    if (isSurveyScheduled && !scheduleRate) {
      setFieldValue('scheduleRate', 'Once');
    }
  }, [isSurveyScheduled, scheduleRate]);

  useEffect(() => {
    if (scheduleRate !== 'Once' && !isEmpty(surveyStartDate)) {
      setFieldValue('surveyEndDate', surveyStartDate);
      setFieldTouched('surveyEndDate', true);
    }
  }, [scheduleRate, surveyStartDate]);

  useEffect(() => {
    setIsSurveyScheduled(!isEmpty(schedule));
  }, [schedule]);

  return (
    <Grid item={ true } xs={ 12 }>
      <Grid container={ true } spacing={ 2 }>
        { isSurveyScheduled && (
          <Grid item={ true } xs={ 12 }>
            <Divider />
          </Grid>
        ) }
        <Grid item={ true } xs={ 12 }>
          <Toggle
            id="scheduleSurvey"
            label={ <CustomFieldLabel label="Schedule survey" font="title-6" /> }
            onChange={ onToggleScheduleSurvey }
            checked={ isSurveyScheduled }
          />
        </Grid>
        { isSurveyScheduled && (
          <>
            <Grid item={ true } xs={ 12 }>
              <RadioGroup
                name="scheduleRate"
                id="scheduleRateRadioBtnGroup"
                orientation="horizontal"
                valueSelected={ scheduleRateVal }
                onChange={ handleScheduleRateChange }
              >
                <Radio
                  label={ <CustomFieldLabel label="Once" /> }
                  value="Once"
                  id="Once"
                />
                <Radio
                  label={ <CustomFieldLabel label="Daily" /> }
                  value="Daily"
                  id="Daily"
                />
                <Radio
                  label={ <CustomFieldLabel label="Weekly" /> }
                  value="Weekly"
                  id="Weekly"
                />
              </RadioGroup>
            </Grid>
            <Grid item={ true } xs={ 12 } container={ true } spacing={ 2 }>
              <Grid item={ true } xs={ scheduleRate !== 'Once' ? 6 : 12 }>
                <DateField
                  name="surveyStartDate"
                  label={
                    scheduleRate === 'Once'
                      ? 'Survey Send Date'
                      : 'Survey Start Date'
                  }
                  setHasUnsavedChanges={ setHasUnsavedChanges }
                  scheduleSurveyErrors={ scheduleSurveyErrors }
                />
              </Grid>
              { scheduleRate !== 'Once' && (
                <Grid item={ true } xs={ 6 }>
                  <DateField
                    name="surveyEndDate"
                    label="Survey End Date"
                    setHasUnsavedChanges={ setHasUnsavedChanges }
                    scheduleSurveyErrors={ scheduleSurveyErrors }
                  />
                </Grid>
              ) }
              <Grid item={ true } xs={ 12 }>
                <DateField
                  type="time"
                  name="surveyTime"
                  label="Survey Send Time"
                  message="Warning: Surveys are scheduled based on the east coast timezone"
                  setHasUnsavedChanges={ setHasUnsavedChanges }
                  scheduleSurveyErrors={ scheduleSurveyErrors }
                />
              </Grid>
            </Grid>
          </>
        ) }
      </Grid>
    </Grid>
  );
};

ScheduleSurvey.propTypes = {
  handleFieldChange: PropTypes.func,
  isSurveyScheduled: PropTypes.bool,
  schedule: PropTypes.shape({
    rate: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  scheduleRate: PropTypes.string,
  scheduleSurveyErrors: PropTypes.shape({}),
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  setHasUnsavedChanges: PropTypes.func,
  setIsSurveyScheduled: PropTypes.func,
  surveyStartDate: PropTypes.string,
};

export default ScheduleSurvey;
