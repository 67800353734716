import React, { useCallback, useState } from 'react';
import { Popover, Text } from '@nike/eds';
import styles from './index.module.scss';
import { unknownCustomVariableError } from 'components/forms/utils';

const WarningPopover = () => {
  const [popoverShown, setPopoverShown] = useState(false);
  const handlePopoverClick = useCallback(
    () => setPopoverShown((prev) => !prev),
    []
  );

  return (
    <div className={ styles.warningPopover }>
      <Popover
        onClickOutside={ handlePopoverClick }
        isOpen={ popoverShown }
        bodySlot={
          <Text className={ styles.popoverText } font="body-3">
            { unknownCustomVariableError }
          </Text>
        }
        headerSlot={ <Text font="title-5">Warning</Text> }
        placement="bottom"
      >
        <div className={ styles.warningIcon } onClick={ handlePopoverClick }>
          ⚠️
        </div>
      </Popover>
    </div>
  );
};

export default WarningPopover;
