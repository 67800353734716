/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { formTypes } from 'lib/propTypes';
import { Button } from '@nike/eds';
import { isEmpty } from 'lodash';
import { Plus } from '@nike/nike-design-system-icons';
import InquiryQuestion from './InquiryQuestion.js';
import { getFormFields, getPPIOptions } from 'components/forms/Assets/lib';
import {
  QuestionSetTypes,
  addDynamicQuestionLabels,
  addIsUsedInConditionalFlag,
  getCategoriesFromForm,
  getFieldsByQuestionSet,
  getLastNonConditionalFieldSetId,
  setConditionalToggledLabel,
  shouldShowDeleteLink,
} from './RepeatableFieldsetUtils';
import { INQUIRIES } from 'lib/layoutUtils';
import { ReorderingMenu } from './Components/ReorderingMenu';
import { EntityType } from 'lib/enums';
import { userInitiativePermissions } from 'components/RBAC';
import { usePPIlist } from 'lib/hooks/usePPIlist';

const RepeatableFieldSet = ({
  fields,
  setFieldValue,
  setFieldTouched,
  setFormState,
  formState,
  inquiryErrors: errors,
  disabled,
  layoutType,
  isApproved,
  newPPI = {},
  requiredFields,
  setHasUnsavedChanges,
  initiativeHandle,
}) => {
  const canCreatePPI = userInitiativePermissions(
    initiativeHandle,
    EntityType.PPI
  ).includes('CREATE');
  const fieldKey = 'questionSet';
  const isInquiry = INQUIRIES.includes(layoutType);
  const ppisList = usePPIlist();

  useEffect(() => {
    if (isInquiry && fields instanceof Map && fields.size > 0) {
      const updatedQuestionSetFields = addDynamicQuestionLabels(
        addIsUsedInConditionalFlag(setConditionalToggledLabel(fields))
      );
      setFormState(new Map(updatedQuestionSetFields));
    }
  }, [layoutType, fields]);

  const showAddLink = useMemo(() => {
    return isInquiry && !disabled && formState?.size >= 0;
  }, [disabled, formState, isInquiry]);

  const handleFormStateChange = useCallback(
    (response, option) => {
      //Select sends key=option.name
      const key = option?.name || response?.target?.name || response;
      const isOptionalQuestion = key.includes(
        QuestionSetTypes.OPTIONAL_QUESTION
      );

      //Text sends response.target.value
      //Select sends response
      const value = response?.label
        ? response
        : key?.includes(QuestionSetTypes.CONDITIONAL_OPTIONS)
          ? response?.map((opt) => ({
            label: opt?.name || opt?.label,
            value: opt?.value,
          }))
          : key?.includes(QuestionSetTypes.CONDITIONAL_TOGGLE) ||
          isOptionalQuestion
            ? response?.target?.checked
            : (response?.target?.type === 'checkbox'
              ? response?.target?.checked
              : response?.target?.value) || option;

      const [currentFieldName, fieldSetId] = key.split('-');
      console.time('setFormState');
      setFormState((prevState) => {
        const newState = new Map(prevState);
        const fieldSetIds = [...newState.keys()];
        const currentFieldSet = newState.get(+fieldSetId);
        const questionSet = newState.get(+fieldSetId).value;
        // Relevant field updates
        if (questionSet instanceof Map) {
          const questionField = questionSet.get(currentFieldName);
          if (currentFieldName === QuestionSetTypes.OPTIONAL_QUESTION) {
            questionSet.get(QuestionSetTypes.QUESTION_LABEL).optional = value;
          } else if (currentFieldName === QuestionSetTypes.SELECTION_TYPE) {
            questionSet.get(
              QuestionSetTypes.CONDITIONAL_OPTIONS
            ).selectionType = value;
          } else if (questionField) {
            if (currentFieldName === QuestionSetTypes.CONDITIONAL_OPTIONS) {
              questionField.value = value.length === 1 ? value[0] : value;
            } else questionField.value = value;
          }
        }
        // Updating the answerOptions and answerType fields based on the updated PPI.
        if (
          questionSet instanceof Map &&
          currentFieldName === QuestionSetTypes.EXISTING_PPI
        ) {
          const { value: ppiValue } = value || {};
          const {
            answerOptions: answerOptionsField,
            answerType: answerTypeField,
          } = getFieldsByQuestionSet(questionSet, [
            QuestionSetTypes.ANSWER_OPTIONS,
            QuestionSetTypes.ANSWER_TYPE,
          ]);
          const ppiOptions = getPPIOptions(ppiValue);
          answerOptionsField.value = ppiOptions ?? [];
          answerTypeField.value = ppiOptions ? 'choice' : answerTypeField.value;
        }
        // Updating relevant questionCategory options when CONDITIONAL_TOGGLE changes
        if (
          questionSet instanceof Map &&
          currentFieldName === QuestionSetTypes.CONDITIONAL_TOGGLE
        ) {
          const conditionalQuestionCategoryField = questionSet.get(
            QuestionSetTypes.QUESTION_CATEGORY
          );
          const currentIdx = fieldSetIds.indexOf(+fieldSetId);
          const lastNonConditionalFieldSetId = getLastNonConditionalFieldSetId(
            +fieldSetId,
            newState,
            fieldSetIds
          );
          const lastNonConditionalIdx = fieldSetIds.indexOf(
            lastNonConditionalFieldSetId
          );
          const lastNonConditionalQuestionSet = newState.get(
            lastNonConditionalFieldSetId
          );
          const validOptionsForConditional = new Set();

          if (value) {
            currentFieldSet.lastNonConditionalQuestionSet =
              lastNonConditionalQuestionSet;
            // When CONDITIONAL_TOGGLE is true collecting only valid options
            // for conditional questions's questionCategory options
            const hasValue = !isEmpty(
              conditionalQuestionCategoryField?.value?.value
            );
            const startIndex = hasValue ? currentIdx : currentIdx - 1;
            for (let i = startIndex; i >= lastNonConditionalIdx; i--) {
              const questionCategoryField = newState
                .get(fieldSetIds[i])
                .value.get(QuestionSetTypes.QUESTION_CATEGORY);

              if (questionCategoryField?.value) {
                validOptionsForConditional.add(
                  questionCategoryField.value.value
                );
              }
            }
          } else {
            delete currentFieldSet.lastNonConditionalQuestionSet;
            // When CONDITIONAL_TOGGLE is false collecting all options across
            // newState for conditional questions's questionCategory options
            for (const questionSet of newState.values()) {
              const questionCategoryField = questionSet.value.get(
                QuestionSetTypes.QUESTION_CATEGORY
              );
              if (questionCategoryField?.value) {
                if (!isEmpty(questionCategoryField.value.value)) {
                  validOptionsForConditional.add(
                    questionCategoryField.value.value
                  );
                }
              }
            }
          }
          // still holds a reference to the (questionSet.value)'s obj since it's a shallow copy
          conditionalQuestionCategoryField.options = [
            ...validOptionsForConditional,
          ].map((category) => ({ label: category, value: category }));
        }
        // Updating the categoryName field based on the value of questionCategory field
        if (
          questionSet instanceof Map &&
          currentFieldName === QuestionSetTypes.QUESTION_CATEGORY
        ) {
          const categoryNameField = questionSet.get(
            QuestionSetTypes.CATEGORY_NAME
          );
          if (isEmpty(categoryNameField.value)) {
            categoryNameField.value = value?.value;
            categoryNameField.inheritedValue = value?.value;
          }
        }
        const updatedState = addIsUsedInConditionalFlag(
          addDynamicQuestionLabels(newState)
        );
        if (updatedState) setFieldValue(fieldKey, updatedState);
        return updatedState;
      });
      console.timeEnd('setFormState');
      setFieldTouched(key, true);
      setHasUnsavedChanges(true);
    },
    [formState]
  );

  const handleAddFieldSet = useCallback(() => {
    setFormState((prevState) => {
      const newState = new Map(prevState);
      const content = getCategoriesFromForm(prevState);
      const updatedOptions = canCreatePPI
        ? [...(ppisList ?? []), { label: 'Add New PPI', value: 'New PPI' }]
        : ppisList;
      const addedFieldSet =
        (ppisList?.length > 0 &&
          getFormFields(content, layoutType, updatedOptions).elements[0]
            .value) ||
        new Map();
      for (const [fieldSetId, fieldSet] of addedFieldSet) {
        newState.set(fieldSetId, fieldSet);
      }
      const updatedState = addDynamicQuestionLabels(newState);
      if (updatedState) setFieldValue(fieldKey, updatedState);
      return updatedState;
    });
  }, [ppisList, canCreatePPI]);

  useEffect(() => {
    if (!isEmpty(newPPI)) {
      setFormState((prevState) => {
        if (
          !(prevState instanceof Map) ||
          prevState.size === 0 ||
          !prevState.has(newPPI.fieldSetId)
        ) {
          return prevState;
        }
        const fieldSet = prevState.get(newPPI.fieldSetId);
        if (!fieldSet) return prevState;
        const updatedFieldSet = { ...fieldSet };
        const ppiField = updatedFieldSet?.value.get(
          QuestionSetTypes.EXISTING_PPI
        );

        if (ppiField) {
          ppiField.options = [...ppiField.options.slice(0, -1), newPPI];
          ppiField.value = newPPI;
        }

        const updatedFormState = new Map(prevState);
        updatedFormState.set(newPPI.fieldSetId, updatedFieldSet);
        return updatedFormState;
      });
    }
  }, [newPPI]);

  return (
    <>
      { formState instanceof Map && formState.size > 0 && (
        <>
          <div>
            { [...formState].map(([fieldSetId, fieldSet], idx) => {
              const isCurrentIndexConditional = fieldSet?.value.get(
                QuestionSetTypes.CONDITIONAL_TOGGLE
              )?.value;
              return (
                !isEmpty(fieldSet) &&
                !fieldSet?.hideQuestion && (
                  <div key={ fieldSetId } data-testid={ `inquiryQuestion-${ idx }` }>
                    <div
                      className={
                        isCurrentIndexConditional
                          ? styles.conditionalLayout
                          : styles.layout
                      }
                    >
                      {
                        <ReorderingMenu
                          fieldSet={ fieldSet }
                          index={ idx }
                          repeatableFieldSet={ formState }
                          setRepeatableFieldSet={ setFormState }
                          disabled={ disabled }
                          setHasUnsavedChanges={ setHasUnsavedChanges }
                          setFieldValue={ setFieldValue }
                          setFieldTouched={ setFieldTouched }
                        />
                      }
                      <InquiryQuestion
                        questionIndex={ idx }
                        errors={ errors }
                        fieldSet={ fieldSet }
                        disabled={ disabled }
                        setFieldValue={ setFieldValue }
                        setFormState={ setFormState }
                        handleChange={ handleFormStateChange }
                        layoutType={ layoutType }
                        isApproved={ isApproved }
                        requiredFields={ requiredFields }
                        setFieldTouched={ setFieldTouched }
                        shouldShowDeleteLink={ shouldShowDeleteLink({
                          disabled,
                          formState,
                          fieldSet,
                          idx,
                        }) }
                      />
                    </div>
                  </div>
                )
              );
            }) }
          </div>

          { showAddLink && (
            <div className={ styles.addItemBtn }>
              <Button
                afterSlot={ <Plus /> }
                size="medium"
                variant="secondary"
                onClick={ handleAddFieldSet }
                data-testid="add-item-btn"
              >
                Add Item
              </Button>
            </div>
          ) }
        </>
      ) }
    </>
  );
};

RepeatableFieldSet.propTypes = {
  ...formTypes,
  disabled: PropTypes.bool,
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  newPPI: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    group: PropTypes.string,
  }),
  setHasUnsavedChanges: PropTypes.func,
};

export default RepeatableFieldSet;
