import React from 'react';
import PropTypes from 'prop-types';
import { AccordionStyled } from '@nike/nike-design-system-components';
import RepeatableFieldSet from '../RepeatableFieldSet';
import { formPropsTypes } from 'lib/propTypes';

const SurveyForm = ({ layoutType, newPPI, formProps }) => (
  <AccordionStyled id="surveyQuestionForm" open={ true } size="large">
    <RepeatableFieldSet
      layoutType={ layoutType }
      newPPI={ newPPI }
      { ...formProps }
    />
  </AccordionStyled>
);

SurveyForm.propTypes = {
  formProps: formPropsTypes,
  layoutType: PropTypes.string,
  newPPI: PropTypes.shape({}),
};

export default SurveyForm;
