import React, { useMemo, useState } from 'react';
import RenderField from './utils';
import { isEmpty } from 'lodash';
import { fieldValueTypes, fieldsTypes, formTypes } from 'lib/propTypes';
import PropTypes from 'prop-types';
import { getFieldType, QuestionSetTypes } from './RepeatableFieldsetUtils';
import { useParams } from 'react-router-dom';
import { ViewMode } from 'lib/enums';

const Fields = ({
  fields,
  fieldSet,
  errors,
  handleChange,
  existingPPI,
  isCorePPI,
  layoutType,
  isApproved,
  choiceConditionMet,
  disableCategoryChoice,
  requiredFields,
}) => {
  const [showCategoryContent, setShowCategoryContent] = useState(false);
  const { viewMode } = useParams();

  //Filter the fields based on the category-choice option selected
  const modifiedFields = useMemo(
    () =>
      (choiceConditionMet
        ? fields.length > 2
          ? fields.filter((item) => item.sortOrder <= 7) // Keeping only category related fields
          : [] // Remove all, if there are only two fields, it means those are answerOptions related fields
        : fields),
    [choiceConditionMet, fields]
  );

  return !isEmpty(modifiedFields)
    ? modifiedFields
      .filter(
        (field) =>
          !['image', 'repeatingText', 'questionSet'].includes(
            getFieldType(field)
          )
      )
      .map(
        ({
          field,
          type,
          value,
          // Exclusively used for the categoryName fieldValue when a new questionCategory is created
          inheritedValue,
          optional,
          maxChars,
          label,
          options,
          isCreatable,
          activeId,
          message,
          disabled,
          className,
        }) => {
          const updateDisableProp = field?.includes(
            QuestionSetTypes.CATEGORY_CHOICE
          )
            ? disableCategoryChoice
            : disabled;
          const fieldInfo = {
            field,
            type,
            value,
            inheritedValue,
            optional,
            maxChars,
            label,
            options,
            isCreatable,
            activeId,
            message,
            existingPPI,
            isCorePPI,
            layoutType,
            fieldSet,
            className,
            disabled: updateDisableProp,
            showCategoryContent,
            setShowCategoryContent,
          };

          return (
            <div key={ `${ field }-${ type }` }>
              <RenderField
                field={ fieldInfo }
                onChange={ handleChange }
                errors={ errors }
                disabled={ disabled ?? viewMode === ViewMode.VIEW }
                isApproved={ isApproved }
                requiredFields={ requiredFields }
              />
            </div>
          );
        }
      )
    : null;
};

Fields.propTypes = {
  ...formTypes,
  fields: PropTypes.oneOfType([
    fieldsTypes.consentData,
    fieldsTypes.contentData,
    fieldValueTypes,
  ]),
};

export default Fields;
