/* eslint-disable max-statements */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import {
  QuestionSetTypes,
  getFieldValuesByQuestionSetAndNames,
} from '../../RepeatableFieldsetUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import { SURVEY_FORM } from 'lib/layoutUtils';
import { ACTIVITY_GEAR_PPIS, getFieldLabel, getPPIOptions } from '../../lib';
import { TagsInput } from 'components/fields';
import { AnswerOptions } from 'components/fields/AnswerOptionField';
import { Field, useFormikContext } from 'formik';
import { arrayOfObjects, objectOrArray } from 'lib/propTypes';
import { renderOptionHeaders } from './utils';
import { minAnswerOptionsRequired as helperText } from 'components/fields/AnswerOptionField/utils';
import { Toggle } from '@nike/eds';
import styles from './styles.module.scss';

export const TagsInputField = ({
  fieldId,
  fieldLabel,
  fieldName,
  fieldValue,
  disabled,
  hasErrors,
  errorMessage,
  errors,
  layoutType,
  onChange,
  isApproved,
  existingPPI,
  isCorePPI,
  fieldSet,
}) => {
  const { setFieldValue } = useFormikContext();
  const [currentFieldName, fieldSetId] = fieldName?.split('-');
  const isSurveyForm = layoutType === SURVEY_FORM;
  const curFieldSetInitialPPIRef = useRef(null);
  const curFieldSetInitialAnsOptsRef = useRef(null);

  const { currentFieldInitialPPIValue, initialAnswerOptionsExists } =
    useMemo(() => {
      if (
        curFieldSetInitialPPIRef.current === null ||
        curFieldSetInitialAnsOptsRef.current === null
      ) {
        const existingPPI = fieldSet?.value?.get(
          QuestionSetTypes.EXISTING_PPI
        )?.value;
        const ppiValue = existingPPI?.value;
        if (curFieldSetInitialPPIRef.current === null) {
          curFieldSetInitialPPIRef.current = ppiValue;
        }
        if (curFieldSetInitialAnsOptsRef.current === null) {
          curFieldSetInitialAnsOptsRef.current = fieldValue;
        }
      }
      return {
        currentFieldInitialPPIValue: curFieldSetInitialPPIRef.current,
        initialAnswerOptionsExists:
          !getPPIOptions(curFieldSetInitialPPIRef.current) &&
          (curFieldSetInitialAnsOptsRef.current?.length > 0 || false),
      };
    }, [fieldSet, currentFieldName, fieldSetId]);

  const { answerOptions, answerType } = getFieldValuesByQuestionSetAndNames(
    fieldSet?.value,
    [currentFieldName, QuestionSetTypes.ANSWER_TYPE]
  );
  const currentAnswerOptionsExists = !isCorePPI && answerOptions?.length > 0;

  const answerOptionsExists =
    initialAnswerOptionsExists && currentAnswerOptionsExists;

  const [showOptionDescriptions, setShowOptionDescriptions] =
    useState(answerOptionsExists);

  const showAnswerOptions = showOptionDescriptions || isCorePPI;
  const isTextOrHiddenAnsType =
    (isSurveyForm && isTextOrHiddenType(answerType)) ?? false;

  const handleToggle = useCallback(() => {
    setShowOptionDescriptions((prevState) => !prevState);
  }, []);

  const headersFields = {
    isCorePPI,
    isApproved,
    isTagsInputShown: !showAnswerOptions,
  };

  return (
    existingPPI !== ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL &&
    answerType &&
    !isTextOrHiddenAnsType && (
      <FieldWrapper disabled={ disabled }>
        <Field key={ fieldId } name={ fieldName }>
          { ({ field }) => {
            return (
              <>
                { !isCorePPI && (
                  <div className={ styles.advancedModeToggle }>
                    <Toggle
                      id={ `showOptionDesc-${ fieldSetId }` }
                      name={ `showOptionDesc-${ fieldSetId }` }
                      label={ 'Show option descriptions' }
                      onChange={ handleToggle }
                      checked={ showOptionDescriptions }
                    />
                  </div>
                ) }
                { renderOptionHeaders(headersFields) }
                { showAnswerOptions ? (
                  <AnswerOptions
                    name={ fieldName }
                    title={ getFieldLabel(fieldLabel || field.name) }
                    tags={ fieldValue || field?.value }
                    disabled={ disabled }
                    existingPPI={ existingPPI }
                    isCorePPI={ isCorePPI }
                    currentFieldInitialPPIValue={ currentFieldInitialPPIValue }
                    isApproved={ isApproved }
                    hasErrors={ hasErrors }
                    errorMessage={ errorMessage }
                    errors={ errors }
                    onChange={ onChange }
                  />
                ) : (
                  <TagsInput
                    fullWidth={ true }
                    variant="outlined"
                    id={ fieldName }
                    name={ fieldName }
                    tags={ fieldValue || field?.value }
                    disabled={ disabled }
                    title={ getFieldLabel(fieldLabel || field.name) }
                    helperText={ `${ helperText } Type or paste options separated by a tab or line break.` }
                    errorMessage={ errorMessage }
                    onChange={ onChange }
                    separators={ ['Enter', 'Tab'] }
                    setFieldValue={ setFieldValue }
                  />
                ) }
              </>
            );
          } }
        </Field>
      </FieldWrapper>
    )
  );
};

TagsInputField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  existingPPI: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldSet: PropTypes.shape({
    value: PropTypes.instanceOf(Map),
  }),
  fieldValue: arrayOfObjects,
  hasErrors: PropTypes.bool,
  isApproved: PropTypes.bool,
  isCorePPI: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
};
