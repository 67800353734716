import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SURVEY_FORM } from 'lib/layoutUtils';
import { PublishModalStyled } from './AssetCard/styled';
import { Button, Text } from '@nike/eds';

const status409ErrorHeading = 'Survey Handle Conflict Error';
const status409ErrorBody =
  'Survey handle is already in use. Survey handles must be unique. Please create a different handle for your survey and try again';
const SurveyErrorModal = ({ postResult, layoutType }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ heading: '', body: '' });

  const dismissModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    if (postResult?.error && layoutType === SURVEY_FORM) {
      const { status, data } = postResult.error;
      if (status === 409) {
        setModalInfo({
          heading: status409ErrorHeading,
          body: status409ErrorBody,
        });
        setModalOpen(true);
      } else if (data && data.errorMessage) {
        setModalInfo({
          heading: 'Error',
          body: data.errorMessage,
        });
        setModalOpen(true);
      } else {
        setModalInfo({
          heading: 'Unexpected Error',
          body: 'An unexpected error occurred. Please try again.',
        });
        setModalOpen(true);
      }
    }
  }, [postResult?.error]);
  return (
    <div>
      <PublishModalStyled
        isOpen={ modalOpen }
        onDismiss={ dismissModal }
        headerSlot={
          <Text font="title-3" as="h3">
            { modalInfo.heading }
          </Text>
        }
        footerSlot={
          <Button variant="primary" onClick={ dismissModal }>
            Close
          </Button>
        }
      >
        { modalInfo.body }
      </PublishModalStyled>
    </div>
  );
};

SurveyErrorModal.propTypes = {
  layoutType: PropTypes.string,
  postResult: PropTypes.shape({
    error: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.shape({
        errorMessage: PropTypes.string,
      }),
    }),
  }),
};

export default SurveyErrorModal;
