import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { TextField } from '@nike/eds';
import { GuideModal } from '../../GuideModal';
import { debounce } from 'lodash';

const SurveyTextField = ({
  id,
  name,
  label,
  isRequiredField,
  errorText,
  isTextFieldTouched,
  handleFieldChange,
  disabled,
  initialValue,
}) => {
  const hasErrors = !!(isTextFieldTouched && errorText);
  const [textFieldVal, setTextFieldVal] = useState('');

  useEffect(() => {
    setTextFieldVal(initialValue);
  }, [initialValue]);

  const debouncedHandleChange = useMemo(
    () => debounce((event) => handleFieldChange(event), 300),
    [handleFieldChange]
  );

  const handleChange = useCallback(
    (event) => {
      setTextFieldVal(event.target.value);
      debouncedHandleChange(event);
    },
    [debouncedHandleChange]
  );

  return (
    <Grid item={ true } xs={ 12 }>
      <TextField
        id={ id }
        name={ name }
        label={
          <label>
            { label }
            { isRequiredField && !hasErrors && (
              <span className="asterisk">*</span>
            ) }
          </label>
        }
        onChange={ handleChange }
        disabled={ disabled }
        value={ textFieldVal || initialValue || '' }
        hasErrors={ hasErrors }
        errorMessage={ hasErrors && errorText }
        message={ <GuideModal /> }
      />
    </Grid>
  );
};

SurveyTextField.propTypes = {
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  handleFieldChange: PropTypes.func,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  isRequiredField: PropTypes.bool,
  isTextFieldTouched: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

SurveyTextField.defaultProps = {
  disabled: false,
  errorText: '',
  initialValue: '',
  isRequiredField: false,
  isTextFieldTouched: false,
  label: '',
  id: '',
  name: '',
};

export default SurveyTextField;
