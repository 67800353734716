import { getPPIOptions } from 'components/forms/Assets/lib';
import { ChoiceTypes } from 'lib/enums';
import { getRandomInt } from 'lib/layoutUtils';
import { isEmpty } from 'lodash';

export const minAnswerOptionsRequired =
  'At least 2 answer options are required, and they must be different.';

export const createAnswerOptions = (existingPPI, tags, isApproved = false) => {
  const corePPIOptions = getPPIOptions(existingPPI);
  const isCorePPI = !!corePPIOptions;
  const tagMap = tags
    ? new Map(tags.map((tag) => [tag.value, tag]))
    : new Map();

  const updatedOptions = !isEmpty(tagMap)
    ? isCorePPI &&
      corePPIOptions.map((corePPI) =>
        (tagMap.has(corePPI.value)
          ? { ...corePPI, ...tagMap.get(corePPI.value) }
          : corePPI)
      )
    : corePPIOptions;

  const updatedTags = isCorePPI ? updatedOptions : tags;

  return !isEmpty(updatedTags)
    ? updatedTags.map((tag) => {
      if (typeof tag === 'string') {
        return {
          id: getRandomInt(),
          label: tag,
          value: tag,
          isReject: false,
          ...(!isCorePPI && { description: '', choiceType: 'fixed' }),
        };
      }

      const {
        label,
        name,
        value,
        selected,
        attributes,
        description = '',
        choiceType = 'fixed',
      } = tag;

      return {
        id: getRandomInt(),
        label: name || label,
        value,
        isReject:
            attributes?.isReject !== undefined
              ? attributes.isReject
              : selected !== undefined
                ? !selected
                : isApproved,
        ...(!isCorePPI && {
          description: attributes?.description || description || '',
          choiceType,
        }),
      };
    })
    : [];
};

export const addAnswerOptions = (
  prevAnsOptions,
  isWriteIn,
  isApproved = false
) => {
  const newAnsOption = {
    id: getRandomInt(),
    label: '',
    value: '',
    choiceType: isWriteIn ? ChoiceTypes.EDITABLE : ChoiceTypes.FIXED,
    isReject: isWriteIn ? undefined : isApproved,
  };

  const updatedAnswerOptions = [...prevAnsOptions];

  if (!isWriteIn) {
    const writeInIndex = updatedAnswerOptions.findIndex(
      (option) => option.choiceType === ChoiceTypes.EDITABLE
    );
    if (writeInIndex !== -1) {
      updatedAnswerOptions.splice(writeInIndex, 0, newAnsOption);
      return updatedAnswerOptions;
    }
  }
  return [...updatedAnswerOptions, newAnsOption];
};
