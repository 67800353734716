/* eslint-disable complexity */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import { TextField } from '@nike/eds';
import { debounce, isEmpty } from 'lodash';
import { ChoiceTypes } from 'lib/enums';

// eslint-disable-next-line max-statements
export const OptionInputField = ({
  id,
  value,
  fieldName,
  onChange,
  setAnswerOptions,
  answerOptionError,
  disabled,
  isValueField = false,
  choiceType,
}) => {
  const initialValueRef = useRef(value);
  const isTouchedRef = useRef(false);
  const valueId = `value-${ id }`;
  const name = isValueField ? valueId : `label-${ id }`;

  const [textFieldValue, setTextFieldValue] = useState(value);

  useEffect(() => {
    if (value !== textFieldValue) {
      setTextFieldValue(value);
    }
  }, [value]);

  const debounceOnChange = useMemo(
    () =>
      debounce((updatedAnswerOptions) => {
        onChange({ target: { name: fieldName } }, updatedAnswerOptions);
      }, 300),
    [onChange, fieldName]
  );

  const updateAnswerOption = (option, textValue, touched) => {
    const shouldUpdateValue =
      !isValueField &&
      !touched[valueId] &&
      isEmpty(initialValueRef.current) &&
      option.choiceType !== ChoiceTypes.EDITABLE;

    return {
      ...option,
      [isValueField ? 'value' : 'label']: textValue,
      ...(shouldUpdateValue && { value: textValue }),
    };
  };

  const handleTextFieldChange = useCallback(
    (event, touched) => {
      if (disabled) return;

      const textValue = event.target.value;
      setTextFieldValue(textValue);

      let updatedAnswerOptions = [];
      setAnswerOptions((prevOptions) => {
        updatedAnswerOptions = prevOptions?.map((option) => {
          return option.id === id
            ? updateAnswerOption(option, textValue, touched)
            : option;
        });
        return updatedAnswerOptions;
      });

      setTimeout(() => {
        debounceOnChange(updatedAnswerOptions);
      }, 0);
    },
    [id, debounceOnChange, setAnswerOptions, setTextFieldValue, disabled]
  );

  const errorMessage =
    typeof answerOptionError === 'string' &&
    answerOptionError?.split(': ')[1]?.trim();

  return (
    <Field key={ 'answerOptionField-' + id } name={ name } value={ value }>
      { ({ field, meta, form }) => {
        const handleChange = useCallback(
          (event) => {
            if (!isTouchedRef.current) {
              form.setFieldTouched(field.name, true);
              isTouchedRef.current = true;
            }
            handleTextFieldChange(event, form.touched);
          },
          [field.name, handleTextFieldChange, form]
        );
        const isTouchedAndEmpty = meta.touched && !value.trim();
        return (
          <TextField
            id={ name }
            name={ name }
            value={
              isValueField && choiceType === ChoiceTypes.EDITABLE
                ? ''
                : textFieldValue
            }
            disabled={
              (isValueField && choiceType === ChoiceTypes.EDITABLE) || disabled
            }
            onChange={ handleChange }
            className={ styles.textFieldStyle }
            placeholder={
              isValueField
                ? choiceType === ChoiceTypes.EDITABLE
                  ? 'User Written In Value'
                  : 'Option Value'
                : choiceType === ChoiceTypes.EDITABLE
                  ? 'Write-in Label'
                  : 'Option Label'
            }
            hasErrors={
              answerOptionError
                ? isTouchedAndEmpty
                  ? isValueField
                    ? answerOptionError
                    : answerOptionError === 'Required'
                  : isValueField &&
                    errorMessage?.toLowerCase() === value.toLowerCase()
                : false
            }
            errorMessage={ answerOptionError }
          />
        );
      } }
    </Field>
  );
};

OptionInputField.propTypes = {
  answerOptionError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  choiceType: PropTypes.string,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  isValueField: PropTypes.bool,
  onChange: PropTypes.func,
  setAnswerOptions: PropTypes.func,
  value: PropTypes.string,
};
