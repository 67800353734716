import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@nike/eds';
import { useField } from 'formik';

const DateField = ({
  name,
  label,
  setHasUnsavedChanges,
  scheduleSurveyErrors,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  const { setValue, setTouched } = helpers;
  const { value } = field;
  const { touched } = meta;
  const error = scheduleSurveyErrors?.[name];

  const handleFieldChange = useCallback((event) => {
    setValue(event.target.value);
    setTouched(true);
    setHasUnsavedChanges(true);
  }, []);

  const handleFieldTouched = useCallback(() => setTouched(true), []);

  return (
    <TextField
      type="date"
      name={ name }
      id={ name }
      value={ value }
      onChange={ handleFieldChange }
      hasErrors={ !!(touched && error) }
      errorMessage={ error }
      onBlur={ handleFieldTouched }
      { ...props }
      label={
        <label>
          { label }
          { (!error || !touched) && <span className="asterisk">*</span> }
        </label>
      }
    />
  );
};

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  scheduleSurveyErrors: PropTypes.shape({}),
  setHasUnsavedChanges: PropTypes.func,
};

export default DateField;
