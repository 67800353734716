import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, Modal, TableCell, Text } from '@nike/eds';
import { TableComponent } from './FormatsTable';
import styles from './index.module.scss';
import {
  dateFormats,
  headers,
  stringFormats,
  inquiryVariableHeaders,
} from './utils';
import { isEqual } from 'lodash';

export const GuideModal = memo(() => {
  const [modalOpen, setModalOpen] = useState(false);
  const [inquiryVariables, setInquiryVariables] = useState([]);
  const [copiedVariable, setCopiedVariable] = useState(null);

  useEffect(() => {
    const inquiryVariables = sessionStorage.getItem('inquiryVariables');
    if (inquiryVariables) {
      const parsedVars = JSON.parse(inquiryVariables);
      setInquiryVariables((prevState) =>
        (isEqual(prevState, parsedVars) ? prevState : parsedVars)
      );
    }
  }, []);

  const toggleModal = useCallback(() => {
    setModalOpen((prevState) => !prevState);
  }, []);

  const copyToClipboardHandler = async (event, text, formatType = '') => {
    event.stopPropagation();
    event.preventDefault();

    const formattedText = formatType
      ? formatType === 'date'
        ? `{{dateTimeZoneFormat activityTime "${ text }"}}`
        : `{{${ text } activityType}}`
      : `{{${ text }}}`;

    try {
      await navigator.clipboard.writeText(formattedText);
      setCopiedVariable(text);
      setTimeout(() => setCopiedVariable(null), 2000);
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  const generateRows = (items, formatType) => {
    return items.map((item, idx) => (
      <tr key={ idx }>
        <TableCell key={ `format-${ idx }` }>
          <Text>{ item.format }</Text>
        </TableCell>
        <TableCell key={ `example-${ idx }` }>
          <Text>{ item.example }</Text>
        </TableCell>
        <TableCell key={ `action-${ idx }` }>
          <Link
            as="button"
            variant="secondary"
            onClick={ useCallback((event) => {
              copyToClipboardHandler(event, item.format, formatType);
            }, []) }
            className={ styles.copyButton }
          >
            { copiedVariable === item.format ? 'Copied!' : 'Copy to clipboard' }
          </Link>
        </TableCell>
      </tr>
    ));
  };

  const inquiryVariableRows = useMemo(() => {
    if (inquiryVariables.length === 0) return [];

    return inquiryVariables.map((variable, idx) => (
      <tr key={ idx }>
        <TableCell>
          <Text>{ variable }</Text>
        </TableCell>
        <TableCell>
          <Link
            as="button"
            variant="secondary"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={ (event) => copyToClipboardHandler(event, variable) }
            className={ styles.copyButton }
          >
            { copiedVariable === variable ? 'Copied!' : 'Copy to clipboard' }
          </Link>
        </TableCell>
      </tr>
    ));
  }, [inquiryVariables, copiedVariable]);

  return (
    <Fragment>
      <Text
        font="legal-1"
        className={ `eds-color--text-secondary ${ styles.guideLink }` }
      >
        { 'Note: This field supports custom variables. ' }
        <Link onClick={ toggleModal }>{ 'Click here for usage guidelines.' }</Link>
      </Text>
      <Modal
        isOpen={ modalOpen }
        hideFade={ true }
        onDismiss={ toggleModal }
        headerSlot={
          <Text font="title-3" as="h3">
            { 'Guide to Using Custom Variables' }
          </Text>
        }
        className={ styles.guideModal }
        displayCheckOverride={ true }
      >
        <div>
          <div className="eds-spacing--mb-24">
            <div>
              <Text font="subtitle-1">Using Custom Variables</Text>
              <Text
                font="body-3"
                className="eds-spacing--mt-12 eds-spacing--mb-12"
              >
                <li>
                  {
                    'To use custom variables, enclose them in double curly braces {{ }}.'
                  }
                </li>
              </Text>
            </div>
            <div>
              <div>
                <Text font="subtitle-1">Known Custom Variables</Text>
                <Text
                  font="body-3"
                  className="eds-spacing--mt-12 eds-spacing--mb-12"
                >
                  <li>
                    {
                      'Below are the available custom variables and their usage. Click to copy any variable for easy use.'
                    }
                  </li>
                </Text>
              </div>
              <TableComponent
                headers={ inquiryVariableHeaders }
                rows={ inquiryVariableRows }
              />
            </div>
            <div>
              <Text
                font="subtitle-1"
                className="eds-spacing--mt-12 eds-spacing--mb-12"
              >
                Examples of Usage
              </Text>
              <div>
                <Text font="subtitle-2" className="eds-spacing--mb-12">
                  1. Using a variable without additional formatting:
                </Text>
                <Text
                  font="body-3"
                  className="eds-spacing--ml-24 eds-spacing--mt-12"
                >
                  <li>
                    { 'To include a variable, such as activityType, use: ' }
                    <strong>{ '{{activityType}}\n' }</strong>
                  </li>
                </Text>
              </div>
              <div>
                <Text
                  font="subtitle-2"
                  className="eds-spacing--mt-12 eds-spacing--mb-12"
                >
                  2. Using a date type variable with additional formatting:
                </Text>
                <Text
                  font="body-3"
                  className="eds-spacing--ml-24 eds-spacing--mb-12"
                >
                  <li>
                    {
                      'To include a date type variable like activityTime with a specific format, use:\n'
                    }
                  </li>
                </Text>
                <strong className="eds-spacing--ml-48">
                  { '{{dateTimeZoneFormat activityTime "MMMM d, yyyy h aaa"}}\n' }
                </strong>
                <Text
                  font="body-3"
                  className={ `${ styles.note } eds-spacing--ml-48 eds-spacing--mt-12` }
                >
                  <span>
                    { 'This ' }
                    <strong>dateTimeZoneFormat</strong>
                    {
                      ' ensures that the displayed time aligns with the reported activity time when available. If no activityTime exists, it defaults to UTC.'
                    }
                  </span>
                </Text>
              </div>
            </div>
          </div>
          <div>
            <Text font="subtitle-1" className="eds-spacing--mb-24">
              {
                'Below are some available formats with examples of how to use custom variables:'
              }
            </Text>
            <div>
              <Text font="title-4" className="eds-spacing--mb-16">
                { 'Date Formats:' }
              </Text>
              <TableComponent
                headers={ headers }
                rows={ generateRows(dateFormats, 'date') }
              />
            </div>
            <div className="eds-spacing--mb-16 eds-spacing--mt-24">
              <Text className="eds-spacing--mb-16 " font="title-4">
                { 'String Formats:' }
              </Text>
              <TableComponent
                headers={ headers }
                rows={ generateRows(stringFormats, 'string') }
              />
            </div>
            <Text font="body-3" className={ styles.note }>
              <strong>
                <u>Note</u>:
              </strong>
              { ` These placeholders will be replaced with their respective values
            when the custom variables are processed on the backend.` }
            </Text>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
});
