import React from 'react';
import PropTypes from 'prop-types';
import ImageFields from '../ImageFields';
import RepeatableFields from '../RepeatableFields';
import Fields from '../Fields';
import { formPropsTypes } from 'lib/propTypes';

const OtherLayoutsForm = ({
  formProps,
  layoutType,
  handleFieldChange,
  showConsentDocument,
  isInquiry,
  viewMode,
  requiredFields,
}) => (
  <>
    { showConsentDocument ? (
      <RepeatableFields layoutType={ layoutType } { ...formProps } />
    ) : (
      <Fields
        { ...formProps }
        layoutType={ layoutType }
        handleChange={ handleFieldChange }
      />
    ) }
    { !showConsentDocument && !isInquiry && (
      <ImageFields
        { ...formProps }
        viewMode={ viewMode }
        requiredFields={ requiredFields }
      />
    ) }
  </>
);

OtherLayoutsForm.propTypes = {
  formProps: formPropsTypes,
  handleFieldChange: PropTypes.func,
  isInquiry: PropTypes.bool,
  layoutType: PropTypes.string,
  requiredFields: PropTypes.shape({}),
  showConsentDocument: PropTypes.bool,
  viewMode: PropTypes.bool,
};

export default OtherLayoutsForm;
