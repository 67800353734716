/* eslint-disable max-statements */
import {
  ACTIVITY_GEAR_PPIS,
  excludeAnswerOptions,
  getPPIOptions,
  validateField,
} from 'components/forms/Assets/lib';
import {
  getFieldValuesByQuestionSetAndNames,
  isCategoryField,
  QuestionSetTypes,
} from 'components/forms/Assets/RepeatableFieldsetUtils';
import {
  duplicateAnswerOption,
  invalidCharacterError,
  isEmptyValue,
  isTextOrHiddenType,
  isValidCustomVariable,
  requiredError,
  specialCharRegexWithCustomVariable,
} from 'components/forms/utils';
import { useEffect, useState } from 'react';
import { propOr } from 'ramda';
import { isEmpty, isObject } from 'lodash';
import { ChoiceTypes } from 'lib/enums';

const validateCategoryFields = (fieldName, fieldValue, errors, errorKey) => {
  if (
    fieldName === QuestionSetTypes.CATEGORY_NAME &&
    isEmptyValue(fieldValue)
  ) {
    errors[errorKey] = requiredError;
  } else if (
    [
      QuestionSetTypes.CATEGORY_NAME,
      QuestionSetTypes.CATEGORY_TITLE,
      QuestionSetTypes.CATEGORY_SUBTITLE,
    ].includes(fieldName) &&
    !isEmpty(fieldValue)
  ) {
    // Category name , title and subtitle should not contain special characters
    // but title, and subtitle can be empty
    const validateResult = validateField(fieldValue);
    if (validateResult) {
      errors[errorKey] = validateResult;
    }
  } else if (
    fieldName === QuestionSetTypes.CATEGORY_TEXT &&
    !isEmpty(fieldValue) &&
    !isValidCustomVariable(fieldValue, specialCharRegexWithCustomVariable)
  ) {
    errors[errorKey] = invalidCharacterError;
  }
};

const useSetInquiryErrors = (formState, touched, isInquiry) => {
  const [inquiryErrors, setInquiryErrors] = useState({});

  useEffect(() => {
    if (
      !isInquiry ||
      !(formState instanceof Map) ||
      formState.size === 0 ||
      isEmpty(touched)
    ) {
      return;
    }
    const errors = {};

    formState.forEach((questionSet, fieldSetId) => {
      const questionSetFields = questionSet?.value;

      if (!(questionSetFields instanceof Map) || questionSetFields.size === 0) {
        return;
      }

      const {
        existingPPI: { value: ppiValue } = { label: '', value: '' },
        answerType: questionType,
        conditionalToggle: isConditionalToggled,
        categoryChoice,
      } = getFieldValuesByQuestionSetAndNames(questionSetFields, [
        QuestionSetTypes.EXISTING_PPI,
        QuestionSetTypes.ANSWER_TYPE,
        QuestionSetTypes.CONDITIONAL_TOGGLE,
        QuestionSetTypes.CATEGORY_CHOICE,
      ]);

      const isCorePPI = !!getPPIOptions(ppiValue);

      const isExcludedOrGearModelPPI = [
        ...excludeAnswerOptions,
        ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL,
      ].includes(ppiValue);

      // eslint-disable-next-line complexity
      questionSetFields.forEach((field, fieldName) => {
        const fieldValue = propOr([], 'value', field);
        const errorKey = `${ fieldName }-${ fieldSetId }`;

        validateCategoryFields(fieldName, fieldValue, errors, errorKey);

        if (!isCategoryField(fieldName) && !categoryChoice) {
          if (fieldName === QuestionSetTypes.ANSWER_OPTIONS) {
            if (isExcludedOrGearModelPPI || isTextOrHiddenType(questionType)) {
              return;
            }

            const minimumOptions = fieldValue.length >= 2;
            const hasEmptyValue =
              !fieldValue.length ||
              fieldValue.some((option) =>
                (isObject(option) && option?.choiceType === ChoiceTypes.FIXED
                  ? !option.value?.trim()
                  : option?.choiceType === ChoiceTypes.EDITABLE
                    ? false
                    : isObject(option)
                      ? !option.value?.trim()
                      : !option?.trim())
              );

            const hasEmptyLabel =
              !isCorePPI &&
              (!fieldValue.length ||
                fieldValue.some((option) => {
                  if (typeof option === 'object' && option !== null) {
                    return (
                      ('name' in option && !option.name?.trim()) ||
                      ('label' in option && !option.label?.trim())
                    );
                  }
                  return !option?.trim();
                }));

            if (!minimumOptions || hasEmptyValue || hasEmptyLabel) {
              errors[errorKey] = requiredError;
              return;
            }

            if (!isCorePPI) {
              const uniqueAnswerOptions = new Set();
              let duplicateValue = null;
              fieldValue.forEach((option) => {
                if (duplicateValue) return;
                const value = option.value?.toLowerCase();
                if (value && uniqueAnswerOptions.has(value)) {
                  duplicateValue = value;
                } else {
                  uniqueAnswerOptions.add(value);
                }
              });

              if (duplicateValue) {
                const duplicateOption = fieldValue.find(
                  (item) => item?.value?.toLowerCase() === duplicateValue
                );
                errors[
                  errorKey
                ] = `${ duplicateAnswerOption }: ${ duplicateOption?.value }`;
              }
            }
          } else if (
            fieldName === QuestionSetTypes.ANSWER_TYPE &&
            !isExcludedOrGearModelPPI &&
            !isTextOrHiddenType(questionType) &&
            !fieldValue
          ) {
            errors[errorKey] = requiredError;
          } else if (
            fieldName === QuestionSetTypes.CONDITIONAL_OPTIONS &&
            isConditionalToggled &&
            (!fieldValue || isEmpty(fieldValue))
          ) {
            //If question is toggled as conditional, CONDITIONAL_OPTIONS is required field
            errors[errorKey] = requiredError;
          } else if (
            [
              QuestionSetTypes.QUESTION_LABEL,
              QuestionSetTypes.QUESTION_CATEGORY,
              QuestionSetTypes.EXISTING_PPI,
            ].includes(fieldName) &&
            isEmptyValue(fieldValue)
          ) {
            //If questionLabel, questionCategory, existingPPI value is empty
            errors[errorKey] = requiredError;
          }
        }
      });
    });

    setInquiryErrors(errors);
  }, [isInquiry, formState, touched]);
  return {
    inquiryErrors,
    setInquiryErrors,
  };
};

export default useSetInquiryErrors;
