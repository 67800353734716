/* eslint-disable complexity */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ConditionalToggle from 'components/fields/ConditionalToggleField';
import { Grid } from '@material-ui/core';
import { TextArea } from '@nike/eds';
import { RichTextField } from 'components/fields';
import { Field } from 'formik';
import { getFieldLabel } from './lib';
import { propOr } from 'ramda';
import { ChoiceComponent } from './Components/Choice';
import { ImageField } from './Components/ImageField';
import { TextComponent } from './Components/Text';
import { RichTextComponent } from './Components/RichText';
import { TagsInputField } from './Components/TagsInputField';
import { InquirySelectField } from './Components/InquirySelectField';
import { InquiryRadioField } from './Components/InquiryRadioField';
import { INQUIRIES } from 'lib/layoutUtils';
import { arrayOfObjects, selectOptionPropType } from 'lib/propTypes';

/* eslint-disable max-params */
const RenderField = memo(
  ({ field, onChange, errors, disabled, isApproved, requiredFields }) => {
    const {
      message: fieldHelperText,
      field: fieldName,
      type: fieldType,
      value: fieldValue,
      label: fieldLabel,
      placeholder,
      sortOrder: fieldOrder,
      options: fieldOptions,
      isCreatable,
      existingPPI,
      isCorePPI,
      layoutType,
      className,
      disabled: fieldDisabled,
      showCategoryContent,
      setShowCategoryContent,
      fieldSet,
      optional,
      inheritedValue,
    } = field;

    const fieldId = `${ fieldName }-${ fieldType }`;
    const errorFiltered =
      Array.isArray(errors?.sections) && errors.sections[fieldOrder - 1]
        ? { error: errors.sections[fieldOrder - 1]?.value || '' }
        : propOr('', fieldName, errors);

    const errorMessage = propOr(null, 'error', errorFiltered) || errorFiltered;
    const hasErrors = errorMessage;

    const updatedClassName =
      className === 'categoryFields'
        ? showCategoryContent
          ? 'showContent'
          : 'hideContent'
        : className;

    const fieldMap = (fieldType) => {
      switch (fieldType) {
        case 'text':
          return (
            <TextComponent
              fieldId={ fieldId }
              fieldLabel={ fieldLabel }
              fieldType={ fieldType }
              fieldValue={ fieldValue }
              fieldHelperText={ fieldHelperText }
              fieldName={ fieldName }
              className={ updatedClassName }
              onChange={ onChange }
              disabled={ disabled }
              requiredFields={ requiredFields }
              placeholder={ placeholder }
              errorMessage={ errorMessage }
              isInquiry={ INQUIRIES.includes(layoutType) }
              isOptionalQuestion={ optional }
              inheritedValue={ inheritedValue }
            />
          );
        case 'textArea':
          return (
            <Field
              key={ fieldId }
              name={ fieldName }
              placeholder={ placeholder || fieldName }
            >
              { ({ field, meta }) => {
                return (
                  <TextArea
                    className="eds-spacing--mb-24"
                    key={ fieldId }
                    type={ fieldType }
                    id={ fieldName }
                    hasErrors={ !!meta.error }
                    errorMessage={ meta.error }
                    label={
                      <label>
                        { fieldLabel || getFieldLabel(field.name) }
                        { requiredFields[fieldName] && !meta.error && (
                          <span className="asterisk">*</span>
                        ) }
                      </label>
                    }
                    message={ fieldHelperText }
                    rows={ 3 }
                    value={ field.value }
                    onChange={ onChange }
                  />
                );
              } }
            </Field>
          );
        case 'richText':
          return (
            <RichTextComponent
              fieldId={ fieldId }
              fieldName={ fieldName }
              fieldType={ fieldType }
              fieldLabel={ fieldLabel }
              placeholder={ placeholder }
              hasErrors={ !!hasErrors }
              errorMessage={ errorMessage }
              className={ updatedClassName }
              disabled={ disabled }
              onChange={ onChange }
              message={ fieldHelperText }
              fieldValue={ fieldValue ?? '' }
            />
          );
        case 'repeatingText':
          return (
            <Field
              key={ fieldId }
              name={ fieldName }
              placeholder={ placeholder || fieldName }
            >
              { ({ field }) => (
                <RichTextField
                  hasErrors={ !!hasErrors }
                  errorMessage={ errorMessage }
                  disabled={ disabled }
                  key={ fieldId }
                  type={ fieldType }
                  id={ fieldName }
                  value={ field.value ?? fieldValue ?? '' }
                  label={
                    <label>
                      { getFieldLabel(fieldType, fieldOrder) }
                      <span
                        className={ `asterisk ${ hasErrors ? 'hasErrors' : '' }` }
                      >
                        *
                      </span>
                    </label>
                  }
                  onChange={ onChange }
                />
              ) }
            </Field>
          );
        case 'select':
          return (
            <InquirySelectField
              fieldId={ fieldId }
              fieldName={ fieldName }
              fieldLabel={ fieldLabel }
              fieldValue={ fieldValue }
              fieldSet={ fieldSet }
              errors={ errors }
              onChange={ onChange }
              disabled={ disabled }
              fieldOptions={ fieldOptions }
              errorMessage={ errorMessage }
              isCreatable={ isCreatable }
              showCategoryContent={ showCategoryContent }
              setShowCategoryContent={ setShowCategoryContent }
            />
          );
        case 'radioSet':
          return (
            <InquiryRadioField
              disabled={ disabled }
              fieldId={ fieldId }
              fieldName={ fieldName }
              fieldLabel={ fieldLabel }
              fieldValue={ fieldValue }
              hasErrors={ !!hasErrors }
              errorMessage={ errorMessage }
              placeholder={ placeholder }
              onChange={ onChange }
              layoutType={ layoutType }
              fieldOptions={ fieldOptions }
              fieldSet={ fieldSet }
              existingPPI={ existingPPI }
            />
          );
        case 'tagsInput':
          return (
            <TagsInputField
              fieldId={ fieldId }
              fieldLabel={ fieldLabel }
              fieldName={ fieldName }
              fieldValue={ fieldValue }
              disabled={ disabled }
              hasErrors={ !!hasErrors }
              errorMessage={ errorMessage }
              errors={ errors }
              layoutType={ layoutType }
              onChange={ onChange }
              existingPPI={ existingPPI }
              isCorePPI={ isCorePPI }
              isApproved={ isApproved }
              fieldSet={ fieldSet }
            />
          );
        case 'toggle':
          return (
            <ConditionalToggle
              fieldId={ fieldId }
              fieldName={ fieldName }
              fieldValue={ !!fieldValue }
              fieldSet={ fieldSet }
              onChange={ onChange }
              layoutType={ layoutType }
            />
          );
        case 'choice':
          return (
            <ChoiceComponent
              fieldId={ fieldId }
              fieldName={ fieldName }
              fieldValue={ fieldValue }
              className={ updatedClassName }
              fieldLabel={ fieldLabel }
              onChange={ onChange }
              disabled={ !!(disabled || fieldDisabled) }
            />
          );
        case 'backgroundImage':
          return (
            <ImageField
              fieldId={ fieldId }
              fieldName={ fieldName }
              fieldValue={ fieldValue }
              className={ updatedClassName }
              fieldLabel={ fieldLabel }
              onChange={ onChange }
              disabled={ !!(disabled || fieldDisabled) }
            />
          );
      }
    };

    return (
      <Grid
        item={ true }
        key={ `${ fieldId }-container` }
        style={ INQUIRIES.includes(layoutType) ? { position: 'relative' } : {} }
      >
        { fieldMap(fieldType) }
      </Grid>
    );
  }
);

RenderField.propTypes = {
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ),
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    field: PropTypes.string,
    isCorePPI: PropTypes.bool,
    placeholder: PropTypes.string,
    sortOrder: PropTypes.number,
    type: PropTypes.string,
    optional: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      selectOptionPropType,
      PropTypes.arrayOf(selectOptionPropType),
      PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          id: PropTypes.number,
          isReject: PropTypes.bool,
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      PropTypes.instanceOf(Map),
      arrayOfObjects,
      PropTypes.oneOf([undefined, null]),
    ]),
    maxChars: PropTypes.number,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    inheritedValue: PropTypes.string,
    isCreatable: PropTypes.bool,
    activeId: PropTypes.string,
    message: PropTypes.string,
    existingPPI: PropTypes.string,
    layoutType: PropTypes.string,
    fieldSet: PropTypes.shape({
      value: PropTypes.instanceOf(Map),
    }),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    showCategoryContent: PropTypes.bool,
    setShowCategoryContent: PropTypes.func,
  }),
  isApproved: PropTypes.bool,
  onChange: PropTypes.func,
  requiredFields: PropTypes.shape({}),
};

export default RenderField;
