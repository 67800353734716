import { useEffect } from 'react';
import { isArray } from 'lodash';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { KNOWN_INQUIRY_VARIABLES } from 'lib/configs';

const useSetInquiryVariables = () => {
  const { data, isFetching } = useFetchConfigQuery(KNOWN_INQUIRY_VARIABLES);

  useEffect(() => {
    if (!isFetching && data?.value?.inquiryVariables) {
      const inquiryVariableNames = data.value.inquiryVariables.map(
        (variable) => variable.name
      );
      if (isArray(inquiryVariableNames) && inquiryVariableNames.length) {
        sessionStorage.setItem(
          'inquiryVariables',
          JSON.stringify(inquiryVariableNames)
        );
      }
    }
  }, [isFetching, data]);
};

export default useSetInquiryVariables;
