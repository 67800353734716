import React from 'react';
import PropTypes from 'prop-types';
import {
  checkConditionalPosition,
  checkIfLastQuestion,
  getConditionalToggleValue,
  getHiddenSetFromStart,
  getPPIValue,
  isActivityGearModelQuestion,
  isFirstInConditionalSet,
} from '../../RepeatableFieldsetUtils';
import styles from './index.module.scss';
import { ActionButton } from './ActionButton';

// eslint-disable-next-line max-statements
export const FieldSetActions = ({
  fieldSet,
  index,
  repeatableFieldSet = new Map(),
  handleMoveUpFieldSet,
  handleMoveDownFieldSet,
}) => {
  const fieldSetId = fieldSet.fieldSetId;
  const isUsedInConditional = fieldSet.isUsedInConditional;
  const isConditionalQuestion = getConditionalToggleValue(fieldSet);
  const repeatableFieldSetArr = Array.from(repeatableFieldSet.values());

  const ppiValue = getPPIValue(fieldSet);
  const currentHiddenSet = isActivityGearModelQuestion(ppiValue)
    ? getHiddenSetFromStart(index, repeatableFieldSetArr)
    : [];

  const conditionalQuestionPosition = isConditionalQuestion
    ? checkConditionalPosition(index, repeatableFieldSetArr)
    : null;

  const isLastQuestion = checkIfLastQuestion(
    index,
    conditionalQuestionPosition,
    repeatableFieldSetArr,
    isUsedInConditional,
    ppiValue,
    currentHiddenSet
  );

  const disableMoveUp =
    index === 0 || isFirstInConditionalSet(conditionalQuestionPosition);
  const disableMoveDown =
    index === repeatableFieldSetArr.length - 1 || isLastQuestion;

  return (
    <div className={ styles.arrowsContainer }>
      <ActionButton
        onClick={ handleMoveUpFieldSet(fieldSetId) }
        disabled={ disableMoveUp }
        icon="ArrowUp"
        label="Move Up"
      />
      <ActionButton
        onClick={ handleMoveDownFieldSet(fieldSetId) }
        disabled={ disableMoveDown }
        icon="ArrowDown"
        label="Move Down"
      />
    </div>
  );
};

FieldSetActions.propTypes = {
  fieldSet: PropTypes.shape({
    fieldSetId: PropTypes.number,
    isUsedInConditional: PropTypes.bool,
    hasConditionalToggled: PropTypes.bool,
    showReorderingMenu: PropTypes.bool,
    value: PropTypes.instanceOf(Map),
  }),
  handleMoveDownFieldSet: PropTypes.func,
  handleMoveUpFieldSet: PropTypes.func,
  index: PropTypes.number,
  repeatableFieldSet: PropTypes.instanceOf(Map),
};
