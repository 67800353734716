import React, { memo } from 'react';
import styles from './index.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { RichTextField } from 'components/fields';
import { getFieldLabel } from '../../lib';
import { QuestionSetTypes } from '../../RepeatableFieldsetUtils';

export const RichTextComponent = memo(
  ({
    fieldId,
    fieldName,
    fieldType,
    placeholder,
    disabled,
    hasErrors,
    onChange,
    errorMessage,
    className,
    fieldLabel,
    message,
    fieldValue,
  }) => {
    const label = (
      <>
        { fieldName.split('-')[0] === QuestionSetTypes.CATEGORY_TEXT
          ? fieldLabel
          : getFieldLabel(fieldType) }
      </>
    );

    return (
      <div className={ cx(styles.wrapper, styles[className]) }>
        <Field
          key={ fieldId }
          name={ fieldName }
          placeholder={ placeholder || fieldName }
        >
          { ({ meta }) => (
            <RichTextField
              hasErrors={ !!(meta.touched && hasErrors) }
              errorMessage={ meta.touched && hasErrors && errorMessage }
              disabled={ disabled }
              key={ fieldId }
              type={ fieldType }
              id={ fieldName }
              name={ fieldName }
              value={ fieldValue }
              label={ label }
              onChange={ onChange }
              message={ message }
            />
          ) }
        </Field>
      </div>
    );
  }
);

RichTextComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldType: PropTypes.string,
  fieldValue: PropTypes.string,
  hasErrors: PropTypes.bool,
  message: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};
