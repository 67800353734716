/* eslint-disable react/no-multi-comp */
// modules
import { Typography } from '@material-ui/core';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { StyledDataGrid } from './overrides';
import Toolbar from './components/TableToolbar';
import ActionMenu from './components/ActionMenu';
import { formatISO } from 'lib/date';
import withActionPermissions from 'lib/hooks/withActionPermissions';
import { useParams } from 'react-router-dom';
import { EntityType } from 'lib/enums';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const DataTable = ({
  heading,
  loading,
  columns,
  rows,
  actions = [],
  initialFilter,
  sortModel,
  createHref,
  createLabel,
  handleCellClick,
  getRowClassName,
  noToolbar,
  actionMenu,
  localeText,
  muiGridProps,
  columnVisibilityModel,
}) => {
  const [pageSize, setPageSize] = useState(20);

  const { id, entity } = useParams();

  const withCreatePermissions = (createHref, createLabel) => {
    const permissionEntity =
      entity === 'audiences' ? EntityType.AUDIENCE : entity;
    const { isCreateAllowed } = useUserPermissions(id, permissionEntity);
    return {
      toolbar: isCreateAllowed ? { createHref, createLabel } : {},
    };
  };

  const dataColumns = useMemo(() => {
    const cols = columns.map((column) => {
      switch (column.type) {
        case 'dateTime':
          return {
            ...column,
            valueGetter: ({ value }) => new Date(value),
            valueFormatter: ({ value }) =>
              formatISO(value.toISOString(), {
                format: 'Ppp',
              }),
          };

        default:
          return column;
      }
    });

    if (actions.length) {
      cols.push({
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        minWidth: 180,
        filterable: false,
        renderCell: (params) => (
          <>
            { actionMenu ? (
              <ActionMenu
                actions={ withActionPermissions(
                  actions,
                  entity === EntityType.INITIATIVE && params.row.handle
                ) }
                row={ params.row }
              />
            ) : (
              // eslint-disable-next-line complexity
              withActionPermissions(actions).reduce((accum, action, i) => {
                const handleClick = action.onClick
                  ? useCallback(() => {
                    action.onClick(params.row[action.key]);
                  }, [action.onClick])
                  : null;
                const to = action.url
                  ? `${ action.url }${
                    params.row.handle || params.row.id || params.row.jobId
                  }`
                  : null;
                const isRetry = action.action === 'retry';
                const showRetry = isRetry && params.row.status === 'failed';
                // join the actions with a slash
                if (i > 0) {
                  if (!isRetry || showRetry) {
                    accum.push(
                      <span key={ `span_${ i }` } style={{ margin: '0 4px' }}>
                        { ' / ' }
                      </span>
                    );
                  }
                }
                accum.push(
                  <Link
                    disabled={ showRetry ? undefined : action.disable }
                    value={ showRetry ? params.row.jobId : params.row.id }
                    key={ `link_${ i }` }
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={
                      showRetry
                        ? () => action?.retryByJobId(params.row.jobId)
                        : handleClick
                    }
                    //Backend team is using handle as a unique id instead of uuid randomly generated
                    to={ !showRetry ? to : undefined }
                    className={ showRetry ? styles.retryBtn : undefined }
                    style={{
                      overflow: 'auto',
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                      cursor: 'pointer',
                    }}
                    children={ (!isRetry || showRetry) && action.label }
                  />
                );

                return accum;
              }, [])
            ) }
          </>
        ),
      });
    }

    return cols;
  }, [columns, actions, entity]);

  const [filterModel, setFilterModel] = useState(undefined);
  useEffect(() => {
    if (initialFilter) {
      setFilterModel({
        items: [initialFilter],
      });
    }
  }, [initialFilter]);

  const handlePageSizeChange = useCallback(
    (newPageSize) => setPageSize(newPageSize),
    []
  );

  const defaultLocaleText = {
    noRowsLabel: 'No data available',
  };

  return (
    <>
      <Typography variant="h3">{ heading }</Typography>
      <StyledDataGrid
        className={ styles.dataGrid }
        filterModel={ filterModel }
        loading={ loading }
        hideFooter={ false }
        columns={ dataColumns }
        rows={ rows || [] }
        pageSize={ pageSize }
        onPageSizeChange={ handlePageSizeChange }
        onFilterModelChange={ setFilterModel }
        pageSizeOptions={ [20] }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        localeText={ localeText || defaultLocaleText }
        slots={{
          //  loadingOverlay: LinearProgress,
          toolbar: !noToolbar && Toolbar,
        }}
        slotProps={ withCreatePermissions(createHref, createLabel) }
        disableExtendRowFullWidth={ true }
        onCellClick={ handleCellClick }
        getRowClassName={ getRowClassName }
        sortModel={ sortModel }
        columnVisibilityModel={ columnVisibilityModel }
        disableVirtualization={ true }
        { ...muiGridProps }
        sx={{
          '& .MuiDataGrid-panel': {
            color: 'red',
          },
        }}
      />
    </>
  );
};

DataTable.propTypes = {
  actionComponent: PropTypes.func,
  actionMenu: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      flex: PropTypes.number,
      minWidth: PropTypes.number,
      width: PropTypes.number,
      description: PropTypes.string,
      headerName: PropTypes.string.isRequired,
      // TODO: some of these types are custom, some are used by DataGrid
      type: PropTypes.oneOf(['string', 'array', 'number', 'dateTime', 'link']),
    })
  ),
  columnVisibilityModel: PropTypes.objectOf(PropTypes.bool),
  createHref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  createLabel: PropTypes.string,
  getRowClassName: PropTypes.func,
  handleCellClick: PropTypes.func,
  heading: PropTypes.string.isRequired,
  initialFilter: PropTypes.shape({
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
  }),
  loading: PropTypes.bool,
  localeText: PropTypes.shape({
    noRowsLabel: PropTypes.string,
  }),
  muiGridProps: PropTypes.shape({}),
  noToolbar: PropTypes.bool,
  /* eslint-disable */
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortModel: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      sort: PropTypes.string.isRequired,
    })
  ),
};

DataTable.defaultProps = {
  rows: [],
  localeText: {
    noRowsLabel: 'No Data available',
  },
};

export default DataTable;
