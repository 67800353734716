import React from 'react';
import styles from './styles.module.scss';
import { Text } from '@nike/eds';

export const renderOptionHeaders = ({
  isCorePPI,
  isApproved,
  isTagsInputShown,
}) => (
  <div className={ styles.answerOptions }>
    { isCorePPI && (
      <Text className="eds-type--title-6 eds-spacing--mb-4">
        Answer options
      </Text>
    ) }
    <div className={ styles.optionHeadersWrapper }>
      { !isTagsInputShown && (
        <div className={ styles.optionHeaders }>
          { isApproved && <span className="eds-type--subtitle-2">Accept</span> }
          { !isCorePPI && <span className="eds-type--subtitle-2">Delete</span> }
        </div>
      ) }
    </div>
  </div>
);
