import { QuestionSetTypes } from 'components/forms/Assets/RepeatableFieldsetUtils';
import { ChoiceTypes } from 'lib/enums';

// eslint-disable-next-line max-statements
export const getUpdatedTags = (id, separators, newVal, prevState) => {
  const newItems = separators.includes(' ')
    ? newVal.split(/\.|\n|\s/)
    : newVal.split(/\.|\n/);

  const cleanNewItems = newItems.map((value) => value.replaceAll(',', ''));

  const getNewTag = (cleanNewItems, length) => {
    return cleanNewItems.map((tag, index) => ({
      name: tag,
      value: tag,
      selected: false,
      attributes: { description: null },
      sortOrder: length + index,
    }));
  };

  if (id.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
    const writeInIndex = prevState.findIndex(
      (item) => item?.choiceType === ChoiceTypes.EDITABLE
    );

    if (writeInIndex !== -1) {
      const updatedItems = [...prevState];
      updatedItems.splice(
        writeInIndex,
        0,
        ...getNewTag(cleanNewItems, prevState.length - 1)
      );
      updatedItems[updatedItems.length - 1].sortOrder = updatedItems.length - 1;
      return updatedItems;
    }
    return [...prevState, ...getNewTag(cleanNewItems, prevState.length)];
  }
  return [...prevState, ...cleanNewItems];
};
