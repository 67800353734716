import React, { useCallback } from 'react';
import { Toggle } from '@nike/eds';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';

export const ToggleButton = ({
  id,
  isReject,
  disabled,
  fieldName,
  setAnswerOptions,
  onChange,
}) => {
  const handleOptionToggle = useCallback(() => {
    let updatedOptions = [];

    setAnswerOptions((prevOptions) => {
      updatedOptions = prevOptions?.map((option) =>
        (option.id === id ? { ...option, isReject: !option.isReject } : option)
      );
      return updatedOptions;
    });

    setTimeout(() => {
      onChange({ target: { name: fieldName } }, updatedOptions);
    }, 0);
  }, [id, fieldName, onChange]);

  return (
    <Toggle
      id={ 'toggle-' + id }
      checked={ !isReject }
      onClick={ handleOptionToggle }
      disabled={ disabled }
      className={ styles.toggleButton }
    />
  );
};

ToggleButton.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  isReject: PropTypes.bool,
  onChange: PropTypes.func,
  setAnswerOptions: PropTypes.func,
};
