import { getFormFields } from 'components/forms/Assets/lib';
import { BASE_URL } from 'features/adminApi/adminApiSlice';
import { DEFAULT_MEDIA } from 'lib/layoutUtils';
import { isArray } from 'lodash';
import { filter, dissoc, propOr, last } from 'ramda';

export const filterContentBy = (
  { data = [], isLoading, isFetching, isUninitialized },
  filterByKey,
  filterByValue,
  layoutType
) => {
  const currentData = data?.find((itm) => itm[filterByKey] === filterByValue);

  const formFields = getFormFields(currentData, layoutType)?.elements || [];

  const initialData = formFields?.reduce(
    (acc, element) => {
      return {
        ...acc,
        [element?.field]: currentData?.[element?.field] || '',
      };
    },
    {
      id: currentData?.id,
      initiativeHandle: currentData?.initiativeHandle,
    }
  );

  return {
    data,
    currentData,
    isLoading,
    isFetching,
    formFields,
    initialData,
    isUninitialized,
  };
};

export const getContentOptions = (assetType) => {
  switch (assetType) {
    case 'content':
      return [DEFAULT_MEDIA];
    default:
      return [assetType];
  }
};

const excludeInitParticiaptionStatusArr = ['FULL', 'COMPLETED', 'ARCHIVED'];

export function lookupTabState(activeLayoutId, tabSet) {
  const activeTab = tabSet?.filter(
    (itm) => String(itm.layoutId) === String(activeLayoutId)
  );
  return propOr('', 'tabName', last(activeTab));
}
export const renderCtasBasedOnParticipation = (data) => {
  const { initiativeParticipationStatus, cardContent } = data;
  const conditionCheck = excludeInitParticiaptionStatusArr.includes(
    initiativeParticipationStatus
  );

  if (Array.isArray(cardContent)) {
    return conditionCheck
      ? filter((fields) => fields.field !== 'ctaText', cardContent)
      : cardContent;
  } else if (data) {
    return conditionCheck ? dissoc('ctaText', data) : data;
  }
};

const regex = /-(\d+)$/;

export const formatVersionNumber = (handle) => {
  return handle?.match(regex) || '0.0.0';
};

export const sortUnpublishedConsent = (dateA, dateB) => {
  const aTimestamp = new Date(dateA?.record?.updateTimestamp).getTime() / 1000;
  const bTimestamp = new Date(dateB?.record?.updateTimestamp).getTime() / 1000;
  return bTimestamp - aTimestamp;
};

export const filteredAsset = (data, assetData) =>
  isArray(data) &&
  data?.map((item) => {
    if (item?.id === assetData?.id) {
      return assetData;
    }
    return item;
  });

// eslint-disable-next-line max-statements
const fetchContent = async (url) => {
  const headers = new Headers();
  headers.set('x-l2w-token-type', 'okta');
  headers.set('content-type', 'application/json');

  try {
    const tokenData = await JSON.parse(
      localStorage.getItem('okta-token-storage')
    );
    const token = tokenData?.accessToken?.accessToken;
    headers.set('authorization', `Bearer ${ token }`);

    const response = await fetch(url, { headers });
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    }
    throw new Error('Expected JSON, received HTML or other content');
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
};

export const fetchDefaultMedia = async (itemId, id) => {
  const url =
    itemId && id && `${ BASE_URL }/initiatives/${ id }/contents/${ itemId }`;

  try {
    if (url) {
      const response = await fetchContent(url);
      return response;
    }
  } catch (error) {
    console.error('Fetch Error:', error);
    throw error;
  }
};

// eslint-disable-next-line max-statements
export const fetchDetailsPageData = async (
  webDetailPage,
  mobileDetailPage,
  id,
  isIdPresentInWebDetailPage
) => {
  const fetchUrls = [];

  const baseURLForContent = `${ BASE_URL }/initiatives/${ id }/contents/`;

  const detailPageItems = isIdPresentInWebDetailPage
    ? webDetailPage
    : mobileDetailPage;

  detailPageItems.forEach((item) => {
    const url = `${ baseURLForContent }${ item.id }`;
    fetchUrls.push(url);
  });

  try {
    const fetchPromises = fetchUrls.map(fetchContent);
    const allData = await Promise.all(fetchPromises);
    return allData;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 error
      // either throw new Error('Unauthorized');
      // or redirect to login
      window.location.href = '/login';
    }
    throw error;
  }
};

export const convertLayoutId = (layoutId) =>
  (isNaN(Number(layoutId)) ? layoutId : Number(layoutId));
