import { isArray } from 'lodash';
import { ACTIVITY_GEAR_PPIS } from './lib';
import { SURVEY_FORM } from 'lib/layoutUtils';

export const CategoryFields = {
  CATEGORY_NAME: 'categoryName',
  CATEGORY_TITLE: 'categoryTitle',
  CATEGORY_SUBTITLE: 'categorySubtitle',
  CATEGORY_TEXT: 'categoryText',
  BACKGROUND_IMAGE: 'backgroundImage',
  CATEGORY_CHOICE: 'categoryChoice',
};

export const QuestionSetTypes = {
  CONDITIONAL_TOGGLE: 'conditionalToggle',
  SELECTION_TYPE: 'selectionType',
  CONDITIONAL_OPTIONS: 'conditionalOptions',
  QUESTION_LABEL: 'questionLabel',
  OPTIONAL_QUESTION: 'optionalQuestion',
  QUESTION_CATEGORY: 'questionCategory',
  EXISTING_PPI: 'existingPPI',
  ANSWER_TYPE: 'answerType',
  ANSWER_OPTIONS: 'answerOptions',
  ...CategoryFields,
};

export const getFieldType = (field) => {
  return field?.value?.type ? field?.value[0]?.type : field?.type;
};

export const categoryFieldValues = Object.values(CategoryFields);

export const isCategoryField = (fieldName) => {
  return categoryFieldValues.includes(fieldName);
};

// Helper function to retrieve the last element of an array
export function getLastElement(arr) {
  if (isArray(arr)) {
    return arr.at ? arr.at(-1) : arr[arr.length - 1];
  }
  return undefined;
}

export const getFieldValueByFieldName = (questionSet, fieldName) => {
  if (isArray(questionSet)) {
    const field = questionSet?.find(
      ({ field = '' }) => typeof field === 'string' && field.includes(fieldName)
    );
    return field?.value;
  }
};

export const getFieldsByQuestionSet = (questionSet, fieldNames) => {
  if (!(questionSet instanceof Map)) return undefined;
  const matchingFields = fieldNames.reduce((acc, fieldName) => {
    acc[fieldName] = questionSet.get(fieldName);
    return acc;
  }, {});
  return matchingFields;
};

export const getFieldValuesByQuestionSetAndNames = (
  questionSet,
  fieldNames
) => {
  if (!(questionSet instanceof Map)) return {};
  const matchingFields = fieldNames.reduce((acc, fieldName) => {
    acc[fieldName] = questionSet.get(fieldName)?.value;
    return acc;
  }, {});
  return matchingFields;
};

export const getLastNonConditionalFieldSetId = (
  currentFieldSetId,
  questionSets,
  fieldSetIds = []
) => {
  if (!(questionSets instanceof Map) || fieldSetIds.length === 0) return null;

  let index = fieldSetIds.indexOf(currentFieldSetId) - 1;

  // Iterating backwards to find the last non-conditional question set from the previous index
  while (index >= 0) {
    const questionSet = questionSets.get(fieldSetIds[index])?.value;
    const isConditional = questionSet?.get(
      QuestionSetTypes.CONDITIONAL_TOGGLE
    )?.value;
    // If the current questionSet is not toggled as conditional return the fieldSetId
    if (!isConditional) return fieldSetIds[index];
    // else move to the previous question set
    index--;
  }
  return null;
};

// eslint-disable-next-line max-statements
export const addDynamicQuestionLabels = (questionSets) => {
  let conditionalCount = 0;
  let nonConditionalCount = 0;

  if (questionSets instanceof Map) {
    const transformedQuestionSets = new Map(questionSets);
    for (const fieldSetId of transformedQuestionSets.keys()) {
      const questionSet = transformedQuestionSets.get(fieldSetId);
      const isConditional = questionSet?.value.get(
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )?.value;

      if (isConditional) {
        conditionalCount++;
        transformedQuestionSets.set(fieldSetId, {
          ...questionSet,
          dynamicQuestionLabel: `Conditional Question ${ conditionalCount }`,
        });
      } else {
        nonConditionalCount++;
        conditionalCount = 0;
        transformedQuestionSets.set(fieldSetId, {
          ...questionSet,
          dynamicQuestionLabel:
            questionSet?.value.get(QuestionSetTypes.QUESTION_CATEGORY)?.value
              ?.value ?? `Item ${ nonConditionalCount }`,
        });
      }
    }
    return transformedQuestionSets;
  }
  return new Map();
};

export const addIsUsedInConditionalFlag = (questionSets) => {
  if (questionSets instanceof Map) {
    const updatedQuestionsSets = new Map(questionSets);
    const fieldSetIds = [...updatedQuestionsSets.keys()];
    fieldSetIds.forEach((fieldSetId) => {
      const questionSet = updatedQuestionsSets.get(fieldSetId);
      if (questionSet?.value.get(QuestionSetTypes.CONDITIONAL_TOGGLE)?.value) {
        const lastNonConditionalFieldSetId = getLastNonConditionalFieldSetId(
          fieldSetId,
          updatedQuestionsSets,
          fieldSetIds
        );
        const lastNonConditionalQuestionSet = updatedQuestionsSets.get(
          lastNonConditionalFieldSetId
        );

        if (lastNonConditionalQuestionSet?.value instanceof Map) {
          const { questionLabel, existingPPI } =
            getFieldValuesByQuestionSetAndNames(
              lastNonConditionalQuestionSet?.value,
              [QuestionSetTypes.QUESTION_LABEL, QuestionSetTypes.EXISTING_PPI]
            );
          updatedQuestionsSets.set(lastNonConditionalFieldSetId, {
            ...lastNonConditionalQuestionSet,
            isUsedInConditional: true,
          });
          updatedQuestionsSets.set(fieldSetId, {
            ...questionSet,
            conditionalPPI: existingPPI?.value,
            conditionalLabel: questionLabel,
          });
        }
      } else if ('isUsedInConditional' in questionSet) {
        delete questionSet?.isUsedInConditional;
      }
    });

    return updatedQuestionsSets;
  }
};

export const getQuestionFieldValuesByFieldName = (
  questionSets,
  fieldSetId,
  fieldNames
) => {
  if (questionSets instanceof Map && isArray(fieldNames)) {
    const questionSet = questionSets.get(+fieldSetId)?.value;
    if (questionSet instanceof Map) {
      const matchingFields = fieldNames.reduce((acc, fieldName) => {
        acc[fieldName] = questionSet.get(fieldName)?.value;
        return acc;
      }, {});
      return matchingFields;
    }
    return {};
  }
  return {};
};

//export const findMatchingQuestionSetsWithIndex = (
//  questionSets,
//  categoryValue,
//  currentQuestionSetIdx
//) => {
//  if (questionSets instanceof Map) {
//    const fieldSetIds = [...questionSets.keys()];
//    const matchingQuestionSets = new Map();
//    questionSets.forEach((questionSet, fieldSetId) => {
//      const categoryFieldVal = questionSet?.value.get(
//        QuestionSetTypes.QUESTION_CATEGORY
//      )?.value?.value;
//      if (
//        categoryFieldVal === categoryValue &&
//        fieldSetIds.indexOf(fieldSetId) !== currentQuestionSetIdx
//      ) {
//        matchingQuestionSets.set(fieldSetId, questionSet);
//      }
//    });
//    return matchingQuestionSets;
//  }
//  return null;
//};

export const setConditionalToggledLabel = (initialValues) => {
  if (!(initialValues instanceof Map)) {
    return initialValues;
  }
  let added = false;
  const updatedQuestionSets = new Map();

  initialValues.forEach((questionSet, key) => {
    const questionFields = questionSet?.value;
    if (questionFields instanceof Map && questionFields.size > 0) {
      if (questionFields.get(QuestionSetTypes.CONDITIONAL_TOGGLE)?.value) {
        updatedQuestionSets.set(key, {
          ...questionSet,
          hasConditionalToggled: true,
        });
        added = true;
      } else {
        updatedQuestionSets.set(key, questionSet);
      }
    }
  });
  return added ? updatedQuestionSets : initialValues;
};

export const transformQuestionSetArray = (questionSets) => {
  if (isArray(questionSets)) {
    const transformedQuestionSets = new Map();

    questionSets.forEach((questionSet) => {
      const questionFields = new Map();
      questionSet?.value.forEach((questionField) => {
        questionFields.set(questionField?.field?.split('-')[0], questionField);
      });

      transformedQuestionSets.set(questionSet?.fieldSetId, {
        ...questionSet,
        value: questionFields,
      });
    });

    return transformedQuestionSets;
  }
  return new Map();
};

export const addLastNonConditionalFieldSet = (questionSetsMap) => {
  questionSetsMap.forEach((questionSet) => {
    if (
      questionSet.lastNonConditionalFieldSetId &&
      questionSetsMap.has(questionSet.lastNonConditionalFieldSetId)
    ) {
      questionSet.lastNonConditionalQuestionSet = questionSetsMap.get(
        questionSet.lastNonConditionalFieldSetId
      );
      delete questionSet.lastNonConditionalFieldSetId;
    }
  });
  return questionSetsMap;
};

export const getConditionalToggleValue = (questionSet) => {
  if (questionSet?.value instanceof Map) {
    return (
      questionSet?.hasConditionalToggled ||
      questionSet.value.get(QuestionSetTypes.CONDITIONAL_TOGGLE)?.value
    );
  }
  return null;
};

export function getCategoriesFromForm(formState) {
  if (!(formState instanceof Map)) return [];

  const categoryValues = new Map();

  for (const [, questionSet] of formState.entries()) {
    if (questionSet?.value instanceof Map) {
      const categoryVal = questionSet.value.get(
        QuestionSetTypes.QUESTION_CATEGORY
      )?.value?.value;
      if (categoryVal) {
        if (!categoryValues.has(categoryVal)) {
          categoryValues.set(categoryVal, [categoryVal]);
        } else categoryValues.get(categoryVal).push(categoryVal);
      }
    }
  }

  return categoryValues.size > 0
    ? {
      categories: [...categoryValues.entries()].map(([name, value]) => ({
        name,
        handle: name,
        questions: value,
      })),
    }
    : [];
}

// Returns the indices of questionSets with hasConditionalToggled:true starting from the given index
// and moving forward until a questionSet without hasConditionalToggled is encountered
export const getConditionalSetFromFirst = (startIndex, questionSets) => {
  //Adding the startIndex (which contains the isUsedInConditional:true) initially
  const result = [startIndex];
  for (let i = startIndex + 1; i < questionSets.length; i++) {
    const field = questionSets[i];
    if (getConditionalToggleValue(field)) {
      result.push(i);
    } else {
      break;
    }
  }
  return result;
};

// Returns the indices of questionSets with the hasConditionalToggled:true starting from the given index
// and moving backwards until a questionSet with isUsedInConditional:true is encountered
export const getConditionalSetFromLast = (lastIndex, questionSets) => {
  //Adding the lastIndex (which contains the hasConditionalToggled:true) initially
  //since it is the lastIndex of conditionalSet
  const result = [lastIndex];
  for (let i = lastIndex - 1; i >= 0; i--) {
    const field = questionSets[i];
    if (getConditionalToggleValue(field)) {
      result.unshift(i);
    } else if (field?.isUsedInConditional) {
      result.unshift(i);
      break;
    } else {
      break;
    }
  }
  return result;
};

// Returns the indices of the entire conditionalSet for the given index.
// Utilizes getConditionalSetFromFirst and getConditionalSetFromLast above helper functions
export const getFullConditionalSet = (index, questionSets) => {
  // Use getConditionalSetFromLast to iterate backward from the given index
  const backwardSet = getConditionalSetFromLast(index, questionSets);
  const lastIdx = getLastElement(backwardSet);

  // Use getConditionalSetFromFirst to iterate forward from the last index of the backwardSet
  const forwardSet = getConditionalSetFromFirst(lastIdx, questionSets);

  // Merge both sets, ensuring no duplicates
  return Array.from(new Set([...backwardSet, ...forwardSet]));
};

// Returns the indices of questionSets with hideQuestion:true starting from the given index
// and moving forward until a questionSet without hideQuestion property is encountered
// NOTE: We set hideQuestion:true for all ACTIVITY_GEAR_PPIS except ACTIVITY_GEAR_MODEL
export const getHiddenSetFromStart = (startIndex, questionSets) => {
  //Adding the startIndex (which contains the ppi-activity-gear-model) initially
  const result = [startIndex];
  for (let i = startIndex + 1; i < questionSets.length; i++) {
    const field = questionSets[i];
    if (field?.hideQuestion) {
      result.push(i);
    } else {
      break;
    }
  }
  return result;
};

export const getHiddenFieldSetIdsFromStart = (startIndex, questionSets) => {
  const fieldSetIds = [...questionSets.keys()];
  if (!(questionSets instanceof Map)) {
    return [];
  }
  //Adding the startIndex (which contains the ppi-activity-gear-model) initially
  const result = [fieldSetIds[startIndex]];

  for (let i = startIndex + 1; i < fieldSetIds.length; i++) {
    const field = questionSets.get(fieldSetIds[i]);
    if (field?.hideQuestion) {
      result.push(fieldSetIds[i]);
    } else {
      break;
    }
  }
  return result;
};

export const getPPIValue = (questionSet) => {
  if (questionSet?.value instanceof Map) {
    return questionSet.value.get(QuestionSetTypes.EXISTING_PPI)?.value?.value;
  }
  return null;
};

// Returns the indices of questionSets with hideQuestion:true starting from the given index
// and moving backwards until it encounters a questionSet with a PPI_ACTIVITY_GEAR_MODEL ppiValue
export const getHiddenSetFromLast = (lastIndex, questionSets) => {
  const result = [lastIndex];
  for (let i = lastIndex - 1; i >= 0; i--) {
    const field = questionSets[i];
    const ppiValue = getPPIValue(field);
    if (field?.hideQuestion) {
      result.unshift(i);
    } else if (ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL) {
      result.unshift(i);
      break;
    }
  }
  return result;
};

export const isActivityGearModelQuestion = (ppiValue) =>
  ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL;

// Checks if the given index is the last isActivityGearModelQuestion question in the repeatableFieldSet.
export const checkIfActivityGearModelIsLastQuestion = (
  ppiValue,
  currentHiddenSet,
  repeatableFieldSet
) => {
  if (isActivityGearModelQuestion(ppiValue) && currentHiddenSet?.length > 0) {
    const nextIndexAfterHiddenSet = getLastElement(currentHiddenSet) + 1;
    return !repeatableFieldSet[nextIndexAfterHiddenSet];
  }
  return false;
};

// Checks if the given index is the last conditional question in the repeatableFieldSet.
export const checkIfConditionalIsLastQuestion = (index, repeatableFieldSet) => {
  const conditionalSet = getConditionalSetFromFirst(index, repeatableFieldSet);
  const nextIndexAfterConditionalSet = getLastElement(conditionalSet) + 1;
  return !repeatableFieldSet[nextIndexAfterConditionalSet];
};

// Checks if the given index is the first or last in the conditionalSet.
// Returns first if it's the first index, last if it's the last index, or null otherwise.
export const checkConditionalPosition = (index, repeatableFieldSet) => {
  const conditionalSet = getFullConditionalSet(index, repeatableFieldSet);
  //Taking second index of conditionalSet as the first index has isUsedInConditional:true
  const isFirst = index === conditionalSet[1];
  const isLast = index === getLastElement(conditionalSet);

  //it means only one conditional question exists in the set
  if (isFirst && isLast) return ['first', 'last'];
  if (isFirst) return 'first';
  if (isLast) return 'last';

  return null;
};

// Helper function to check if the question is the first in the conditional set
export const isFirstInConditionalSet = (position) =>
  position === 'first' || (isArray(position) && position.includes('first'));

// Helper function to check if the question is the last in the conditional set
export const isLastInConditionalSet = (position) =>
  position === 'last' || (isArray(position) && position.includes('last'));

// Helper function to check if the current question is the last question
export const checkIfLastQuestion = (
  index,
  conditionalQuestionPosition,
  repeatableFieldSet,
  isUsedInConditional,
  ppiValue,
  currentHiddenSet
  // eslint-disable-next-line max-params
) => {
  return (
    checkIfActivityGearModelIsLastQuestion(
      ppiValue,
      currentHiddenSet,
      repeatableFieldSet
    ) ||
    isLastInConditionalSet(conditionalQuestionPosition) ||
    (isUsedInConditional
      ? checkIfConditionalIsLastQuestion(index, repeatableFieldSet)
      : false)
  );
};

export const removeFieldSetFromMap = (
  setFormState,
  fieldSetId,
  setFieldTouched,
  setFieldValue
) => {
  // eslint-disable-next-line max-statements
  setFormState((prevState) => {
    const newState = new Map(prevState);
    const fieldSetIds = [...newState.keys()];
    if (!newState.has(fieldSetId)) {
      return newState;
    }
    const fieldToRemove = newState.get(fieldSetId);
    const ppiValue = fieldToRemove?.value.get(QuestionSetTypes.EXISTING_PPI)
      ?.value?.value;

    if (isActivityGearModelQuestion(ppiValue)) {
      const currentIdx = fieldSetIds.indexOf(fieldSetId);
      const hiddenSetIndices = getHiddenFieldSetIdsFromStart(
        currentIdx,
        newState
      );
      hiddenSetIndices
        .reverse()
        .forEach((hiddenId) => newState.delete(hiddenId));
    } else {
      newState.delete(fieldSetId);
    }

    let sortOrder = 1;
    newState.forEach((field) => (field.sortOrder = sortOrder++));

    const updatedState = addIsUsedInConditionalFlag(newState);

    if (updatedState) {
      setFieldValue('questionSet', updatedState);
      setFieldTouched('questionSet', true);
      return updatedState;
    }
    return newState;
  });
};

export const shouldShowDeleteLink = ({
  disabled,
  formState,
  fieldSet,
  questionIndex,
}) => {
  if (formState instanceof Map) {
    const hasEnoughQuestions = !disabled && formState.size > 1;
    const currentHiddenSet = isActivityGearModelQuestion(fieldSet?.ppiHandle)
      ? getHiddenFieldSetIdsFromStart(questionIndex, formState)
      : [];
    const isOnlyHiddenSetExists = currentHiddenSet?.length === formState.size;

    return !(
      fieldSet?.isUsedInConditional ||
      !hasEnoughQuestions ||
      isOnlyHiddenSetExists
    );
  }
  return false;
};
