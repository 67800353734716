/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-statements */
// modules
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Text } from '@nike/eds';
import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { isEmpty, prop, propOr, propEq, pipe, filter, map } from 'ramda';
import { CandidatesForm } from 'components/forms';
//import AudienceMemberList from 'views/Audience/components/AudienceMemberList';
import { usePatchParticipationsMutation } from 'features/adminApi/endpoints/userParticipation';
import {
  useFetchScreenerByIdQuery,
  useFetchScreenerQuery,
} from 'features/adminApi/endpoints/screener';
import CandidateDownload from './CandidateDownload';

// aliased
import { FormContainer } from 'components/FormContainer';
import { useFetchInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import { useToasts } from 'react-toast-notifications';
import Loading from 'components/Loading';

// eslint-disable-next-line react/prop-types
const CandidateFormWrapper = () => {
  const history = useHistory();
  const { id, initiativeType } = useParams();
  const initiative = useFetchInitiativeQuery(id, { skip: !id });
  const { addToast } = useToasts();
  const [patchParticipations, patchParticipationsResult] =
    usePatchParticipationsMutation();
  const [, setParticipationError] = useState();
  //const [showAddParticipants, setShowAddParticipants] = useState();
  const [downloadFile, setDownloadFile] = useState(false);
  const [downloadCount, setDownloadCount] = useState(0);

  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const [participationsResult, setParticipationsResult] = useState([]);

  const { shortHandle } = initiative?.data || '';

  const { data: screenerData = [], isLoading: isScreenerLoading } =
    useFetchScreenerQuery(id);

  const layoutId = pipe(
    filter(propEq('status', 'published')),
    filter(propEq('inquiryType', 'screener')),
    map(prop('id'))
  )(screenerData);

  const { data: screenerDataById, isLoading } = useFetchScreenerByIdQuery(
    { initiativeHandle: id, id: layoutId[0] },
    {
      skip: !id || isEmpty(layoutId),
    }
  );

  const inquiryData = screenerData.filter(
    (data) => data.inquiryType === 'screener'
  );
  const inquiryHandle = propOr('', 'handle', inquiryData[0]);

  const handleCandidateDownload = useCallback(() => {
    setDownloadCount((prevState) => prevState + 1);
    setDownloadFile(true);
  }, []);

  const handleUpdateUserParticipation = useCallback(async (values) => {
    const addParticipationConflictError = 409;
    if (values.upmids) {
      values.upmids.map(async (upmid) => {
        const { handle } = values;
        const data = { upmid, initiativeHandle: id, status: 'eligible' };
        const result = await patchParticipations(data);

        if (
          result.data?.handle === handle ||
          result.error?.status === addParticipationConflictError
        ) {
          //Remove users added or already part of candidate from input field
          setParticipationsResult(...participationsResult, upmid);
        }
      });
    }
  }, []);

  //Check for data
  useEffect(() => {
    if (!isScreenerLoading && isEmpty(screenerData)) {
      addToast(`No screener is enabled for initiative ${ id }`, {
        appearance: 'info',
        autoDismiss: true,
      });
      history.push(`/initiative/${ initiativeType }`);
    } else if (!isLoading && screenerDataById?.attributes?.isAutomated) {
      addToast(
        `Automated review is enabled on screener for initiative ${ id } thus candidate review is not available.`,
        {
          appearance: 'info',
          autoDismiss: true,
        }
      );
      history.push(`/initiative/${ initiativeType }`);
    }
  }, [screenerData, screenerDataById, isScreenerLoading]);

  //Add participations results
  useEffect(() => {
    if (patchParticipationsResult.isSuccess) {
      addToast('Participant(s) successfully invited', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
  }, [patchParticipationsResult.isSuccess]);

  useEffect(() => {
    if (patchParticipationsResult.error?.data.errorMessage) {
      setParticipationError(patchParticipationsResult.error?.data.errorMessage);
      addToast(
        'Error finding participant(s). Please check for correct upmid(s).',
        {
          appearance: 'error',
          autoDismiss: true,
        }
      );
    }
  }, [patchParticipationsResult.error]);

  //   const addParticipation = useCallback(() => {
  //     setShowAddParticipants(true);
  //   }, []);

  //TODO: check if screener candidates exist, if not, redirect to init list
  //currently no way to check for screener candidates by init

  //TODO - uncomment and update once have names & availability of endpoint
  //   const handleRemove = useCallback(async (upmId) => {
  //     await deleteUser({ id, upmId });
  //   }, []);

  //TODO - uncomment and update once have names & availability of endpoint
  //   useEffect(() => {
  //     if (deleteUserResult?.isSuccess) {
  //       addToast('Participation(s) successfully removed', {
  //         appearance: 'success',
  //         autoDismiss: true,
  //       });
  //     }
  //     if (deleteUserResult?.error?.data?.errorMessag) {
  //       addToast(deleteUserResult?.error?.data.errorMessage, {
  //         appearance: 'error',
  //         autoDismiss: true,
  //       });
  //     }
  //   }, [deleteUserResult]);

  //   const userDataTemp = {
  //     users: [
  //       {
  //         id: '6033b907-41cd-4b60-b61c-967216dd50b4',
  //         upmId: '990ae569-7a75-4805-b305-a9872ea4b4cc',
  //         createTimestamp: '4442-10-06T17:03:00.113Z',
  //         updateTimestamp: '4442-10-06T17:03:00.113Z',
  //       },
  //       {
  //         id: '58ecd947-45a4-41ac-a58a-ed2d07f61eff',
  //         upmId: 'b6fc7acf-0d34-4110-a688-1bc6e30ae890',
  //         createTimestamp: '4442-07-05T14:46:05.126Z',
  //         updateTimestamp: '4442-07-05T14:46:05.126Z',
  //       },
  //       {
  //         id: 'fe47b46f-7818-4cdc-9ba7-f6a1978a5b3d',
  //         upmId: 'cb074081-b64c-4d9f-9138-45f93112fea6',
  //         createTimestamp: '4442-09-26T07:43:21.433Z',
  //         updateTimestamp: '4442-09-26T07:43:21.433Z',
  //       },
  //       {
  //         id: 'eb1e3481-1e57-4f8f-877d-479b21701b49',
  //         upmId: 'fab67910-1f3e-4fa6-bb46-bffd1ae7b6e5',
  //         createTimestamp: '4442-09-21T02:48:25.277Z',
  //         updateTimestamp: '4442-09-21T02:48:25.277Z',
  //       },
  //     ],
  //   };
  const renderCandidateDownload = useMemo(() => {
    if (downloadFile && downloadCount > 0) {
      return (
        <CandidateDownload
          id={ id }
          inquiryHandle={ inquiryHandle }
          shortHandle={ shortHandle }
          downloadCount={ downloadCount }
        />
      );
    }
  }, [downloadCount]);

  return (
    <>
      { isScreenerLoading ? (
        <Loading text="Fetching Candidates data" />
      ) : (
        <FormContainer>
          <>
            <Text font={ 'title-1' } as={ 'h1' }>
              { `Update Candidate Eligibility for ${
                initiative.data?.name || ''
              }` }
            </Text>
            <Grid
              container={ true }
              justifyContent="space-between"
              style={{ textAlign: 'right', marginTop: 40 }}
            >
              { !isScreenerLoading && (
                <Grid item={ true } xs={ 12 }>
                  <Button size="small" onClick={ handleCandidateDownload }>
                    Download Candidate Responses
                  </Button>
                </Grid>
              ) }
              { renderCandidateDownload }
            </Grid>
            <Grid container={ true } justifyContent="space-between">
              <Grid item={ true } xs={ 12 }>
                <FormContainer>
                  <CandidatesForm
                    disabled={ false }
                    isLoading={ false }
                    hasUsers={ true }
                    onCancel={ handleCancel }
                    onSubmit={ handleUpdateUserParticipation }
                  />
                </FormContainer>
              </Grid>
            </Grid>
            { /*
        TODO: get user participation status data
        <Grid>
          <AudienceMemberList
            //handleRemove={ handleRemove }
            createHref={ addParticipation }
            //loading={ isLoadingUsers }
            title={ 'Invited Candidates' }
            loading={ false }
            data={ userDataTemp?.users || [] }
            //viewMode={ ViewMode.EDIT }
          />
        </Grid> */ }
          </>
        </FormContainer>
      ) }
    </>
  );
};

export default CandidateFormWrapper;
