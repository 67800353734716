import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Plus } from '@nike/nike-design-system-icons';
import styles from '../styles.module.scss';
import { Button } from '@nike/eds';
import { debounce } from 'lodash';
import { addAnswerOptions } from '../utils';

export const AddAnswerOption = ({
  isApproved,
  fieldName,
  disabled,
  onChange,
  setAnswerOptions,
  isWriteIn = false,
}) => {
  const debounceAdd = useMemo(
    () =>
      debounce((updatedAnswerOptions) => {
        onChange({ target: { name: fieldName } }, updatedAnswerOptions);
      }, 100),
    [fieldName, onChange]
  );

  const handleAddOption = useCallback(() => {
    let updatedAnswerOptions = [];

    setAnswerOptions((prevOptions) => {
      updatedAnswerOptions = addAnswerOptions(
        prevOptions,
        isWriteIn,
        isApproved
      );
      return updatedAnswerOptions;
    });

    setTimeout(() => {
      debounceAdd(updatedAnswerOptions);
    }, 0);
  }, [isApproved, isWriteIn, debounceAdd, setAnswerOptions]);

  return (
    <div className="eds-spacing--mt-24">
      <Button
        size="small"
        variant={ isWriteIn ? 'secondary' : 'primary' }
        onClick={ handleAddOption }
        icon="Close"
        afterSlot={ <Plus /> }
        disableRipple={ true }
        className={ styles.addAnswerOptionBtn }
        disabled={ disabled }
      >
        { isWriteIn ? 'Add Write-In Option' : 'Add Option' }
      </Button>
    </div>
  );
};

AddAnswerOption.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  isApproved: PropTypes.bool,
  isWriteIn: PropTypes.bool,
  onChange: PropTypes.func,
  setAnswerOptions: PropTypes.func,
};
