/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useMemo, useState } from 'react';
import { AddEditForm } from '../components';
import { useHistory, useParams } from 'react-router-dom';
import { getFormFields } from 'components/forms/Assets/lib';
import Loading from 'components/Loading';
import {
  checkAndUpdateScreenerFields,
  formatInquiryDataForAsset,
  SURVEY_FORM,
} from 'lib/layoutUtils';
import {
  useFetchSurveyQuery,
  useCreateSurveyMutation,
  usePatchSurveyMutation,
  useFetchSurveyByIdQuery,
} from 'features/adminApi/endpoints/survey';
import { isEmpty } from 'lodash';
import { usePPIlist } from 'lib/hooks/usePPIlist.js';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { PPI_ANSWER_OPTIONS } from 'lib/configs';
import { convertLayoutId, filteredAsset } from '../utils';
import { ViewMode } from 'lib/enums';
import SurveyPreview from '../components/Preview/SurveyPreview';

// Keep feature hidden until ready
const showWebPreview = !!sessionStorage.getItem('showWebPreview');

const AddEditSurvey = () => {
  const { id, type, viewMode } = useParams();
  const isCreatePage = viewMode === ViewMode.CREATE;
  const history = useHistory();
  const [layoutId, setlayoutId] = useState();
  const [formFields, setFormFields] = useState([]);
  const [initialData, setInitialData] = useState({});

  const { data, isLoading } = useFetchSurveyQuery(id);
  const [usePatchSurvey, patchSurveyResult] = usePatchSurveyMutation();
  const [useCreateSurvey, postSurveyResult] = useCreateSurveyMutation();
  const { data: surveyData, isLoading: isSurveyLoading } =
    useFetchSurveyByIdQuery(
      { initiativeHandle: id, id: layoutId },
      {
        skip: !id || !layoutId || type !== SURVEY_FORM || isCreatePage,
      }
    );

  const ppiList = usePPIlist();
  const { data: { value: configData } = {}, isFetching } =
    useFetchConfigQuery(PPI_ANSWER_OPTIONS);

  useEffect(() => {
    if (!isFetching && !isEmpty(configData)) {
      window.localStorage.setItem(
        'ppi-answer-options',
        JSON.stringify(configData)
      );
    }
  }, [isFetching, configData]);

  useEffect(() => {
    if (isCreatePage) {
      sessionStorage.removeItem('layoutId');
    }
  }, [isCreatePage]);

  useEffect(() => {
    const initialLayoutId = history?.location?.state?.layoutId
      ? history.location.state.layoutId
      : JSON.parse(sessionStorage.getItem('layoutId')) || initialData.id;
    const finalLayoutId = convertLayoutId(initialLayoutId);
    setlayoutId(finalLayoutId);
  }, [history, initialData]);

  useEffect(() => {
    const selectedData = surveyData
      ? surveyData.inquiryType === SURVEY_FORM && surveyData
      : layoutId && data && !isCreatePage
        ? data.filter((itm) => itm.id === layoutId).pop()
        : [];

    const transformedData = !isEmpty(selectedData)
      ? formatInquiryDataForAsset(selectedData, SURVEY_FORM, ppiList)
      : {};

    const formFields =
      getFormFields(transformedData, SURVEY_FORM, ppiList).elements[0].value ??
      new Map();

    setFormFields(new Map(formFields));
    setInitialData(new Map(formFields));
  }, [data, surveyData, layoutId, ppiList, viewMode]);

  const finalData = useMemo(
    () => (viewMode !== ViewMode.CREATE ? filteredAsset(data, surveyData) : []),
    [data, surveyData]
  );

  return isLoading || isSurveyLoading || isEmpty(initialData) ? (
    <Loading show={ true } text="Fetching Survey Details" />
  ) : ViewMode.PREVIEW && window?.previewData ? (
    <SurveyPreview surveyProps={{ ...window.previewData }} />
  ) : (
    <AddEditForm
      layoutId={ layoutId }
      submitText={ 'Save Survey' }
      patchResult={ patchSurveyResult }
      postResult={ postSurveyResult }
      useCreate={ useCreateSurvey }
      usePatch={ usePatchSurvey }
      viewMode={ viewMode }
      data={ finalData }
      initialData={ initialData }
      assetType={ SURVEY_FORM }
      layoutType={ SURVEY_FORM }
      formFields={ formFields }
      updateFields={ checkAndUpdateScreenerFields }
      initiativeHandle={ id }
      showWebPreview={ showWebPreview }
    />
  );
};

export default AddEditSurvey;
