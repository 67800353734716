import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ToggleConditionalWrapper } from './styles';
import { Toggle, Tooltip } from '@nike/eds';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// eslint-disable-next-line max-statements
const ToggleField = ({
  hasChecked = false,
  fieldName = '',
  fieldValue = false,
  fieldId = '',
  lastNonConditionalQuestionLabel = '',
  onChange,
  disabled = false,
  hasUsedInConditional = false,
  isTextOrHiddenAnswerType = false,
}) => {
  const [toggleFieldVal, setToggleFieldVal] = useState(fieldValue);
  let tooltipMessage = null;

  if (hasChecked) {
    tooltipMessage = `⚠️ Once toggled as a conditional
      question, it cannot be reverted to a
      non-conditional question.`;
  } else if (hasUsedInConditional) {
    tooltipMessage =
      '⚠️ If answer options of this question are used for conditional questions, then it can\'t be toggled as conditional.';
  } else if (isTextOrHiddenAnswerType) {
    tooltipMessage =
      '⚠️ This question  can\'t be toggled as conditional because the last non-conditional question is a text or hidden type.';
  }
  const debounceOnChange = useMemo(
    () => debounce((event) => onChange(event), 50),
    [onChange]
  );
  const handleToggleFieldChange = useCallback(
    (event) => {
      setToggleFieldVal(event.target.checked);
      debounceOnChange(event);
    },
    [debounceOnChange]
  );

  useEffect(() => {
    if (fieldValue !== toggleFieldVal) setToggleFieldVal(fieldValue);
  }, [fieldValue]);

  const toggleElement = (
    <ToggleConditionalWrapper isChecked={ hasChecked }>
      <Toggle
        id={ fieldId }
        name={ fieldName }
        label={
          fieldValue
            ? `Conditional Question:  ${ lastNonConditionalQuestionLabel }`
            : 'Conditional Question'
        }
        onChange={ handleToggleFieldChange }
        checked={ toggleFieldVal }
        disabled={ disabled }
      />
    </ToggleConditionalWrapper>
  );

  return tooltipMessage ? (
    <Tooltip
      bodySlot={ tooltipMessage }
      placement="bottom-start"
      enableFocus={ true }
      isDark={ true }
    >
      { toggleElement }
    </Tooltip>
  ) : (
    toggleElement
  );
};

ToggleField.propTypes = {
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.bool,
  hasChecked: PropTypes.bool,
  hasUsedInConditional: PropTypes.bool,
  isTextOrHiddenAnswerType: PropTypes.bool,
  lastNonConditionalQuestionLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default ToggleField;
