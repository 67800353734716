/* eslint-disable complexity */
/* eslint-disable max-statements */
import * as Yup from 'yup';
import { propOr, sum } from 'ramda';
import { weightOptions, heightOptions } from 'lib/ppiOptions.js';
import { isEmpty, kebabCase } from 'lodash';
import { marked } from 'marked';

import {
  getFilteredPPIs,
  MOBILE_DETAIL_SCREEN,
  radioSetOptions,
  WEB_DETAIL_PAGE,
  WEB_OVERVIEW_CARD,
} from 'lib/layoutUtils';
import {
  invalidCharacterError,
  requiredError,
  nonSpecialCharRegex,
  uuidFormatError,
  customVariableSupportText as message,
  textBeforeOrAfterCustomVariableError,
  emptyCustomVariableError,
} from '../utils';
import {
  addLastNonConditionalFieldSet,
  QuestionSetTypes,
  transformQuestionSetArray,
} from './RepeatableFieldsetUtils';

export const INITIAL_VALUES = {
  tagline: '',
  headline: '',
};

export const ACTIVITY_GEAR_PPIS = {
  PPI_ACTIVITY_GEAR_MODEL: 'ppi-activity-gear-model',
  PPI_ACTIVITY_GEAR_BRAND: 'ppi-activity-gear-brand',
  PPI_ACTIVITY_GEAR_NAME: 'ppi-activity-gear-name',
  PPI_ACTIVITY_GEAR_IMAGE: 'ppi-activity-gear-image',
  PPI_ACTIVITY_GEAR_TYPE: 'ppi-activity-gear-type',
};

export const activityGearPPIs = Object.values(ACTIVITY_GEAR_PPIS);

export const getPPIOptions = (ppiHandle) => {
  const storedData = window.localStorage.getItem('ppi-answer-options');
  const ppiChoices = storedData ? JSON.parse(storedData).ppiAnswerOptions : {};

  if (!ppiChoices) return null;

  // Returns [key,value] pair as an object to spread below
  const mapPPIChoice = (choice) => {
    if (!isEmpty(choice)) {
      return { [choice.ppiType]: choice.options };
    }
  };

  const ppiOptions = {
    'ppi-height': heightOptions(36, 95),
    'ppi-weight': weightOptions(60, 500),
    'ppi-shoe-size': ppiChoices?.shoeSizes?.options,
    'ppi-mens-shoe-size': ppiChoices?.shoeSizes?.options,
    'ppi-womens-shoe-size': ppiChoices?.shoeSizes?.options,
    ...mapPPIChoice(ppiChoices?.mensTopSizes),
    ...mapPPIChoice(ppiChoices?.mensBottomSizes),
    ...mapPPIChoice(ppiChoices?.womensTopSizes),
    ...mapPPIChoice(ppiChoices?.womensBottomSizes),
    ...mapPPIChoice(ppiChoices?.activityChoices),
    ...mapPPIChoice(ppiChoices?.genderIdentity),
    ...mapPPIChoice(ppiChoices?.nikeAffiliationChoices),
    ...mapPPIChoice(ppiChoices?.raceChoices),
    ...mapPPIChoice(ppiChoices?.portlandArea),
  };

  return ppiOptions[ppiHandle] ?? null;
};

const stringValidation = (maxChars = 1000) =>
  Yup.string()
    .max(maxChars)
    .test('required', requiredError, (value) => {
      return value !== undefined && value !== null && value.trim() !== '';
    });

const imageFieldsValidation = () =>
  Yup.string().required(requiredError)
    .nullable();

const richTextValidation = (maxChars) =>
  Yup.string()
    .transform((value, originalValue) => {
      if (originalValue) {
        const markdown = marked(value);
        const div = document.createElement('div');
        div.innerHTML = markdown;
        const textContent = div.textContent || div.innerText || '';
        //Rich text also shows up with \ formatting when blank and few other cases, so need to check for that
        const convertedText = textContent
          .replace(/[\r\n\s\\]+/g, '') // Remove line breaks, whitespace, and backslashes
          .trim(); // Trim any leading or trailing whitespace
        return convertedText;
      }
      return '';
    })
    .test('non empty', requiredError, (value) => !!value?.length)
    .required(requiredError)
    .max(maxChars);

export const defaultAsset = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(95),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const overviewCard = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(25),
  text: richTextValidation(6000),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const webOverviewCard = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(25),
  text: richTextValidation(85),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const mobileOverviewCard = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(85),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const mobileReturningCard = Yup.object().shape({
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const mobileDetailScreen = Yup.object().shape({
  title: stringValidation(40),
  text: richTextValidation(1200),
  subtitle: stringValidation(85),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const webDetailPage = Yup.object().shape({
  title: stringValidation(60),
  subtitle: stringValidation(95),
  text: richTextValidation(6000),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const consentDocument = Yup.object().shape({
  sections: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        value: Yup.string()
          //should not contain consecutive empty spaces at the start.
          //Rich text shows up with formatting when blank, so need to check for that
          .test(
            'no-empty-or-whitespace',
            'Required',
            (value) => value && value.trim() !== ''
          )
          .matches(/^(?:(?=\n1)|(?!\s))(?!\\\n)/, 'Invalid entry'),
      })
    ),
});

const createCustomError = (context, val1, errorMessage = 'Required') =>
  context.createError({
    message: errorMessage,
    path: context.path,
    value: val1,
  });

// Regular expression for alphanumeric and punctuation characters, excluding single curly braces
const alphanumericWithPunctuationRegex = /^[0-9A-Za-z\s\-.,?!:;'"()[\]]+$/;

export const validateField = (value, context = null, val1 = null) => {
  const trimmedValue = value?.trim();
  // Checking for empty value after trimming to handle required validation
  if (!trimmedValue) {
    return context
      ? createCustomError(context, val1, requiredError)
      : requiredError;
  }
  // Removing content inside double curly braces for checking the invalid characters
  const cleanedValue = value && value.replace(/\{\{.*?\}\}/g, '');

  // Checking for surrounding text
  const customVariablePatternForSurroundingText = /\{\{.*?\}\}/;
  if (customVariablePatternForSurroundingText.test(value)) {
    if (
      value.replace(customVariablePatternForSurroundingText, '').trim()
        .length === 0
    ) {
      return context
        ? createCustomError(context, val1, textBeforeOrAfterCustomVariableError)
        : textBeforeOrAfterCustomVariableError;
    }
  }
  // Checking for invalid single curly braces
  if (
    cleanedValue &&
    (cleanedValue.includes('{') || cleanedValue.includes('}'))
  ) {
    return context
      ? createCustomError(context, val1, invalidCharacterError)
      : invalidCharacterError;
  }
  // Checking for valid characters outside double braces
  if (!alphanumericWithPunctuationRegex.test(cleanedValue)) {
    return context
      ? createCustomError(context, val1, invalidCharacterError)
      : invalidCharacterError;
  }

  // Checking for empty double braces
  const emptyBracesPattern = /\{\{\}\}/;
  if (emptyBracesPattern.test(value)) {
    return context
      ? createCustomError(context, val1, emptyCustomVariableError)
      : emptyCustomVariableError;
  }

  // Checking for unmatched braces
  const openBracesCount = (value.match(/\{\{/g) || []).length;
  const closeBracesCount = (value.match(/\}\}/g) || []).length;
  if (openBracesCount !== closeBracesCount) {
    return context
      ? createCustomError(context, val1, invalidCharacterError)
      : invalidCharacterError;
  }

  return null;
};

export function validateCustomInquiryVariables(value, inquiryVariables) {
  // Checking for unknown customVariables
  const customVariablePattern = /\{\{(.*?)\}\}/g;
  let variableFound;
  while ((variableFound = customVariablePattern.exec(value)) !== null) {
    const content = variableFound[1];
    const containsInquiryVariable = inquiryVariables.some((variable) =>
      content.includes(variable)
    );
    if (!containsInquiryVariable) {
      return true;
    }
  }
  return false;
}

//const checkCategoryFieldValidity = (fields, value, val1, context) => {
//  if (
//    (fields.includes(QuestionSetTypes.QUESTION_LABEL) ||
//      fields.includes(QuestionSetTypes.QUESTION_CATEGORY) ||
//      fields.includes(QuestionSetTypes.EXISTING_PPI)) &&
//    isEmptyValue(value)
//  ) {
//    //If questionLabel, questionCategory, existingPPI value is empty -> false
//    return false;
//  }

//  //Category name should not contain special characters and it shouldn't be empty
//  if (fields.includes(QuestionSetTypes.CATEGORY_NAME)) {
//    return isEmptyValue(value) ? false : validateField(value, context, val1);
//  }

//  //Category title and subtitle should not contain special characters. However they can be empty
//  if (
//    fields.includes(QuestionSetTypes.CATEGORY_TITLE) ||
//    fields.includes(QuestionSetTypes.CATEGORY_SUBTITLE)
//  ) {
//    if (!isEmpty(value)) {
//      return validateField(value, context, val1);
//    }
//  }

//  //Category text can be empty and allows special characters too
//  if (fields.includes(QuestionSetTypes.CATEGORY_TEXT)) {
//    if (
//      !isValidCustomVariable(value, specialCharRegexWithCustomVariable) &&
//      !isEmpty(value)
//    ) {
//      return createCustomError(context, val1, invalidCharacterError);
//    }
//  }

//  return null;
//};

const screener = Yup.object().shape({
  //  questionSet: Yup.array().of(
  //    Yup.object().shape({
  //      value: Yup.lazy((itm) => {
  //        const ppiValue = getFieldValueByFieldName(
  //          itm,
  //          QuestionSetTypes.EXISTING_PPI
  //        )?.value;
  //        const isCorePPI = !!getPPIOptions(ppiValue);
  //        const isConditionalToggled = getFieldValueByFieldName(
  //          itm,
  //          QuestionSetTypes.CONDITIONAL_TOGGLE
  //        );
  //        return Yup.array().of(
  //          Yup.object().shape({
  //            value: Yup.mixed()
  //              //eslint-disable-next-line
  //              .test('required', requiredError, function (val1) {
  //                const { field: fields, value } = this.parent || {};
  //                if (fields.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
  //                  if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
  //                    return true;
  //                  } else if (
  //                    ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL
  //                  ) {
  //                    return true;
  //                  }
  //                  const options = propOr([], 'value', this.parent);
  //                  const minimumOptions = options.length >= 2;
  //                  const hasEmptyValue =
  //                    options.length === 0 ||
  //                    options.some((option) => {
  //                      if (typeof option === 'object' && option !== null) {
  //                        return (
  //                          option.value === undefined ||
  //                          option.value.trim() === ''
  //                        );
  //                      }
  //                      return option === undefined || option.trim() === '';
  //                    });
  //                  if (!isCorePPI && !minimumOptions) {
  //                    return createCustomError(
  //                      this,
  //                      val1,
  //                      atLeastTwoOptionsError
  //                    );
  //                  }
  //                  if (hasEmptyValue) {
  //                    return false;
  //                  }
  //                }
  //                if (fields.includes(QuestionSetTypes.ANSWER_TYPE)) {
  //                  if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
  //                    return true;
  //                  } else if (
  //                    ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL
  //                  ) {
  //                    return true;
  //                  }
  //                  if (!value) {
  //                    return false;
  //                  }
  //                }
  //                if (
  //                  fields.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
  //                  isConditionalToggled &&
  //                  (!value || isEmpty(value))
  //                ) {
  //                  return false;
  //                }
  //                const validationResult = checkCategoryFieldValidity(
  //                  fields,
  //                  value,
  //                  val1,
  //                  this
  //                );
  //                return validationResult !== null ? validationResult : true;
  //              })
  //              //eslint-disable-next-line
  //              .test('duplicate', 'Duplicate', function (val1) {
  //                const { field: fields } = this.parent || {};
  //                if (fields.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
  //                  const options = propOr([], 'value', this.parent);
  //                  const duplicateValues = options
  //                    .map((option) => {
  //                      if (typeof option === 'object' && option !== null) {
  //                        return option.value?.toLowerCase();
  //                      }
  //                      return null;
  //                    })
  //                    .filter(
  //                      (value, index, self) =>
  //                        value && self.indexOf(value) !== index
  //                    );
  //                  const duplicateOption = val1?.find((item) =>
  //                    duplicateValues.includes(item.value?.toLowerCase())
  //                  );
  //                  if (duplicateOption) {
  //                    const duplicateValue = duplicateOption.value;
  //                    return createCustomError(
  //                      this,
  //                      val1,
  //                      `${ duplicateAnswerOption }: ${ duplicateValue }`
  //                    );
  //                  }
  //                }
  //                return true;
  //              }),
  //          })
  //        );
  //      }),
  //    })
  //  ),
});

const survey = Yup.object().shape({
  surveyName: Yup.string()
    .test(
      'custom-validation',
      'Invalid field value',
      function customValidation(value) {
        const error = validateField(value, this, value);
        return error ? this.createError({ message: error.message }) : true;
      }
    )
    .required(requiredError),
  surveyHandle: Yup.string()
    .matches(nonSpecialCharRegex, invalidCharacterError)
    .max(60, 'Character limit exceeded. Maximum 60 characters allowed.')
    .required(requiredError),
  surveyId: Yup.string()
    .uuid(uuidFormatError)
    .required(requiredError)
    .nullable(),
  //questionSet: Yup.array().of(
  //  Yup.object().shape({
  //    value: Yup.lazy((itmVal) => {
  //      const {
  //        existingPPI: { value: ppiValue } = { label: '', value: '' },
  //        answerType: questionType,
  //        conditionalToggle: isConditionalToggled,
  //        categoryChoice,
  //      } = getFieldValuesByFieldNames(itmVal, [
  //        QuestionSetTypes.EXISTING_PPI,
  //        QuestionSetTypes.ANSWER_TYPE,
  //        QuestionSetTypes.CONDITIONAL_TOGGLE,
  //        QuestionSetTypes.CATEGORY_CHOICE,
  //      ]);
  //      const isCorePPI = !!getPPIOptions(ppiValue);

  //      return Yup.array().of(
  //        Yup.object().shape({
  //          value: Yup.mixed()
  //            //eslint-disable-next-line
  //            .test('required', requiredError, function (val1, opt1) {
  //              const { field: fields, value } = this.parent || {};
  //              if (categoryChoice) {
  //                if (
  //                  fields.includes(QuestionSetTypes.CATEGORY_NAME) &&
  //                  isEmptyValue(value)
  //                ) {
  //                  return false;
  //                }
  //                return true;
  //              }

  //              if (fields.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
  //                if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
  //                  return true;
  //                } else if (
  //                  ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL
  //                ) {
  //                  return true;
  //                }
  //                const options = propOr([], 'value', this.parent);
  //                const minimumOptions = options.length >= 2;
  //                const hasEmptyValue =
  //                  options.length === 0 ||
  //                  options.some((option) => {
  //                    if (typeof option === 'object' && option !== null) {
  //                      return (
  //                        option.value === undefined ||
  //                        option.value.trim() === ''
  //                      );
  //                    }
  //                    return option === undefined || option.trim() === '';
  //                  });

  //    if (!minimumOptions && !isCorePPI) {
  //      if (isTextOrHiddenType(questionType)) {
  //        return true;
  //      } else if (!minimumOptions) return false;

  //      return createCustomError(
  //        this,
  //        val1,
  //        atLeastTwoOptionsError
  //      );
  //    }
  //    if (hasEmptyValue && !isCorePPI) {
  //      return false;
  //    }
  //  }

  //              if (fields.includes(QuestionSetTypes.ANSWER_TYPE)) {
  //                if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
  //                  return true;
  //                } else if (
  //                  ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL
  //                ) {
  //                  return true;
  //                }
  //                if (!value) {
  //                  // Exclude non-option requiring PPI
  //                  return false;
  //                }
  //              }

  //              if (
  //                fields.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
  //                isConditionalToggled &&
  //                (!value || isEmpty(value))
  //              ) {
  //                return false;
  //              }

  //              const validationResult = checkCategoryFieldValidity(
  //                fields,
  //                value,
  //                val1,
  //                this
  //              );
  //              return validationResult !== null ? validationResult : true;
  //            })
  //            //eslint-disable-next-line
  //            .test('duplicate', 'Duplicate', function (val1) {
  //              const { field: fields } = this.parent;
  //              if (
  //                fields.includes(QuestionSetTypes.ANSWER_OPTIONS) &&
  //                !isCorePPI
  //              ) {
  //                const options = propOr([], 'value', this.parent);

  //                const duplicateValues = options
  //                  .map((option) => {
  //                    if (typeof option === 'object' && option !== null) {
  //                      return option.value?.toLowerCase();
  //                    }
  //                    return null;
  //                  })
  //                  .filter(
  //                    (value, index, self) =>
  //                      value && self.indexOf(value) !== index
  //                  );

  //                const duplicateOption = val1?.find((item) =>
  //                  duplicateValues.includes(item.value?.toLowerCase())
  //                );

  //                if (duplicateOption) {
  //                  const duplicateValue = duplicateOption.value;

  //                  return createCustomError(
  //                    this,
  //                    val1,
  //                    `${ duplicateAnswerOption }: ${ duplicateValue }`
  //                  );
  //                }
  //              }

  //              return true;
  //            }),
  //        })
  //      );
  //    }),
  //  })
  //),
});

export const validationSchema = {
  overviewCard,
  webOverviewCard,
  webDetailPage,
  mobileOverviewCard,
  mobileReturningCard,
  mobileDetailScreen,
  consentDocument,
  screener,
  survey,
  defaultMedia: defaultAsset,
};

export function convertHandleToTitle(text = '') {
  return text
    ?.split('-')
    .join(' ')
    .toLowerCase()
    .split(' ')
    .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
    .join(' ');
}

export const getFieldLabel = (label, order) =>
  ({
    title: 'Headline',
    subtitle: 'Tagline',
    ctaText: 'CTA Text',
    richText: 'Text',
    backgroundImage: 'Background Image',
    logoImage: 'Logo Image',
    repeatingText: `Section ${ order }`,
    questionSet: `Question ${ order }`,
  }[label] || convertHandleToTitle(label));

export function convertHandleToCardType(text) {
  return (
    text?.charAt(0).toLowerCase() +
    text
      ?.split('-')
      .join(' ')
      .toLowerCase()
      .split(' ')
      .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
      .join(' ')
      .split(' ')
      .join('')
      .slice(1)
  );
}

export const getRepeatingTextFields = (itm) => ({
  field: Math.random(),
  type: itm?.type || 'repeatingText',
  value: itm?.text || '',
  sortOrder: itm?.sortOrder || 1,
  helperText: itm?.helperText || 'You may use rich text formatting',
});

function getNewQuestionSetFields(categoryOptions = [], index, ppiOptions = []) {
  const fieldSetId = getRandomInt();

  const createField = (
    fieldName,
    type,
    label,
    sortOrder,
    value,
    extra = {}
    // eslint-disable-next-line max-params
  ) => {
    return {
      field: `${ fieldName }-${ fieldSetId }`,
      label,
      sortOrder,
      type,
      value,
      ...extra,
    };
  };

  const fields = [
    createField(
      QuestionSetTypes.QUESTION_CATEGORY,
      'select',
      'Category',
      1,
      {},
      {
        isCreatable: true,
        options: categoryOptions,
      }
    ),
    createField(
      QuestionSetTypes.CATEGORY_NAME,
      'text',
      'Category Name',
      2,
      '',
      {
        className: 'categoryFields',
        message,
      }
    ),
    createField(
      QuestionSetTypes.CATEGORY_TITLE,
      'text',
      'Category Title',
      3,
      '',
      {
        className: 'categoryFields',
        message,
      }
    ),
    createField(
      QuestionSetTypes.CATEGORY_SUBTITLE,
      'text',
      'Category Subtitle',
      4,
      '',
      { className: 'categoryFields', message }
    ),
    createField(
      QuestionSetTypes.CATEGORY_TEXT,
      'richText',
      'Category Text',
      5,
      '',
      { className: 'categoryFields', message }
    ),
    createField(
      QuestionSetTypes.BACKGROUND_IMAGE,
      'backgroundImage',

      'Background Image',
      6,
      null,
      { className: 'categoryFields' }
    ),
    createField(
      QuestionSetTypes.CATEGORY_CHOICE,
      'choice',
      'This category does not have questions associated with it',
      7,
      false,
      { disabled: false, className: 'categoryFields' }
    ),
    createField(
      QuestionSetTypes.CONDITIONAL_TOGGLE,
      'toggle',
      'Conditional Toggle',
      8,
      false
    ),
    createField(
      QuestionSetTypes.CONDITIONAL_OPTIONS,
      'select',
      'Select the answer option(s) to display this question',
      9,
      null,
      { options: [], selectionType: 'any', conditionalPPI: '' }
    ),
    createField(
      QuestionSetTypes.QUESTION_LABEL,
      'text',
      'Question',
      10,
      undefined,
      {
        optional: false,
      }
    ),
    createField(
      QuestionSetTypes.EXISTING_PPI,
      'select',
      'Associated Profile Property (PPI)',
      11,
      {},
      { isCreatable: false, options: getFilteredPPIs(ppiOptions) }
    ),
    createField(
      QuestionSetTypes.ANSWER_TYPE,
      'radioSet',
      'Question Type',
      12,
      radioSetOptions[0].value,
      {
        options: radioSetOptions,
      }
    ),
    createField(
      QuestionSetTypes.ANSWER_OPTIONS,
      'tagsInput',
      'Options',
      13,
      []
    ),
  ];

  const fieldsMapObj = fields.reduce((acc, field) => {
    acc.set(field.field.split('-')[0], field);
    return acc;
  }, new Map());

  return new Map([
    [
      fieldSetId,
      {
        field: 'questionSet',
        type: 'questionSet',
        sortOrder: index + 1,
        fieldSetId,
        hideQuestion: false,
        value: fieldsMapObj,
      },
    ],
  ]);
}

export function getFormFields(
  content,
  layoutType = 'defaultMedia',
  ppiOptions = []
) {
  const selectedDataSections = propOr([], 'sections', content);
  const selectedDataQuestions = propOr([], 'questionSet', content);

  const totalQuestions = sum(
    propOr([], 'categories', content).map((cat) => cat.questions.length)
  );

  const categoryOptions = content?.categories
    ? content?.categories.map((cat) => ({
      label: cat.handle,
      value: cat.handle,
    }))
    : [];

  const kebabCaseLayoutType = kebabCase(layoutType);
  const isWebCard = [WEB_OVERVIEW_CARD, WEB_DETAIL_PAGE].includes(
    kebabCaseLayoutType
  );

  const mapObjectQuestionSets = addLastNonConditionalFieldSet(
    transformQuestionSetArray(selectedDataQuestions)
  );

  const elements = (filterBy = '') =>
    [
      {
        field: 'title',
        type: 'text',
        value: content?.title || '',
        maxChars: 40,
      },
      {
        field: 'subtitle',
        type: 'text',
        value: content?.subtitle || '',
        maxChars: 85,
      },
      {
        field: 'text',
        type:
          kebabCaseLayoutType === WEB_OVERVIEW_CARD ? 'textArea' : 'richText',
        value: content?.text || '',
        maxChars: 300,
      },
      {
        field: 'ctaText',
        type: 'text',
        value: content?.ctaText || '',
        maxChars: 15,
      },
      {
        field: 'logoImage',
        type: 'image',
        value: content?.logoImage || null,
        helperText: `Please upload a white logo as a transparent PNG or GIF./Recommended size: ${
          isWebCard ? '480' : '240'
        }px x 168px./Max file size: 1 MB.`,
      },
      {
        field: 'backgroundImage',
        type: 'image',
        value: content?.backgroundImage || null,
        helperText: `Recommended size: ${
          isWebCard
            ? '2010px x 830'
            : kebabCaseLayoutType === MOBILE_DETAIL_SCREEN
              ? '1125px x 1812'
              : '984px x 1392'
        }px./Max file: size 1 MB.`,
      },
      {
        field: 'repeatingText',
        type: 'repeatingText',
        value:
          selectedDataSections.length > 0
            ? selectedDataSections.map((itm) => {
              return getRepeatingTextFields(itm);
            })
            : [getRepeatingTextFields()],
      },
      {
        field: 'screener',
        type: 'screener',
        value:
          mapObjectQuestionSets.size > 0
            ? mapObjectQuestionSets
            : getNewQuestionSetFields(
              categoryOptions,
              mapObjectQuestionSets.size,
              ppiOptions
            ),
      },
      {
        field: 'survey',
        type: 'survey',
        value:
          mapObjectQuestionSets.size > 0
            ? mapObjectQuestionSets
            : getNewQuestionSetFields(
              categoryOptions,
              totalQuestions,
              ppiOptions
            ),
      },
    ].filter((item) => {
      if (filterBy.length) {
        const fieldType = Array.isArray(item) ? item[0]?.field : item?.field;
        const result = filterBy.includes(fieldType);
        return result;
      }
      return true;
    });

  return {
    mobileOverviewCard: {
      elements: elements([
        'title',
        'subtitle',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    mobileReturningCard: {
      elements: elements(['backgroundImage', 'logoImage']),
    },
    mobileDetailScreen: {
      elements: elements([
        'title',
        'subtitle',
        'text',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    webDetailPage: {
      elements: elements([
        'title',
        'subtitle',
        'text',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    webOverviewCard: {
      elements: elements([
        'title',
        'subtitle',
        'text',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    consentDocument: {
      elements: elements(['repeatingText']),
    },
    screener: {
      elements: elements(['screener']),
    },
    survey: {
      elements: elements(['survey']),
    },
    defaultMedia: {
      elements: elements(['title', 'subtitle', 'logoImage', 'backgroundImage']),
    },
  }[layoutType];
}

function getRandomInt() {
  return Math.floor(Math.random() * 9999999);
}

export const hiddenPPIs = [
  ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_BRAND,
  ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_NAME,
  ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_IMAGE,
  ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_TYPE,
];

export const filterPPI = ({ value }) => !hiddenPPIs.includes(value);

export const excludeAnswerOptions = ['ppi-height', 'ppi-weight'];

export const getSurveyScheduleRate = (rate) => {
  if (rate && rate.unit === 'days') {
    if (rate.value === 1) {
      return 'Daily';
    } else if (rate.value === 7) {
      return 'Weekly';
    }
  }
  return 'Once';
};

export const checkNewQuestionHasValues = (formState) => {
  if (formState instanceof Map && formState.size > 0) {
    const lastQuestionSet = [...formState.values()].pop()?.value;

    if (lastQuestionSet instanceof Map) {
      const categoryChoice = lastQuestionSet.get(
        QuestionSetTypes.CATEGORY_CHOICE
      )?.value;

      if (categoryChoice) {
        return true;
      }

      return !!(
        lastQuestionSet.get(QuestionSetTypes.QUESTION_CATEGORY) &&
        lastQuestionSet.get(QuestionSetTypes.QUESTION_LABEL) &&
        lastQuestionSet.get(QuestionSetTypes.EXISTING_PPI)?.value?.value
      );
    }
  }

  return false;
};

export const validateSurveyDates = ({
  isSurveyScheduled,
  surveyStartDate,
  surveyEndDate,
  scheduleRate,
  surveyTime,
  initialStartDate,
  initialEndDate,
}) => {
  const errors = {};

  // Utilizing this to bypass the surveyStartDate & surveyEndDate fields validation since past dates
  // are acceptable and should be  allowed if the schedule exists and has already started and past the currentDate.
  const isStartDateChanged = initialStartDate !== surveyStartDate;
  const isEndDateChanged = initialEndDate !== surveyEndDate;

  // Setting the time to tomorrow midnight for comparison
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 1);
  futureDate.setHours(0, 0, 0, 0);

  const startDate = surveyStartDate ? new Date(surveyStartDate) : null;
  const endDate = surveyEndDate ? new Date(surveyEndDate) : null;

  // Survey Start Date
  if (isSurveyScheduled) {
    if (!startDate) {
      errors.surveyStartDate = 'Required';
    } else if (isStartDateChanged && startDate < futureDate) {
      errors.surveyStartDate =
        'Survey Start Date must be at least one day in the future';
    }
  }

  // Survey End Date
  if (isSurveyScheduled && scheduleRate !== 'Once') {
    if (!endDate) {
      errors.surveyEndDate = 'Required';
    } else if (surveyStartDate && isEndDateChanged) {
      if (endDate <= startDate) {
        errors.surveyEndDate = 'Survey End Date must be after Start Date';
      } else if (endDate < futureDate) {
        errors.surveyEndDate = 'Survey End Date cannot be in the past';
      }
    }
  }

  // Survey Time
  if (isSurveyScheduled) {
    if (!surveyTime) {
      errors.surveyTime = 'Required';
    }
  }

  return errors;
};
