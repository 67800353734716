import React from 'react';
import PropTypes from 'prop-types';
import { AutoApprovedSurvey } from '../AutoApprovedSurvey';
import { AccordionStyled } from '@nike/nike-design-system-components';
import RepeatableFieldSet from '../RepeatableFieldSet';
import { formPropsTypes } from 'lib/propTypes';

const ScreenerForm = ({
  isApproved,
  handleIsApproved,
  createPage,
  layoutType,
  newPPI,
  formProps,
}) => (
  <>
    <AutoApprovedSurvey
      isApproved={ isApproved }
      handleIsApproved={ handleIsApproved }
      isCreatePage={ createPage }
    />
    <AccordionStyled id="screenerQuestionForm" open={ true } size="large">
      <RepeatableFieldSet
        layoutType={ layoutType }
        newPPI={ newPPI }
        isApproved={ isApproved }
        { ...formProps }
      />
    </AccordionStyled>
  </>
);

ScreenerForm.propTypes = {
  createPage: PropTypes.bool,
  formProps: formPropsTypes,
  handleIsApproved: PropTypes.func,
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  newPPI: PropTypes.bool,
};

export default ScreenerForm;
