/* eslint-disable max-statements */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import FieldWrapper from 'components/FieldWrapper';
import styles from './index.module.scss';
import { minAnswerOptionsRequired as helperNoteMessage } from '../AnswerOptionField/utils';
import { arrayOfObjects } from 'lib/propTypes';
import { ChoiceTypes } from 'lib/enums';
import { debounce } from 'lodash';
import { getUpdatedTags } from './utils';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  main: {
    '& >div': {
      backgroundColor: 'white',
      border: 0,
      display: 'revert',
    },
    '& > div.Mui-focused fieldset': {
      borderColor: '#111111',
      borderWidth: '1px !important',
    },
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    position: 'absolute',
    top: 0,
  },
}));

export default function TagsInput({ ...props }) {
  const classes = useStyles();
  const {
    name,
    id,
    onChange,
    placeholder,
    tags,
    title,
    helperText,
    onTagAdd,
    separators,
    errorMessage,
    setFieldValue,
    ...other
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState([]);
  const [{ ...field }, meta, helpers] = useField(name);
  const { setTouched } = helpers;

  const getTagValue = (item) =>
    (typeof item === 'string'
      ? item
      : item?.choiceType === ChoiceTypes.EDITABLE
        ? item?.name || item?.label
        : item?.value);

  const selectedItemVal = useMemo(() => tags ?? [], [tags]);

  useEffect(() => {
    setSelectedItem(selectedItemVal);
  }, [selectedItemVal]);

  // Filtering out successfully added tags(upmIds or athleteIds - in audience form) from the input field
  useEffect(() => {
    if (Array.isArray(onTagAdd) && onTagAdd.length > 0) {
      const onTagAddSet = new Set(onTagAdd);
      setSelectedItem((prevState) =>
        prevState.filter((item) => !onTagAddSet.has(getTagValue(item)))
      );
    }
  }, [onTagAdd]);

  const debounceOnChange = useMemo(
    () =>
      debounce((newSelectedItem) => {
        onChange({ target: { name } }, newSelectedItem);
      }, 100),
    [onChange, name]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (separators.includes(event.key)) {
        event.preventDefault();
        const newVal = event.target.value.trim();
        if (!newVal) return;

        setSelectedItem((prevState) => {
          if (prevState.some((item) => getTagValue(item) === newVal)) {
            setInputValue('');
            return prevState;
          }

          const updatedItems = getUpdatedTags(
            id,
            separators,
            newVal,
            prevState
          );
          setFieldValue(id, updatedItems);
          debounceOnChange(updatedItems);
          return updatedItems;
        });
        setInputValue('');
        setTouched(true);
      } else if (
        event.key === 'Backspace' &&
        !inputValue.length &&
        selectedItem.length
      ) {
        setSelectedItem((prevState) => {
          const updatedItems = prevState.slice(0, prevState.length - 1);
          setFieldValue(id, updatedItems);
          debounceOnChange(updatedItems);
          return updatedItems;
        });

        setTouched(true);
      }
    },
    [id, inputValue, selectedItem, setFieldValue, debounceOnChange]
  );

  const handleDelete = (itemToBeDeleted) => () => {
    setSelectedItem((prevState) => {
      const newItems = prevState.filter(
        (selectedItem) =>
          !(
            itemToBeDeleted?.choiceType === selectedItem?.choiceType &&
            getTagValue(selectedItem) === getTagValue(itemToBeDeleted)
          )
      );
      setFieldValue(id, newItems);
      debounceOnChange(newItems);
      return newItems;
    });

    setTouched(true);
  };

  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);

  const showError = meta?.value?.length > 0 && meta.error;
  const helperTextCombined = (
    <span className={ styles.helperTextContainer }>
      <span>
        { errorMessage ||
          (showError && 'One or more ids are not in correct format.') }
      </span>
      <span>{ helperText }</span>
    </span>
  );

  return (
    <FieldWrapper disabled={ props.disabled }>
      <>
        <label className={ classes.label }>
          { title }
          <span className={ `asterisk ${ showError ? 'hasErrors' : '' }` }>*</span>
        </label>
        <Downshift
          id="downshift-multiple"
          inputValue={ inputValue }
          selectedItem={ selectedItem }
        >
          { ({ getInputProps }) => {
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onKeyDown: handleKeyDown,
              placeholder,
            });
            return (
              <div>
                <FieldWrapper disabled={ props.disabled }>
                  <TextField
                    error={
                      (!helperText.includes(helperNoteMessage) &&
                        !!(meta.touched && errorMessage)) ||
                      !!(meta.touched && showError)
                    }
                    value={ selectedItem
                      .map((item) => getTagValue(item))
                      .join(', ') }
                    className={ classes.main }
                    helperText={ helperTextCombined }
                    multiline={ true }
                    minRows="3"
                    InputProps={{
                      startAdornment: selectedItem.map((item) => (
                        <Chip
                          id={ `chip-${ getTagValue(item) }` }
                          key={
                            item?.choiceType
                              ? `${ getTagValue(item) }-${ item.choiceType }`
                              : getTagValue(item)
                          }
                          tabIndex={ -1 }
                          label={ getTagValue(item) }
                          variant="outlined"
                          size="small"
                          className={ classes.chip }
                          onDelete={ handleDelete(item) }
                        />
                      )),
                      onBlur,
                      onChange: (event) => {
                        handleInputChange(event);
                        onChange(event);
                      },
                      onFocus,
                    }}
                    { ...field }
                    { ...other }
                    { ...inputProps }
                  />
                </FieldWrapper>
              </div>
            );
          } }
        </Downshift>
      </>
    </FieldWrapper>
  );
}
TagsInput.defaultProps = {
  helperText: 'Type or paste options separated by a space, comma or line break',
  onChange() {},
  separators: [' ', ',', 'Enter', 'Tab'],
  tags: [],
};
TagsInput.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onTagAdd: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  selectedTags: PropTypes.func,
  separators: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    arrayOfObjects,
  ]),
  title: PropTypes.string,
};
