/* eslint-disable max-statements */
import { Grid } from '@material-ui/core';
import { Text } from '@nike/eds';
import { FormikProvider, useFormik } from 'formik';
import { ContentStatus, ViewMode } from 'lib/enums';
import { defaultProps, propTypes } from 'lib/react';
import PropTypes from 'prop-types';
import { applyTo, pipe } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import isEmpty from 'lodash/isEmpty';
import {
  CONSENT_FORM,
  SURVEY_FORM,
  adjustFromUTCtoET,
  INQUIRIES,
  SCREENER_FORM,
} from 'lib/layoutUtils';
import {
  convertHandleToTitle,
  getSurveyScheduleRate,
  validateSurveyDates,
  validationSchema,
} from './lib';
import FormContainer from 'components/FormContainer';
import { kebabCase } from 'lodash';
import { NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import { MaskedTextField } from 'components/fields/MaskedTextField';
import { surveyFieldsHelperText, surveyPrefix, trimAndReplace } from '../utils';
import { getRequiredFields } from 'utils';
import { initialValuesTypes } from 'lib/propTypes';
import { useExitConfirmation } from 'lib/hooks/useExitConfirmation';
import PPIModal from './Components/AssetFormPPIModal';
import SurveyIdField from './Components/InquiryNonQuestionSetFields/InquirySurveyIdField';
import SurveyTextField from './Components/InquiryNonQuestionSetFields/InquirySurveyTextField';
import RenderFormContent from './RenderFormContent';
import ScheduleSurvey from './Components/ScheduleSurvey';
import AssetFormButtonGroup from './Components/AssetFormButtonGroup';
import useSetInquiryVariables from 'lib/hooks/useSetInquiryVariables';
import useSetInquiryErrors from 'lib/hooks/useSetInquiryErrors';
import useSetNonQuestionSetFields from 'lib/hooks/useSetNonQuestionSetFields';

export default applyTo(
  // eslint-disable-next-line complexity
  ({
    submitText = 'Save Media',
    draftExist,
    initialValues,
    handleSubmit,
    handleWebPreview,
    handleSave,
    layoutType,
    viewMode,
    fields,
    setFormState,
    formState,
    initiativeHandle,
    cardStatus,
    isApproved,
    currentData,
    handleIsApproved,
  }) => {
    const disabled = viewMode === ViewMode.VIEW;
    const createPage = viewMode === ViewMode.CREATE;
    const showConsentDocument = kebabCase(layoutType) === CONSENT_FORM;
    const isInquiry = INQUIRIES.includes(layoutType);
    const { surveyId = null } = currentData?.attributes || {};
    const {
      schedule = null,
      handle: surveyHandle = null,
      name: surveyName = null,
    } = currentData || {};
    const { startDate, endDate, rate } = schedule || {};

    const [isSurveyScheduled, setIsSurveyScheduled] = useState(false);
    const [newPPI, setNewPPI] = useState({});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    if (isInquiry) useSetInquiryVariables();

    // eslint-disable-next-line no-underscore-dangle
    const _initialValues =
      layoutType === SURVEY_FORM
        ? {
          surveyId: surveyId ?? undefined,
          surveyName: surveyName ?? undefined,
          surveyHandle: surveyHandle ?? undefined,
          surveyStartDate: startDate
            ? adjustFromUTCtoET(startDate)?.split('T')[0]
            : undefined,
          surveyEndDate:
              getSurveyScheduleRate(rate) === 'Once'
                ? null
                : endDate
                  ? adjustFromUTCtoET(endDate)?.split('T')[0]
                  : undefined,
          surveyTime: startDate ? startDate?.split('T')[1] : undefined,
          scheduleRate: getSurveyScheduleRate(rate) ?? undefined,
          isSurveyScheduled: !isEmpty(schedule),
          questionSet:
              initialValues instanceof Map ? initialValues : new Map(),
        }
        : layoutType === SCREENER_FORM
          ? {
            questionSet:
              initialValues instanceof Map ? initialValues : new Map(),
          }
          : initialValues;

    const getValidationSchema = validationSchema[layoutType];

    const formikData = {
      initialValues: _initialValues,
      validationSchema: getValidationSchema,
      onSubmit: (values, { setSubmitting }) => {
        handleSubmit(values, { setSubmitting });
      },
      displayName: 'AssetForm',
    };

    //Passing in separate formik data for static and dynamic validation
    const formikObject = isInquiry
      ? formikData
      : { ...formikData, enableReinitialize: true };

    const formik = useFormik(formikObject);
    const requiredFields = getRequiredFields(getValidationSchema);

    useExitConfirmation(hasUnsavedChanges);

    const {
      values,
      errors,
      dirty,
      isSubmitting,
      touched,
      setFieldError,
      setFieldValue,
      setFieldTouched,
      validateForm,
      handleChange,
      setTouched,
      setSubmitting,
    } = formik;

    const hasErrors = !isEmpty(errors);
    const { inquiryErrors } = useSetInquiryErrors(
      formState,
      touched,
      isInquiry
    );

    const formProps = {
      fields,
      touched,
      setTouched,
      setFieldTouched,
      setFieldError,
      setFieldValue,
      setFormState,
      formState,
      errors,
      inquiryErrors,
      disabled,
      initiativeHandle,
      requiredFields,
      setHasUnsavedChanges,
    };

    useEffect(() => {
      if (
        (values?.logoImage !== '' && values?.logoImage !== undefined) ||
        (values?.backgroundImage !== '' &&
          values?.backgroundImage !== undefined)
      ) {
        validateForm();
      }
    }, [values?.logoImage, values?.backgroundImage]);

    useSetNonQuestionSetFields({
      layoutType,
      createPage,
      surveyName,
      surveyHandle,
      surveyId,
      schedule,
      startDate,
      endDate,
      rate,
      values,
      touched,
      setFieldValue,
    });

    const handleFormSubmit = useCallback(
      (event) => {
        event.preventDefault();
        setHasUnsavedChanges(false);
        handleSubmit(values, { setSubmitting });
      },
      [values, setSubmitting, handleSubmit]
    );

    const handleFieldChange = useCallback((event) => {
      const key = event?.target?.id;
      let value = event?.target?.value?.trim();
      if (key === 'surveyHandle') {
        value = trimAndReplace(value);
      }
      setFieldValue(key, value);
      setFieldTouched(key, true);
      if (!isInquiry) {
        setFormState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
      // Invoking the original handleChange func from Formik
      handleChange(event);
      setHasUnsavedChanges(true);
    }, []);

    const getSurveyHandle = () => {
      if (values?.surveyHandle) return values.surveyHandle;
      if (!createPage) return currentData?.handle;

      return touched.surveyHandle
        ? values.surveyHandle
        : trimAndReplace(values?.surveyName ?? '');
    };

    const { surveyStartDate, surveyEndDate, scheduleRate, surveyTime } =
      values ?? {};
    const { surveyStartDate: initialStartDate, surveyEndDate: initialEndDate } =
      _initialValues || {};

    const scheduleSurveyErrors = useMemo(() => {
      if (!isInquiry) return null;
      return validateSurveyDates({
        isSurveyScheduled,
        initialStartDate,
        initialEndDate,
        surveyStartDate,
        surveyEndDate,
        surveyTime,
        scheduleRate,
        schedule,
      });
    }, [
      isInquiry,
      values,
      isSurveyScheduled,
      initialStartDate,
      initialEndDate,
      surveyStartDate,
      surveyEndDate,
      surveyTime,
      scheduleRate,
      schedule,
    ]);

    // eslint-disable-next-line complexity
    const formButtonDisabled = useMemo(() => {
      return (
        isEmpty(values) ||
        (isInquiry && (isEmpty(inquiryErrors) ? isEmpty(touched) : true)) ||
        hasErrors ||
        //(isInquiry
        //  ? checkNewQuestionHasValues(formState)
        //    ? isEmpty(inquiryErrors)
        //      ? isEmpty(touched)
        //      : true
        //    : true
        //  : false) ||
        !isEmpty(scheduleSurveyErrors) ||
        (isInquiry ? isEmpty(touched) : !dirty && isEmpty(touched)) ||
        (showConsentDocument
          ? formState?.sections?.updatedFields?.slice(-1)[0]?.value === ''
          : false)
      );
    }, [
      values,
      hasErrors,
      inquiryErrors,
      touched,
      dirty,
      isInquiry,
      showConsentDocument,
      formState,
    ]);

    return (
      <FormikProvider value={ formik }>
        <NikeDesignSystemProvider>
          { /* PPIModal is for creating new PPI in inquiry forms */ }
          { INQUIRIES.includes(layoutType) && (
            <PPIModal
              questionSet={ values?.questionSet }
              layoutType={ layoutType }
              setNewPPI={ setNewPPI }
            />
          ) }
          <FormContainer>
            <form
              method="post"
              onSubmit={ handleFormSubmit }
              encType="multipart/form-data"
              className={ styles.form }
              data-testid="add-edit-form-view"
            >
              <Grid
                container={ true }
                direction="column"
                spacing={ 2 }
                justifyContent="space-evenly"
              >
                { /*Survey Specific Non Question Set Fields*/ }
                { layoutType === SURVEY_FORM && (
                  <Grid
                    container={ true }
                    direction="row"
                    spacing={ 3 }
                    className={ styles.surveyGrid }
                  >
                    <SurveyTextField
                      id="surveyName"
                      name="surveyName"
                      label="Survey Name"
                      isRequiredField={ requiredFields.surveyName }
                      errorText={ errors?.surveyName }
                      isTextFieldTouched={ touched?.surveyName }
                      handleFieldChange={ handleFieldChange }
                      disabled={ disabled }
                      initialValue={ currentData?.name }
                    />
                    <Grid
                      item={ true }
                      xs={ 12 }
                      className={ styles.surveyHandleGrid }
                    >
                      <MaskedTextField
                        id="surveyHandle"
                        name="surveyHandle"
                        label="Survey Handle"
                        onChange={ handleFieldChange }
                        handleType={ surveyPrefix }
                        createPage={ createPage }
                        helperText={ surveyFieldsHelperText }
                        value={ getSurveyHandle() }
                        disabled={ !createPage || disabled }
                        className={ !createPage ? styles.greyOut : '' }
                        required={ requiredFields.surveyHandle }
                      />
                    </Grid>
                    <SurveyIdField
                      id="surveyId"
                      name="surveyId"
                      label="Survey Id"
                      value={ values?.surveyId }
                      initialValue={ surveyId }
                      isRequiredField={ requiredFields.surveyId }
                      errorText={ errors?.surveyId }
                      isTextFieldTouched={ touched?.surveyId }
                      handleFieldChange={ handleFieldChange }
                      disabled={ disabled }
                      createPage={ createPage }
                    />
                    <ScheduleSurvey
                      isSurveyScheduled={ isSurveyScheduled }
                      setIsSurveyScheduled={ setIsSurveyScheduled }
                      surveyStartDate={ surveyStartDate }
                      scheduleRate={ scheduleRate }
                      setFieldTouched={ setFieldTouched }
                      setFieldValue={ setFieldValue }
                      setHasUnsavedChanges={ setHasUnsavedChanges }
                      schedule={ schedule }
                      scheduleSurveyErrors={ scheduleSurveyErrors }
                      handleFieldChange={ handleFieldChange }
                    />
                  </Grid>
                ) }
                <Grid container={ true } direction="row" spacing={ 3 }>
                  <fieldset
                    disabled={ !!disabled }
                    data-testid="asset-field-set"
                    id="asset-form"
                    style={{ width: '100%' }}
                  >
                    <input
                      type="hidden"
                      name="layoutId"
                      value={ values?.layoutId }
                    />
                    <Grid
                      container={ true }
                      direction="row"
                      spacing={ 0 }
                      className={ styles.gridTitle }
                    >
                      <Grid item={ true } xs={ 12 }>
                        <Text font="title-6" as="h6">
                          { convertHandleToTitle(values?.contentType) }
                        </Text>
                      </Grid>
                    </Grid>
                    { /*Renders all the form fields content for the respective layoutType */ }
                    <RenderFormContent
                      layoutType={ layoutType }
                      showConsentDocument={ showConsentDocument }
                      isApproved={ isApproved }
                      handleIsApproved={ handleIsApproved }
                      createPage={ createPage }
                      newPPI={ newPPI }
                      formProps={ formProps }
                      handleFieldChange={ handleFieldChange }
                      isInquiry={ isInquiry }
                      viewMode={ viewMode }
                      requiredFields={ requiredFields }
                    />
                  </fieldset>
                </Grid>
                <AssetFormButtonGroup
                  cardStatus={ cardStatus }
                  dirty={ dirty }
                  disabled={ disabled }
                  draftExist={ draftExist }
                  formButtonDisabled={ formButtonDisabled }
                  handleSave={ handleSave }
                  hasErrors={ hasErrors }
                  initiativeHandle={ initiativeHandle }
                  isSubmitting={ isSubmitting }
                  layoutType={ layoutType }
                  submitText={ submitText }
                  handleWebPreview={ handleWebPreview }
                  values={ values }
                />
              </Grid>
            </form>
          </FormContainer>
        </NikeDesignSystemProvider>
      </FormikProvider>
    );
  },
  pipe(
    propTypes({
      cardStatus: PropTypes.oneOf(Object.values(ContentStatus)),
      draftExist: PropTypes.bool,
      handleSubmit: PropTypes.func,
      handleWebPreview: PropTypes.func,
      initialValues: PropTypes.oneOfType([
        initialValuesTypes.initialInquiryData,
        initialValuesTypes.initialConsentData,
        initialValuesTypes.initialContentData,
      ]),
      layoutType: PropTypes.string,
      fields: PropTypes.instanceOf(Map),
    }),
    defaultProps({
      submitText: 'Save Media',
      handleSubmit: () => {},
      handleWebPreview: null,
      setFormState: () => {},
    })
  )
);
