import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../index.module.scss';
import { selectionTypeOptions } from 'components/forms/utils';
import { debounce } from 'lodash';

export const ConditionalSelectSubtitle = ({
  fieldName,
  selectionType,
  onChange,
  disabled,
}) => {
  const [selectVal, setSelectVal] = useState(selectionType);
  const debounceOnChange = useMemo(
    () => debounce((event) => onChange(event), 100),
    [onChange]
  );

  const handleSelectFieldChange = useCallback(
    (event) => {
      setSelectVal(event.target.value);
      debounceOnChange(event);
    },
    [debounceOnChange]
  );

  useEffect(() => {
    if (disabled && selectionType !== 'any') {
      setSelectVal('any');
      debounceOnChange({
        target: {
          name: fieldName,
          value: 'any',
        },
      });
    }
  }, [disabled, selectionType, debounceOnChange, fieldName]);

  return (
    <div className={ styles.conditionalSelectSubtitle }>
      <span>{ 'This question will only appear when ' }</span>
      <select
        id={ fieldName }
        name={ fieldName }
        onChange={ handleSelectFieldChange }
        value={ selectVal }
        data-testid="selectionType"
        disabled={ disabled }
      >
        { selectionTypeOptions.map((option) => (
          <option key={ option.label } value={ option.value }>
            { option.label }
          </option>
        )) }
      </select>
      <span>{ ' of the below answers are selected' }</span>
    </div>
  );
};

ConditionalSelectSubtitle.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  selectionType: PropTypes.string,
};
