// modules
import React, { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';

// aliased

// local
import NotFound from 'components/NotFound';
import Create from './components/Create';
import Edit from './components/Edit';
import AudienceEditView from 'views/Audience/components/Edit';
import DetailView from './components/DetailView';
import ExperienceListView from '../Experiences/components/List';
import Audiences from '../Audiences';
import withRolePermissions from 'lib/hooks/withRolePermissions';
// import Delete from './components/Delete';

const Polls = () => {
  const ProtectedAudiences = useMemo(() => withRolePermissions(Audiences), []);
  // not wrapped in a container because the parent already is.
  return (
    <Switch>
      <Route
        path="/:entity/polls"
        exact={ true }
        component={ ExperienceListView }
      />
      <Route
        path="/:entity/:initiativeType"
        exact={ true }
        component={ ExperienceListView }
      />
      <Route
        path="/:entity/:initiativeType/create"
        exact={ true }
        component={ Create }
      />

      { /* <Route path="/initiative/polls/create" exact={ true } component={ Create } />
      <Route
        path="/initiative/activity-survey/create"
        exact={ true }
        component={ Create }
      /> */ }
      { /*
      <Route
        path="/initiative/:initiativeType"
        exact={ true }
        component={ ExperienceListView }
      /> */ }
      <Route
        path="/:entity/:initiativeType/:handle/audiences"
        exact={ true }
        component={ ProtectedAudiences }
      />
      <Route
        path="/:entity/:initiativeType/:initiativeHandle/audience/edit/:id"
        exact={ true }
        component={ AudienceEditView }
      />
      <Route
        path="/:entity/:initiativeType/edit/:id"
        exact={ true }
        component={ Edit }
      />
      <Route
        path="/:entity/:initiativeType/:viewMode/:id"
        exact={ true }
        component={ DetailView }
      />

      { /* <Route
        path="/initiative/polls/edit/:handle"
        exact={ true }
        component={ Edit }
      />
      <Route
        path="/initiative/:initiativeType/edit/:handle"
        exact={ true }
        component={ Edit }
      /> */ }
      { /** Using this to quickly clean up test polls. */ }
      { /* <Route
        path="/initiative/polls/delete/:handle"
        exact={ true }
        component={ Delete }
      /> */ }
      { /* <Route
        path="/initiative/polls/:handle"
        exact={ true }
        component={ DetailView }
      /> */ }

      <Route
        path="/initiative/:initiativeType/:id"
        exact={ true }
        component={ DetailView }
      />
      <Route component={ NotFound } />
    </Switch>
  );
};

export default Polls;
