import { Link, Text } from '@nike/eds';
import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import { removeFieldSetFromMap } from './RepeatableFieldsetUtils';
import PropTypes from 'prop-types';

export const DetailsTitle = ({
  fieldSetId,
  cardLabel,
  setFormState,
  setFieldTouched,
  setFieldValue,
  shouldShowDeleteLink,
}) => {
  const [isHovered, setIsHovered] = useState(null);

  const handleHover = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleDeleteClick = useCallback(
    (event) => {
      event.preventDefault();
      setTimeout(() => {
        removeFieldSetFromMap(
          setFormState,
          fieldSetId,
          setFieldTouched,
          setFieldValue
        );
      }, 500);
    },
    [fieldSetId]
  );

  return (
    <Text
      as="h3"
      className={ styles.detailsTitle }
      id={ fieldSetId }
      onMouseEnter={ handleHover }
      onMouseLeave={ handleMouseLeave }
    >
      { cardLabel }
      <Link
        as="button"
        font="subtitle-2"
        onClick={ handleDeleteClick }
        variant="secondary"
      >
        { isHovered && shouldShowDeleteLink && '[ Delete item ]' }
      </Link>
    </Text>
  );
};
DetailsTitle.propTypes = {
  cardLabel: PropTypes.string,
  fieldSetId: PropTypes.number,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFormState: PropTypes.func,
  shouldShowDeleteLink: PropTypes.bool,
};
