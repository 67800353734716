import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Button, ButtonGroup, Spinner } from '@nike/eds';
import styles from './index.module.scss';
import PreviewInANewWinBtn from 'views/Initiative/Experiences/components/ManageAssets/components/PreviewNewWinBtn';
import { kebabCase } from 'lodash';
import {
  DEFAULT_MEDIA,
  getAssetType,
  SCREENER_FORM,
  SURVEY_FORM,
  WEB_DETAIL_PAGE,
  WEB_OVERVIEW_CARD,
} from 'lib/layoutUtils';
import { ContentStatus } from 'lib/enums';
import { userInitiativePermissions } from 'components/RBAC';
import { getEntitySubtype } from 'lib/auth';
import { useHistory, useParams } from 'react-router-dom';

// eslint-disable-next-line complexity, max-statements
const AssetFormButtonGroup = ({
  layoutType,
  initiativeHandle,
  disabled,
  hasErrors,
  isSubmitting,
  dirty,
  formButtonDisabled,
  handleSave,
  submitText,
  draftExist,
  cardStatus,
  handleWebPreview,
  values,
}) => {
  const history = useHistory();
  const { initiativeType } = useParams();
  const experienceUrl = `/initiative/${ initiativeType }`;
  const kebabLayoutType = kebabCase(layoutType);
  const showPreviewButton = ![
    DEFAULT_MEDIA,
    SCREENER_FORM,
    SURVEY_FORM,
  ].includes(kebabLayoutType);
  const entityType = getEntitySubtype(kebabLayoutType);
  const canEdit = userInitiativePermissions(
    initiativeHandle,
    entityType
  ).includes('EDIT');

  const handleCancel = useCallback(() => {
    const assetUrl = `${ experienceUrl }/${ initiativeHandle }/assets`;
    history.push({
      pathname: assetUrl,
      state: {
        assetType: getAssetType(layoutType),
      },
    });
  }, [initiativeHandle, layoutType]);

  const handleEditRoute = useCallback(() => {
    const editUrl = `${ experienceUrl }/${ initiativeHandle }/assets/edit/${ kebabCase(
      layoutType
    ) }`;
    const layoutId = history?.location?.state?.layoutId;
    const draftExist = history?.location?.state?.draftExist;
    history.push({
      pathname: editUrl,
      state: {
        layoutId,
        disabled: true,
        draftExist,
      },
    });
  }, [initiativeHandle]);

  const handleWebPreviewSubmit = useCallback(
    (previewInNewWindow, entityType) => {
      handleWebPreview(previewInNewWindow, entityType, values);
    },
    [handleWebPreview, values]
  );

  return (
    <Grid container={ true } direction="row" spacing={ 3 }>
      <ButtonGroup className={ styles.btns }>
        <Button variant="secondary" onClick={ handleCancel } type="button">
          Cancel
        </Button>
        { !disabled && typeof handleWebPreview === 'function' && (
          <PreviewInANewWinBtn
            disabled={ formButtonDisabled }
            handleSave={ handleWebPreviewSubmit }
            layoutType={ layoutType }
            text="Preview on the web"
          />
        ) }
        { !disabled ? (
          <ButtonGroup>
            { showPreviewButton &&
              [WEB_DETAIL_PAGE, WEB_OVERVIEW_CARD].includes(
                kebabLayoutType
              ) && (
              <PreviewInANewWinBtn
                disabled={ hasErrors || isSubmitting }
                handleSave={ handleSave }
                layoutType={ layoutType }
              />
            ) }
            { showPreviewButton && (
              <Button
                disabled={ hasErrors || isSubmitting || !dirty }
                type="button"
                variant="primary"
                onClick={ handleSave }
              >
                Preview
              </Button>
            ) }
            <Button
              data-testid="submit-button"
              variant="primary"
              disabled={ formButtonDisabled }
              type="submit"
            >
              { isSubmitting ? (
                <div className="eds--dark">
                  <Spinner />
                </div>
              ) : (
                submitText
              ) }
            </Button>
          </ButtonGroup>
        ) : (
          !draftExist &&
          canEdit && (
            <ButtonGroup>
              { [WEB_DETAIL_PAGE, WEB_OVERVIEW_CARD].includes(
                kebabLayoutType
              ) && (
                <PreviewInANewWinBtn
                  disabled={ draftExist }
                  handleSave={ handleSave }
                  layoutType={ layoutType }
                />
              ) }
              { /* Remove edit on Previous version consents and
             show only on Published when no draft exist */ }
              { cardStatus === ContentStatus.PUBLISHED && (
                <Button
                  disabled={ draftExist }
                  data-testid="editRoute"
                  variant="secondary"
                  onClick={ handleEditRoute }
                >
                  Edit
                </Button>
              ) }
            </ButtonGroup>
          )
        ) }
      </ButtonGroup>
    </Grid>
  );
};

AssetFormButtonGroup.propTypes = {
  cardStatus: PropTypes.string,
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  draftExist: PropTypes.bool,
  formButtonDisabled: PropTypes.bool,
  handleSave: PropTypes.func,
  handleWebPreview: PropTypes.func,
  hasErrors: PropTypes.bool,
  initiativeHandle: PropTypes.string,
  isSubmitting: PropTypes.bool,
  layoutType: PropTypes.string,
  submitText: PropTypes.string,
  values: PropTypes.shape({}),
};

export default AssetFormButtonGroup;
