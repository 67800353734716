import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import { Field } from 'formik';
import { Checkbox } from '@nike/eds';

export const ChoiceComponent = memo(
  ({
    fieldId,
    fieldName,
    fieldLabel,
    fieldValue,
    className,
    onChange,
    disabled,
  }) => {
    return (
      <Field key={ fieldId } name={ fieldName }>
        { () => (
          <FieldWrapper disabled={ disabled } className={ className }>
            <Checkbox
              id={ fieldId }
              name={ fieldName }
              label={ fieldLabel }
              checked={ fieldValue }
              onChange={ onChange }
              disabled={ disabled }
            />
          </FieldWrapper>
        ) }
      </Field>
    );
  },
  (prevProps, nextProps) =>
    prevProps.fieldValue === nextProps.fieldValue &&
    prevProps.className === nextProps.className
);

ChoiceComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.bool,
  onChange: PropTypes.func,
};
