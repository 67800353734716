/* eslint-disable max-statements */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styles from './index.module.scss';
import { excludeAnswerOptions, getPPIOptions } from './lib';
import { TextField } from 'components/fields';
import { AccordionPanelStyled } from '@nike/nike-design-system-components';
import Fields from './Fields';
import { fieldsTypes, formTypes } from 'lib/propTypes';
import { Text } from '@nike/eds';
import {
  QuestionSetTypes,
  getFieldValuesByQuestionSetAndNames,
} from './RepeatableFieldsetUtils';
import { DetailsTitle } from './DetailsTitle';
import { isEmpty } from 'lodash';

const InquiryQuestion = (props) => {
  const {
    questionIndex,
    errors,
    fieldSet,
    setFormState,
    disabled,
    handleChange,
    layoutType,
    isApproved,
    requiredFields,
    setFieldTouched,
    setFieldValue,
    shouldShowDeleteLink,
  } = props;

  const [answerFormat, setAnswerFormat] = useState();
  const fieldSetId = fieldSet.fieldSetId;
  const questionSetFields = fieldSet?.value;
  const {
    questionLabel,
    conditionalToggle: hasConditionalToggled,
    questionCategory: { value: categoryLabel } = {},
    existingPPI: { value: existingPPI } = {},
  } = getFieldValuesByQuestionSetAndNames(questionSetFields, [
    QuestionSetTypes.QUESTION_LABEL,
    QuestionSetTypes.QUESTION_CATEGORY,
    QuestionSetTypes.EXISTING_PPI,
    QuestionSetTypes.CONDITIONAL_TOGGLE,
  ]);

  const isCorePPI = !!getPPIOptions(existingPPI);
  useEffect(() => {
    if (existingPPI) {
      setAnswerFormat(isCorePPI ? 'ppi-set' : 'enum');
    }
  }, [existingPPI, isCorePPI]);

  const dynamicQuestionLabel = fieldSet.dynamicQuestionLabel ?? null;
  const choiceConditionMet =
    questionSetFields?.get(QuestionSetTypes.CATEGORY_CHOICE)?.value === true;

  const cardLabel = useMemo(() => {
    if (!isEmpty(questionLabel)) {
      return hasConditionalToggled
        ? questionLabel
        : !isEmpty(categoryLabel)
          ? `${ categoryLabel }: ${ questionLabel }`
          : dynamicQuestionLabel || '';
    }
    return dynamicQuestionLabel || '';
  }, [
    questionLabel,
    categoryLabel,
    dynamicQuestionLabel,
    hasConditionalToggled,
  ]);

  const fields = [...questionSetFields.values()];

  return (
    <AccordionPanelStyled
      key={ `question-${ fieldSetId }` }
      id={ `question-${ fieldSetId }` }
      open={ true }
      className={ `${ styles.accordionPanel } ${
        !hasConditionalToggled ? styles.accordionPanelMargin : ''
      }` }
      detailsTitle={
        <DetailsTitle
          cardLabel={ cardLabel }
          questionIndex={ questionIndex }
          fieldSetId={ fieldSetId }
          disabled={ disabled }
          fieldSet={ fieldSet }
          shouldShowDeleteLink={ shouldShowDeleteLink }
          setFormState={ setFormState }
          setFieldTouched={ setFieldTouched }
          setFieldValue={ setFieldValue }
        />
      }
      size="large"
      animatable={ true }
    >
      <Grid
        container={ true }
        direction="row"
        spacing={ 0 }
        className={ styles.fieldSetGrid }
      >
        <Grid container={ true } direction="row">
          <Grid item={ true } xs={ 12 }>
            <Fields
              disabled={ disabled }
              fieldSet={ fieldSet }
              handleChange={ handleChange }
              errors={ errors }
              fields={ fields.filter((val) => val.sortOrder < 12) }
              layoutType={ layoutType }
              isApproved={ isApproved }
              choiceConditionMet={ choiceConditionMet }
              disableCategoryChoice={ !!questionLabel }
              requiredFields={ requiredFields }
            />
          </Grid>

          { answerFormat === 'enum' && (
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <Fields
                  choiceConditionMet={ choiceConditionMet }
                  disabled={ disabled }
                  disableCategoryChoice={ !!questionLabel }
                  errors={ errors }
                  fieldSet={ fieldSet }
                  fields={ fields.filter((val) => val.sortOrder >= 12) }
                  handleChange={ handleChange }
                  isApproved={ isApproved }
                  layoutType={ layoutType }
                  existingPPI={ existingPPI }
                  isCorePPI={ isCorePPI }
                />
              </Grid>
            </Grid>
          ) }
          { answerFormat === 'ppi-set' &&
            !excludeAnswerOptions.includes(existingPPI) && (
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <Fields
                  disabled={ disabled }
                  fieldSet={ fieldSet }
                  handleChange={ handleChange }
                  errors={ errors }
                  fields={ fields.filter((val) => val.sortOrder >= 12) }
                  existingPPI={ existingPPI }
                  isCorePPI={ isCorePPI }
                  layoutType={ layoutType }
                  isApproved={ isApproved }
                  choiceConditionMet={ choiceConditionMet }
                  disableCategoryChoice={ !!questionLabel }
                />
              </Grid>
            </Grid>
          ) }
          { answerFormat === 'number' && (
            <Grid item={ true } xs={ 12 }>
              <Grid xs={ 12 }>
                <Text font="title-6" as="h6">
                  Answer Numeric Range
                </Text>
              </Grid>
              <Grid
                sm={ true }
                container={ true }
                xs={ 12 }
                className={ styles.rowWrapper }
              >
                <Grid sm={ true } item={ true }>
                  <TextField name="answerMin" type="number" label="Min" />
                </Grid>
                <Text
                  font="body-1"
                  style={{ float: 'left', padding: '50px 30px' }}
                >
                  to
                </Text>
                <Grid sm={ true } item={ true }>
                  <TextField name="answerMax" type="number" label="Max" />
                </Grid>
                <Grid sm={ true } item={ true }>
                  <Text
                    font="body-1"
                    style={{ float: 'left', padding: '50px 30px' }}
                  >
                    by
                  </Text>
                </Grid>

                <Grid sm={ true } item={ true }>
                  <TextField name="iterateBy" type="number" label="Iteration" />
                </Grid>
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <TextField name="answerUnits" label="Answer Units" />
              </Grid>
            </Grid>
          ) }
          { answerFormat === 'pattern' && (
            <Grid item={ true } xs={ 12 }>
              <TextField
                name="answerPattern"
                label="Answer Pattern Requirements"
              />
            </Grid>
          ) }
        </Grid>
      </Grid>
    </AccordionPanelStyled>
  );
};

InquiryQuestion.propTypes = {
  errors: PropTypes.shape({}),
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  questionIndex: PropTypes.number,
  touched: PropTypes.shape({}),
  ...formTypes,
  fieldSet: fieldsTypes.inquiryData,
};

export default InquiryQuestion;
