import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'components/DataTable';
import {
  useFetchEventJobByIdQuery,
  useFetchEventJobsQuery,
  useRetryByJobIdMutation,
} from 'features/adminApi/endpoints/eventJobs';
import Loading from 'components/Loading';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { columns, devUrl, getRows } from './lib';
import SearchBar from '../SearchBar';
import { isEmpty } from 'lodash';

// eslint-disable-next-line max-statements
export const EventJobs = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const {
    data: eventJobs,
    isLoading,
    isError,
    error,
  } = useFetchEventJobsQuery();
  const evtJobs = getRows(eventJobs);
  const [searchValue, setSearchValue] = useState('');
  const [submit, setSubmit] = useState(false);
  const [filteredRows, setFilteredRows] = useState(evtJobs);

  const [retryByJobId, retryByJobIdResult] = useRetryByJobIdMutation();

  const actions = [
    {
      key: 'jobId',
      label: 'view',
      url: devUrl,
      action: 'create',
    },
    {
      key: 'jobId',
      label: 'retry',
      url: '',
      action: 'retry',
      retryByJobId,
    },
  ];

  const {
    data: eventJob,
    isFetching,
    isError: isJobIdError,
  } = useFetchEventJobByIdQuery(searchValue, {
    skip: !submit || searchValue === '',
  });

  // eslint-disable-next-line max-statements
  useEffect(() => {
    if (searchValue.trim() === '') {
      const jobs = [...evtJobs];

      if (
        !isEmpty(eventJob) &&
        !jobs.some((job) => job.jobId === eventJob.jobId)
      ) {
        jobs.unshift(eventJob);
      }
      setFilteredRows(jobs);
      return;
    }
    const filteredRow = evtJobs.filter(
      (row) => row.jobId.toLowerCase() === searchValue.toLowerCase()
    );
    if (filteredRow.length) setFilteredRows(filteredRow);
    if (filteredRow.length === 0 && submit && !isFetching) {
      setSubmit((prevState) => !prevState);
      if (!isJobIdError) {
        if (!isEmpty(eventJob)) {
          setFilteredRows(getRows([eventJob]));
        }
      } else {
        addToast('Job Id is not found', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }, [eventJobs, searchValue, submit, eventJob, isJobIdError, isFetching]);

  useEffect(() => {
    if (isError && !isLoading) {
      setFilteredRows([]);
      addToast(error?.error || 'An error occurred', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError, isLoading, error]);

  useEffect(() => {
    if (retryByJobIdResult?.isSuccess) {
      addToast('Check back in 5 minutes for an updated status', {
        appearance: 'info',
        autoDismiss: true,
      });
    } else if (retryByJobIdResult?.isError) {
      addToast('Failed to retry job', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [retryByJobIdResult?.isSuccess, retryByJobIdResult?.isError]);

  const handleRowClick = useCallback(
    ({ colDef: { field }, row: { jobId } }) => {
      if (field !== 'actions' && jobId) {
        history.push(`${ devUrl }/${ jobId }`);
      }
    },
    [history]
  );

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleSearchSubmit = useCallback(() => {
    setSubmit((prevState) => !prevState);
  }, [searchValue]);

  const initialFilter = useMemo(
    () => ({
      field: 'status',
      operator: 'equals',
      value: 'failed',
    }),
    []
  );

  return (
    <div data-testid="event-jobs-container">
      { isLoading ? (
        <Loading text="Fetching Event-jobs" />
      ) : (
        <div>
          <SearchBar
            jobSearch={ true }
            searchValue={ searchValue }
            handleSearchChange={ handleSearchChange }
            handleSearchSubmit={ handleSearchSubmit }
            disabled={ !searchValue.length }
            isFetching={ isFetching }
          />
          {
            <DataTable
              rows={ filteredRows || [] }
              columns={ columns }
              actions={ actions }
              heading="Event Jobs"
              loading={ false }
              muiGridProps={{
                getRowId: (row) => row?.jobId,
              }}
              handleCellClick={ handleRowClick }
              initialFilter={ initialFilter }
            />
          }
        </div>
      ) }
    </div>
  );
};
