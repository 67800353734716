import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getReturnPath } from '../components/AssetCard/utils';
import { useToasts } from 'react-toast-notifications';
import { convertHandleToTitle } from 'components/forms/Assets/lib';
import { getAssetType } from 'lib/layoutUtils';

const useHandleContentUpdate = ({
  patchResult,
  postResult,
  initiativeType,
  id,
  viewMode,
  layoutType,
  currentData,
  draftExist,
  layoutId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToasts();

  useEffect(() => {
    if (patchResult?.error?.data?.errorMessage) {
      addToast(patchResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    if (patchResult?.isSuccess) {
      const returnPath = getReturnPath(initiativeType, id, location);
      setTimeout(() => {
        addToast(`${ convertHandleToTitle(currentData?.handle) } Updated`, {
          appearance: 'success',
          autoDismiss: true,
        });

        history.push({
          pathname:
            returnPath?.[layoutType]?.[viewMode] ||
            `/initiative/${ initiativeType }/edit/${ id }`,
          state: {
            layoutId: layoutId || currentData?.id,
            disabled: true,
            draftExist,
            assetType: getAssetType(layoutType),
          },
        });
      }, 250);
    }
    if (postResult?.isSuccess) {
      const returnPath = getReturnPath(initiativeType, id, location);
      addToast('Content Update Complete', {
        appearance: 'success',
        autoDismiss: true,
      });

      history.push({
        pathname:
          returnPath?.[layoutType]?.[viewMode] ||
          `/initiative/${ initiativeType }/${ currentData?.handle }/edit/${ id }`,
        state: {
          layoutId: layoutId || currentData?.id,
          disabled: true,
          draftExist,
          assetType: getAssetType(layoutType),
        },
      });
    }
  }, [
    patchResult,
    postResult,
    initiativeType,
    id,
    viewMode,
    layoutType,
    history,
    currentData,
    draftExist,
    layoutId,
  ]);
};

export default useHandleContentUpdate;
