import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../styles.module.scss';
import { TextField } from '@nike/eds';
import { debounce } from 'lodash';

export const OptionDescriptionField = ({
  id,
  value,
  fieldName,
  onChange,
  setAnswerOptions,
  disabled,
}) => {
  const [textFieldValue, setTextFieldValue] = useState(value);

  const debounceOnChange = useMemo(
    () =>
      debounce((updatedAnswerOptions) => {
        onChange({ target: { name: fieldName } }, updatedAnswerOptions);
      }, 300),
    [onChange, fieldName]
  );

  const handleTextFieldChange = useCallback(
    (event) => {
      if (disabled) return;
      const textValue = event.target.value;
      setTextFieldValue(textValue);
      let updatedAnswerOptions = [];

      setAnswerOptions((prevOptions) => {
        updatedAnswerOptions = prevOptions?.map((option) =>
          (option.id === id ? { ...option, description: textValue } : option)
        );
        return updatedAnswerOptions;
      });

      setTimeout(() => {
        debounceOnChange(updatedAnswerOptions);
      }, 0);
    },
    [id, debounceOnChange, setAnswerOptions, setTextFieldValue, disabled]
  );

  return (
    <TextField
      id={ 'description-' + id }
      name={ textFieldValue }
      defaultValue={ textFieldValue || '' }
      value={ textFieldValue }
      disabled={ disabled }
      onChange={ handleTextFieldChange }
      className={ styles.textFieldStyle }
      placeholder="Option Description"
    />
  );
};

OptionDescriptionField.propTypes = {
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
  id: PropTypes.number,
  onChange: PropTypes.func,
  setAnswerOptions: PropTypes.func,
  value: PropTypes.string,
};
