import React, { useCallback, useEffect, useState } from 'react';
import { PublishModalStyled } from 'views/Initiative/Experiences/components/ManageAssets/components/AssetCard/styled';
import styles from './index.module.scss';
import { QuestionSetTypes } from '../../RepeatableFieldsetUtils';
import { INQUIRIES } from 'lib/layoutUtils';
import PropTypes from 'prop-types';
import CreatePPI from 'views/PPI/components/Create';
import { convertHandleToTitle } from '../../lib';
import { getPPIgroup } from 'lib/hooks/usePPIlist';
const PPIModal = ({ layoutType, questionSet, setNewPPI }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newPPIFieldSetId, setNewPPIFieldSetId] = useState(null);

  useEffect(() => {
    if (
      INQUIRIES.includes(layoutType) &&
      questionSet instanceof Map &&
      questionSet.size > 0
    ) {
      for (const [fieldSetId, fieldSet] of questionSet.entries()) {
        const existingPPI = fieldSet?.value.get(QuestionSetTypes.EXISTING_PPI)
          ?.value?.value;
        if (existingPPI === 'New PPI') {
          setModalOpen(true);
          setNewPPIFieldSetId(fieldSetId);
          return;
        }
      }
    }
  }, [questionSet, layoutType]);

  const dismissModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  const createPPICallback = useCallback(
    (values) => {
      dismissModal();
      const newPPIOption = {
        label: convertHandleToTitle(values.name),
        value: values.handle,
        group: getPPIgroup(values.handle),
        fieldSetId: newPPIFieldSetId,
      };
      setNewPPI(newPPIOption);
    },
    [newPPIFieldSetId, dismissModal, setNewPPI]
  );

  return (
    <div className={ styles.createPPIModal }>
      <PublishModalStyled
        isOpen={ modalOpen }
        onDismiss={ dismissModal }
        hideFade={ true }
      >
        <CreatePPI onSubmit={ createPPICallback } />
      </PublishModalStyled>
    </div>
  );
};

PPIModal.propTypes = {
  layoutType: PropTypes.string,
  questionSet: PropTypes.instanceOf(Map),
  setNewPPI: PropTypes.func,
};

export default PPIModal;
