import React from 'react';
import {
  QuestionSetTypes,
  getFieldValuesByQuestionSetAndNames,
} from 'components/forms/Assets/RepeatableFieldsetUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import ToggleField from './ToggleField';
import {
  activityGearPPIs,
  excludeAnswerOptions,
} from 'components/forms/Assets/lib';
import { SCREENER_FORM } from 'lib/layoutUtils';

/* eslint-disable max-statements */
// eslint-disable-next-line complexity
const ConditionalToggle = ({
  fieldSet,
  fieldId,
  fieldValue = false,
  fieldName,
  onChange,
  layoutType,
}) => {
  const {
    value: currentQuestionSet,
    hasConditionalToggled = false,
    isUsedInConditional = false,
  } = fieldSet || {};

  // lastNonConditionalQuestionSet is available inside the currentQuestionSet if it is a conditional question
  const lastNonConditionalQuestionSet =
    fieldSet?.lastNonConditionalQuestionSet?.value;

  const {
    questionLabel: lastNonConditionalQuestionLabel,
    answerType: lastNonConditionalAnswerType,
    existingPPI: lastNonConditionalPPIHandle,
  } = getFieldValuesByQuestionSetAndNames(lastNonConditionalQuestionSet, [
    QuestionSetTypes.QUESTION_LABEL,
    QuestionSetTypes.ANSWER_TYPE,
    QuestionSetTypes.EXISTING_PPI,
  ]);

  const currentPPIValue =
    currentQuestionSet instanceof Map &&
    currentQuestionSet.get(QuestionSetTypes.EXISTING_PPI)?.value?.value;

  const isTextOrHiddenAnswerType = isTextOrHiddenType(
    lastNonConditionalAnswerType
  );
  const isRestrictedAnswerOptions = excludeAnswerOptions.includes(
    lastNonConditionalPPIHandle?.value
  );

  const disabled =
    isUsedInConditional ||
    isTextOrHiddenAnswerType ||
    isRestrictedAnswerOptions ||
    (hasConditionalToggled && !lastNonConditionalQuestionLabel) ||
    hasConditionalToggled;

  const showToggle =
    layoutType !== SCREENER_FORM &&
    fieldSet?.sortOrder !== 1 &&
    !activityGearPPIs.includes(currentPPIValue);

  return (
    showToggle && (
      <FieldWrapper disabled={ hasConditionalToggled }>
        <ToggleField
          hasChecked={ hasConditionalToggled }
          hasUsedInConditional={ isUsedInConditional }
          isTextOrHiddenAnswerType={ isTextOrHiddenAnswerType }
          fieldId={ fieldId }
          fieldName={ fieldName }
          fieldValue={ fieldValue }
          lastNonConditionalQuestionLabel={ lastNonConditionalQuestionLabel }
          onChange={ onChange }
          disabled={ disabled }
        />
      </FieldWrapper>
    )
  );
};

ConditionalToggle.propTypes = {
  fieldId: PropTypes.string,
  fieldName: PropTypes.string,
  fieldSet: PropTypes.shape({
    sortOrder: PropTypes.number,
    lastNonConditionalQuestionSet: PropTypes.shape({
      value: PropTypes.instanceOf(Map),
    }),
    value: PropTypes.instanceOf(Map),
  }),
  fieldValue: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
};

export default ConditionalToggle;
