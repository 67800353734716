import React from 'react';
import PropTypes from 'prop-types';
import ScreenerForm from './ScreenerForm';
import SurveyForm from './SurveyForm';
import OtherLayoutsForm from './OtherLayoutsForm';
import { SCREENER_FORM, SURVEY_FORM } from 'lib/layoutUtils';

const RenderFormContent = (props) => {
  const { layoutType } = props;

  return (
    <>
      { layoutType === SCREENER_FORM ? (
        <ScreenerForm { ...props } />
      ) : layoutType === SURVEY_FORM ? (
        <SurveyForm { ...props } />
      ) : (
        <OtherLayoutsForm { ...props } />
      ) }
    </>
  );
};

RenderFormContent.propTypes = {
  layoutType: PropTypes.string,
};

export default RenderFormContent;
