import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { QuestionSetTypes } from '../../RepeatableFieldsetUtils';
import { Toggle } from '@nike/eds';
import { debounce } from 'lodash';

const OptionalToggle = ({
  fieldSetId,
  disabled,
  isOptionalQuestion,
  onChange,
}) => {
  const [isOptionalToggled, setIsOptionalToggled] =
    useState(isOptionalQuestion);

  const debounceOnToggle = useMemo(
    () => debounce((event) => onChange(event), 100),
    [onChange]
  );
  const handleOptionalToggle = useCallback(
    (event) => {
      setIsOptionalToggled(event.target.checked);
      debounceOnToggle(event);
    },
    [debounceOnToggle]
  );

  return (
    <div>
      <div className={ styles.optionalQuestionToggle }>
        <Toggle
          id={ `${ QuestionSetTypes.OPTIONAL_QUESTION }-${ fieldSetId }` }
          name={ `${ QuestionSetTypes.OPTIONAL_QUESTION }-${ fieldSetId }` }
          label="Optional"
          onChange={ handleOptionalToggle }
          checked={ isOptionalToggled }
          disabled={ disabled }
        />
      </div>
    </div>
  );
};

OptionalToggle.propTypes = {
  disabled: PropTypes.bool,
  fieldSetId: PropTypes.string,
  isOptionalQuestion: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionalToggle;
